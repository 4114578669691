import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Grid } from '@mui/material';

const AddCooler = () => {
  const [newCooler, setNewCooler] = useState({
  serial_number: '',
  location: '',
  installation_date: '',
  last_pm_date: ''
});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setNewCooler({ ...newCooler, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Adjust the URL and data structure as per your API and database
      await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/coolers', newCooler);
      navigate('/dashboard'); // Redirect back to dashboard after adding
    } catch (error) {
      console.error('Error adding cooler:', error);
    }
  };

  return (
  <div style={{ padding: '2rem', backgroundColor: '#F5F5F5', minHeight: '100vh' }}>
    <Typography variant="h6" gutterBottom style={{ color: '#5A67D8' }}>
      Add New Cooler
    </Typography>
    <form onSubmit={handleSubmit}>
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <TextField
          label="Serial Number"
          name="serial_number"
          onChange={handleChange}
          fullWidth
          required
          variant="outlined"
        />
        <TextField
          label="Location"
          name="location"
          onChange={handleChange}
          fullWidth
          required
          variant="outlined"
        />
        <TextField
          label="Installation Date"
          name="installation_date"
          type="date"
          onChange={handleChange}
          fullWidth
          required
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Last PM Date"
          name="last_pm_date"
          type="date"
          onChange={handleChange}
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
            label="Latitude"
            name="latitude"
            type="number"
            onChange={handleChange}
            fullWidth
            required
            variant="outlined"
          />
          <TextField
            label="Longitude"
            name="longitude"
            type="number"
            onChange={handleChange}
            fullWidth
            required
            variant="outlined"
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ backgroundColor: '#1B40A7' }}
          >
            Add Cooler
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default AddCooler;
