// CoolersListView.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Typography, List, ListItem } from '@mui/material';
import { CircularProgress } from '@mui/material';
const CoolersListView = () => {
  const [coolers, setCoolers] = useState([]);
  const { childOf } = useParams(); // Ensure this matches the dynamic segment in your route
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!childOf) {
      setError('No Child Of value provided');
      setLoading(false);
      return;
    }
    const fetchCoolers = async () => {
      try {
        const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/newcoolers/coolers?childOf=${encodeURIComponent(childOf)}`);
        setCoolers(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Failed to fetch coolers');
        setLoading(false);
      }
    };

    fetchCoolers();
  }, [childOf]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ color: '#1B40A7' }} />
      </div>
    );
  }
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <List>
      {coolers.map((cooler, index) => (
        <ListItem key={index}>
          {/* Display cooler details */}
          {cooler.AccountName} - {cooler.Model} - {cooler.AssetSerial_Number}
        </ListItem>
      ))}
    </List>
  );
};

export default CoolersListView;
