import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Paper, Card, CardContent, Button, ListItem, ListItemText, Collapse, List, Tabs, Tab, TextField, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import debounce from 'lodash.debounce';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BackButton from '../components/BackButton';

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#1B40A7',
  },
});

const StyledTab = styled(Tab)({
  fontFamily: 'NeueEinstellungBold',
  fontSize: '12px',
  minWidth: '10px',
  padding: '2px 8px',
  color: '#000',
  '&.Mui-selected': {
    color: '#1B40A7',
  },
});

const CoolerHierarchy = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [stateCityGroups, setStateCityGroups] = useState({});
  const [openState, setOpenState] = useState({});
  const [openCity, setOpenCity] = useState({});
  const [coolersNeedingAttention, setCoolersNeedingAttention] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [coolersWithMissingPhotos, setCoolersWithMissingPhotos] = useState([]);
  const [open, setOpen] = useState(true);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
  const fetchCoolersNeedingAttention = async () => {
    try {
      const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/coolers-needing-attention');
      setCoolersNeedingAttention(response.data);
    } catch (error) {
      console.error('Error fetching coolers needing attention:', error);
    }
  };

  fetchCoolersNeedingAttention();
}, []);

  useEffect(() => {
    const fetchCoolersWithMissingPhotos = async () => {
      try {
        const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/coolers-with-missing-photos');
        setCoolersWithMissingPhotos(response.data);
      } catch (error) {
        console.error('Error fetching coolers with missing photos:', error);
      }
    };

    fetchCoolersWithMissingPhotos();
  }, []);
  useEffect(() => {
    axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/newcoolers')
      .then(response => {
        const groupedByStateCity = response.data.reduce((acc, cooler) => {
          const state = cooler.ShippingState;
          const city = cooler.ShippingCity.toLowerCase();
          if (!acc[state]) {
            acc[state] = {};
          }
          if (!acc[state][city]) {
            acc[state][city] = [];
          }
          acc[state][city].push(cooler);
          return acc;
        }, {});

        setStateCityGroups(groupedByStateCity);
      })
      .catch(err => console.error('Error fetching coolers', err));
  }, []);

  const handleClickState = (state) => {
    setOpenState({ ...openState, [state]: !openState[state] });
  };

  const handleClickCity = (state, city) => {
    setOpenCity({ ...openCity, [`${state}-${city}`]: !openCity[`${state}-${city}`] });
  };

  const toStartCase = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace(/'\w/g, match => match.toLowerCase());
  };

  const handleSearchChange = debounce((event) => {
    setSearchTerm(event.target.value.toLowerCase());
  }, 300);

  // Function to render coolers based on search term
  const renderSearchedCoolers = () => {
    let coolersList = [];
    for (const state in stateCityGroups) {
      for (const city in stateCityGroups[state]) {
        coolersList = coolersList.concat(stateCityGroups[state][city].filter(cooler =>
          cooler['SerialNumber'].toLowerCase().includes(searchTerm)
        ));
      }
    }

    return coolersList.map((cooler, idx) => (
      <ListItem
        button
        key={idx}
        //sx={{ paddingLeft: '3rem' }}
        onClick={() => navigate(`/cooler-details/${cooler['SerialNumber']}`)}
      >
        <Card
          sx={{
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            width: '100%',
          }}
        >
          <CardContent>
            <Typography variant="h6" sx={{ color: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}>
              {`Cooler ID: ${cooler['SerialNumber']}`}
            </Typography>
            <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
              {`Model: ${cooler.Model}`}
            </Typography>
            <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
              {`${cooler.ShippingCity}, ${cooler.ShippingState}`}
            </Typography>
            <Button
            variant="contained"
            size="small"
            sx={{
              marginTop: '10px',
              backgroundColor: '#1B40A7', // Match your primary color
              color: 'white', // Usually white for good contrast
              borderRadius: '5px', // Adjust to match card's border-radius
              fontFamily: 'NeueEinstellungBold', // Match card's typography
              textTransform: 'none', // Disable MUI's default uppercase
              '&:hover': {
                backgroundColor: '#4552ad' // Slightly darker on hover
              }
            }}
            onClick={(e) => {   // Pass the event object 'e'
                e.stopPropagation();  // Stop the event from propagating
                navigate(`/service/${cooler['SerialNumber']}`);
            }}
          >
            Service Page
          </Button>
          </CardContent>
        </Card>
      </ListItem>
    ));
  };

  return (
    <Container>
      <BackButton />
      <Typography variant="h4" gutterBottom style={{ fontFamily: 'NeueEinstellungBold', marginTop: '2rem' }}>
        Coolers
      </Typography>
      <StyledTabs value={selectedTab} onChange={handleTabChange} style={{ marginBottom: '2.5rem' }}>
        <StyledTab label="All" />
        <StyledTab label="Missing Photos" />
        <StyledTab label="Needs Attention" />
      </StyledTabs>
      {selectedTab === 2 && (
  <Typography variant="h4" gutterBottom style={{ fontFamily: 'NeueEinstellungBold', marginTop: '2rem' }}>
    <Paper sx={{ borderRadius: '20px', padding: '2rem', marginTop: '2rem' }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontFamily: 'NeueEinstellungBold',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setOpen(!open)}
      >
        Coolers Needing Attention
        <IconButton size="small">
          <KeyboardArrowDownIcon
            sx={{
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
            }}
          />
        </IconButton>
      </Typography>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <TextField
          fullWidth
          label="Search by Serial Number"
          variant="outlined"
          onChange={handleSearchChange}
          sx={{ marginBottom: '1rem' }}
        />
        {searchTerm ? (
          renderSearchedCoolers().map((cooler, idx) => (
            <Card
              key={idx}
              sx={{
                mb: 2,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
              }}
            >
              <CardContent onClick={() => navigate(`/cooler-details/${cooler['SerialNumber']}`)}>
                <Typography variant="h6" sx={{ color: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}>
                  {`Cooler ID: ${cooler['SerialNumber']}`}
                </Typography>
                <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                  {`Model: ${cooler.Model}`}
                </Typography>
                <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                  {`${cooler.ShippingCity}, ${cooler.ShippingState}`}
                </Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          coolersNeedingAttention.map((cooler, idx) => (
            <Card
              key={idx}
              sx={{
                mb: 2,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
              }}
            >
              <CardContent onClick={() => navigate(`/cooler-details/${cooler['SerialNumber']}`)}>
                <Typography variant="h6" sx={{ color: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}>
                  {`Cooler ID: ${cooler['SerialNumber']}`}
                </Typography>
                <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                  {`Model: ${cooler.Model}`}
                </Typography>
                <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                  {`${cooler.ShippingCity}, ${cooler.ShippingState}`}
                </Typography>
            
              </CardContent>
            </Card>
          ))
        )}
      </Collapse>
    </Paper>
  </Typography>
)}
      {selectedTab === 1 && (
        <Typography variant="h4" gutterBottom style={{ fontFamily: 'NeueEinstellungBold', marginTop: '2rem' }}>
        <Paper sx={{ borderRadius: '20px', padding: '2rem', marginTop: '2rem' }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontFamily: 'NeueEinstellungBold',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(!open)}
          >
            Coolers with Missing Photos
            <IconButton size="small">
              <KeyboardArrowDownIcon
                sx={{
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              />
            </IconButton>
          </Typography>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TextField
              fullWidth
              label="Search by Serial Number"
              variant="outlined"
              onChange={handleSearchChange}
              sx={{ marginBottom: '1rem' }}
            />
            {searchTerm ? (
              renderSearchedCoolers().map((cooler, idx) => (
                <Card
                  key={idx}
                  sx={{
                    mb: 2,
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                  }}
                >
                  <CardContent onClick={() => navigate(`/cooler-details/${cooler['SerialNumber']}`)}>
                    <Typography variant="h6" sx={{ color: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}>
                      {`Cooler ID: ${cooler['SerialNumber']}`}
                    </Typography>
                    <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                      {`Model: ${cooler.Model}`}
                    </Typography>
                    <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                      {`${cooler.ShippingCity}, ${cooler.ShippingState}`}
                    </Typography>
                    <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                      {`Technician: ${cooler.technician_name}`}
                    </Typography>
                    <Typography sx={{ fontFamily: 'NeueEinstellungNormal', color: 'red' }}>
                      {`Missing Photos: ${3 - cooler.file_count}`}
                    </Typography>
                  </CardContent>
                </Card>
              ))
            ) : (
              coolersWithMissingPhotos.map((cooler, idx) => (
                <Card
    key={idx}
    sx={{
      mb: 2,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
    }}
                >
                  <CardContent onClick={() => navigate(`/cooler-details/${cooler['SerialNumber']}`)}>
                    <Typography variant="h6" sx={{ color: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}>
                      {`Cooler ID: ${cooler['SerialNumber']}`}
                    </Typography>
                    <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                      {`Model: ${cooler.Model}`}
                    </Typography>
                    <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                      {`${cooler.ShippingCity}, ${cooler.ShippingState}`}
                    </Typography>
                    <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                      {`Technician: ${cooler.technician_name}`}
                    </Typography>
                    <Typography sx={{ fontFamily: 'NeueEinstellungNormal', color: 'red' }}>
                      {`Missing Photos: ${3 - cooler.file_count}`}
                    </Typography>
                  </CardContent>
                </Card>
              ))
            )}
          </Collapse>
        </Paper>
        </Typography>
    )}
      {selectedTab === 0 && (

          <Paper sx={{ borderRadius: '20px', padding: '2rem' }}>
            <Typography variant="h6" gutterBottom sx={{ fontFamily: 'NeueEinstellungBold' }}>
              All Coolers
            </Typography>
            <TextField
              fullWidth
              label="Search by Serial Number"
              variant="outlined"
              onChange={(event) => handleSearchChange(event)}
              sx={{ marginBottom: '1rem' }}
            />
            <List component="nav">
              {searchTerm
                ? renderSearchedCoolers()
                : Object.keys(stateCityGroups).map((state) => (
                    <React.Fragment key={state}>
                      <ListItem button onClick={() => handleClickState(state)}>
                        <ListItemText primary={state} />
                      </ListItem>
                      <Collapse in={openState[state]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {Object.keys(stateCityGroups[state]).map((city) => (
                            <React.Fragment key={`${state}-${city}`}>
                              <ListItem
                                button
                                sx={{ paddingLeft: '2rem' }}
                                onClick={() => handleClickCity(state, city)}
                              >
                                <ListItemText primary={toStartCase(city)} secondary={`Coolers: ${stateCityGroups[state][city].length}`} />

                              </ListItem>
                              <Collapse in={openCity[`${state}-${city}`]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                {stateCityGroups[state][city].map((cooler, idx) => (
                                  <ListItem
                                      button
                                      key={idx}
                                      sx={{ paddingLeft: '3rem' }}
                                  >
                                      <Card
                                          sx={{
                                              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                              borderRadius: '10px',
                                              width: '100%',

                                          }}
                                          onClick={() => navigate(`/cooler-details/${cooler['SerialNumber']}`)}
                                      >
                                          <CardContent>
                                              <Typography variant="h6" sx={{ color: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}>
                                                  {`Cooler ID: ${cooler['SerialNumber']}`}
                                              </Typography>
                                              <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                                  {`Model: ${cooler.Model}`}
                                              </Typography>
                                              <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                                  {`Zip Code: ${cooler.ShippingZip}`}
                                              </Typography>


                                              <Button
                                              variant="contained"
                                              size="small"
                                              sx={{
                                                marginTop: '10px',
                                                backgroundColor: '#1B40A7',
                                                color: 'white',
                                                borderRadius: '5px',
                                                fontFamily: 'NeueEinstellungBold',
                                                textTransform: 'none',
                                                '&:hover': {
                                                  backgroundColor: '#4552ad'
                                                }
                                              }}
                                              onClick={(e) => {   // Pass the event object 'e'
                                                  e.stopPropagation();  // Stop the event from propagating
                                                  navigate(`/service/${cooler['SerialNumber']}`);
                                              }}
                                            >
                                              Service Page
                                            </Button>

                                          </CardContent>
                                      </Card>
                                    </ListItem>
                              ))}
                                </List>
                              </Collapse>
                            </React.Fragment>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ))}
            </List>
          </Paper>

      )}
    </Container>






  );
};

export default CoolerHierarchy;
