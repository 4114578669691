import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    Box,
    TextField,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
} from '@mui/material';
import { GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindowF, Polyline } from '@react-google-maps/api';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NearMeIcon from '@mui/icons-material/NearMe';
import debounce from 'lodash/debounce';
import ListAltIcon from '@mui/icons-material/ListAlt'; // Add this import

const containerStyle = {
    width: '100%',
    height: '400px',
    borderRadius: '15px'
};

const darkTheme = [


    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#5a70bf"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "color": "#808080"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#b3b3b3"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "weight": 1.8
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d7d7d7"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ebebeb"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#a7a7a7"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#efefef"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#696969"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#737373"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#d6d6d6"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {},
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dadada"
            }
        ]
    }


];

const libraries = ["geometry"];



const ProjectInstallations = () => {
    const navigate = useNavigate();
    const { projectId } = useParams(); // Add this line to get the projectId from the URL
    const [installations, setInstallations] = useState([]);
    const [mapCenter, setMapCenter] = useState({
        lat: 31.5774752,  // Center on your first location
        lng: -91.3078792
    });
    const [zoom, setZoom] = useState(11);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const mapRef = useRef(null);
    const [selectedInstallation, setSelectedInstallation] = useState(null);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [zipCode, setZipCode] = useState('');
    const [selectedCoolers, setSelectedCoolers] = useState(new Set());
    const [route, setRoute] = useState(null);  // Change this from array to null
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [showCompletedInstallations, setShowCompletedInstallations] = useState(false);
    const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            position => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                setCurrentPosition(pos);
                setMapCenter(pos);
                fetchInstallations(pos.lat, pos.lng, 1);
            },
            error => {
                console.error("Error getting location", error);
                fetchInstallations(mapCenter.lat, mapCenter.lng, 1);
            }
        );
    }, []);

    useEffect(() => {
        console.log('Installations loaded:', installations);
    }, [installations]);

    const fetchInstallations = async (latitude, longitude, pageNumber) => {
        try {
            setIsLoading(true);
            console.log('Fetching installations with params:', { latitude, longitude, pageNumber });

            const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/installations', {
                params: {
                    lat: latitude,
                    lng: longitude,
                    page: pageNumber
                }
            });

            console.log('Received response:', response.data);

            if (response.data.installations && response.data.installations.length > 0) {
                setInstallations(prev => {
                    const newInstallations = pageNumber === 1 ?
                        response.data.installations :
                        [...prev, ...response.data.installations];
                    console.log('Updated installations:', newInstallations);
                    return newInstallations;
                });
                setTotalPages(response.data.totalPages);
            } else {
                console.log('No installations found in response');
            }

            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching installations:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
            }
            setIsLoading(false);
        }
    };

    const handleSearchChange = debounce((event) => {
        setSearchTerm(event.target.value.toLowerCase());
    }, 300);

    const renderSearchedInstallations = () => {
        return installations.filter(installation =>
            installation.store_number.toLowerCase().includes(searchTerm) ||
            installation.address.toLowerCase().includes(searchTerm)
        );
    };

    const handleShowMore = () => {
        if (page < totalPages) {
            const nextPage = page + 1;
            setPage(nextPage);
            fetchInstallations(mapCenter.lat, mapCenter.lng, nextPage);
        }
    };

    const centerMapOnInstallation = (installation) => {
        setMapCenter({
            lat: parseFloat(installation.latitude),
            lng: parseFloat(installation.longitude)
        });
        setZoom(15);
        setSelectedInstallation(installation);
    };

    const centerOnCurrentLocation = () => {
        if (currentPosition) {
            setMapCenter(currentPosition);
            setZoom(11);
            setPage(1);
            setInstallations([]);
            fetchInstallations(currentPosition.lat, currentPosition.lng, 1);
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'completed': return '#4CAF50';
            case 'in_progress': return '#FFA726';
            case 'pending': return '#757575';
            default: return '#757575';
        }
    };

    const formatDistance = (meters) => {
        const miles = meters / 1609.34;
        return `${miles.toFixed(1)} miles`;
    };

    const handleZipCodeSubmit = async () => {
        try {
            const response = await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/geocode-zip',
                { zipCode }
            );
            if (response.data) {
                const { lat, lng } = response.data;
                setMapCenter({ lat, lng });
                setZoom(11);
                setPage(1);
                setInstallations([]);
                fetchInstallations(lat, lng, 1);
            }
        } catch (error) {
            console.error('Error geocoding zip code:', error);
        }
    };

    const createGoogleMapsUrl = () => {
        const selectedInstallationsList = installations.filter(installation =>
            selectedCoolers.has(installation.id)
        );

        if (selectedInstallationsList.length === 0) {
            return "#";
        }

        // Format addresses for each location
        const formatAddress = (installation) => {
            return encodeURIComponent(
                `${installation.address}, ${installation.city}, ${installation.state} ${installation.zip_code}`.trim()
            );
        };

        // Create waypoints using full addresses
        const waypoints = selectedInstallationsList.map(installation =>
            formatAddress(installation)
        );

        // Use the last address as destination
        const destination = waypoints.pop();

        // Join remaining waypoints with pipe character
        const waypointsParam = waypoints.length > 0 ?
            `&waypoints=${waypoints.join('|')}` : '';

        return `https://www.google.com/maps/dir/?api=1&destination=${destination}${waypointsParam}&travelmode=driving`;
    };

    const calculateAndStartRoute = async () => {
        if (!window.google || !isGoogleLoaded) {
            console.log("Google Maps not yet loaded");
            return;
        }

        const selectedInstallationsList = Array.from(selectedCoolers);
        
        // Creating waypoints array
        const waypoints = [];
        let lastWaypoint = null;
        selectedInstallationsList.forEach(installationId => {
            const installation = installations.find(i => i.id === installationId);
            if (installation) {
                waypoints.push(`${installation.latitude},${installation.longitude}`);
            }
        });

        if (waypoints.length > 0) {
            lastWaypoint = waypoints.pop();
        }

        try {
            // Log the request parameters
            console.log('Direction request params:', {
                origin: `${currentPosition.lat},${currentPosition.lng}`,
                destination: lastWaypoint || `${currentPosition.lat},${currentPosition.lng}`,
                waypoints: waypoints.join('|')
            });

            const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/directions', {
                params: {
                    optimize: true,
                    origin: `${currentPosition.lat},${currentPosition.lng}`,
                    destination: lastWaypoint || `${currentPosition.lat},${currentPosition.lng}`,
                    waypoints: waypoints.join('|'),
                    apiKey: 'AIzaSyCmD5wWJPbP6wQuZcZxCv2jdCXPDgLRLl4'
                }
            });

            // Log the response
            console.log('Direction API response:', response.data);

            // Check if we have valid response data
            if (!response.data || !response.data.routes || !response.data.routes[0]) {
                throw new Error('Invalid response from directions API');
            }

            const directionsData = response.data.routes[0];
            
            if (!directionsData.overview_polyline || !directionsData.overview_polyline.points) {
                throw new Error('No polyline data in response');
            }

            const path = window.google.maps.geometry.encoding.decodePath(
                directionsData.overview_polyline.points
            );
            
            setRoute(path);

            // Adjust map bounds to fit the route
            if (mapRef.current) {
                const bounds = new window.google.maps.LatLngBounds();
                path.forEach((point) => bounds.extend(point));
                mapRef.current.fitBounds(bounds);
            }

        } catch (error) {
            console.error('Error calculating route:', error);
            console.error('Error details:', error.response?.data || error.message);
        }
    };

    const buttonStyle = {
        backgroundColor: '#1B40A7',
        color: '#fff',
        height: '50px',
        boxShadow: '0px 0px 0px rgba(0,0,0,0)',
        fontFamily: 'NeueEinstellungBold',
        fontSize: '10px',
        margin: '8px',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: '#152e78'
        }
    };

    const renderInstallations = () => {
        return installations.filter(installation =>
            (showCompletedInstallations || installation.installation_status !== 'completed') &&
            (installation.store_number.toLowerCase().includes(searchTerm) ||
                installation.address.toLowerCase().includes(searchTerm))
        );
    };

    return (
        <Container>
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'flex-start', sm: 'center' },
                justifyContent: 'space-between',
                mb: { xs: 2, sm: 4 },
                mt: { xs: 1, sm: 2 },
                gap: { xs: 2, sm: 0 }
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%', sm: 'auto' } }}>
                    <Button
                        onClick={() => navigate(-1)}
                        sx={{
                            color: 'rgb(27, 64, 167)',
                            mr: 2,
                            '&:hover': { backgroundColor: 'rgba(27, 64, 167, 0.1)' }
                        }}
                    >
                        <ArrowBackIcon />
                    </Button>
                    <Box>
                        <Typography variant="h4" sx={{ fontFamily: 'NeueEinstellungBold' }}>
                            Installations
                        </Typography>
                        <Typography variant="subtitle1" sx={{ fontFamily: 'NeueEinstellungNormal', color: 'text.secondary' }}>
                            {installations.length} locations found
                        </Typography>
                    </Box>
                </Box>

                <Button
                    variant="contained"
                    startIcon={<ListAltIcon />}
                    onClick={() => navigate('/installations')}
                    sx={{
                        backgroundColor: '#1B40A7',
                        color: '#fff',
                        borderRadius: '10px',
                        fontFamily: 'NeueEinstellungBold',
                        width: { xs: '100%', sm: 'auto' },
                        '&:hover': { backgroundColor: '#152e78' }
                    }}
                >
                    All Installations
                </Button>
            </Box>

            {/* Map Section */}
            <Box sx={{ position: 'relative', mb: 4 }}>
                <LoadScript
                    googleMapsApiKey="AIzaSyCmD5wWJPbP6wQuZcZxCv2jdCXPDgLRLl4"
                    libraries={libraries}
                    onLoad={() => setIsGoogleLoaded(true)}
                >
                    <GoogleMap
                        onLoad={map => {
                            mapRef.current = map;
                            setIsGoogleLoaded(true);
                            console.log('Map loaded');
                        }}
                        mapContainerStyle={containerStyle}
                        center={mapCenter}
                        zoom={zoom}
                        options={{
                            styles: darkTheme,
                            mapTypeControl: false,
                            zoomControl: false,
                            streetViewControl: false,
                            fullscreenControl: false
                        }}
                    >
                        {/* Map content remains the same */}
                        {currentPosition && isGoogleLoaded && window.google && (
                            <Marker
                                position={currentPosition}
                                icon={{
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 7,
                                    fillColor: "#4285F4",
                                    fillOpacity: 1,
                                    strokeWeight: 2,
                                    strokeColor: "white"
                                }}
                            />
                        )}

                        {isGoogleLoaded && window.google && installations.map(installation => (
                            <Marker
                                key={installation.id}
                                position={{
                                    lat: parseFloat(installation.latitude),
                                    lng: parseFloat(installation.longitude)
                                }}
                                icon={{
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 4.5,
                                    fillColor: installation.installation_status === 'completed' ? "green" : "red",
                                    fillOpacity: 1,
                                    strokeWeight: 1,
                                    strokeColor: "white",
                                }}
                                onClick={() => setSelectedInstallation(installation)}
                            />
                        ))}

                        {route && isGoogleLoaded && window.google && (
                            <Polyline
                                path={route}
                                options={{
                                    strokeColor: "#1B40A7",
                                    strokeOpacity: 1.0,
                                    strokeWeight: 3,
                                }}
                            />
                        )}

                        {selectedInstallation && (
                            <InfoWindowF
                                position={{
                                    lat: parseFloat(selectedInstallation.latitude),
                                    lng: parseFloat(selectedInstallation.longitude)
                                }}
                                onCloseClick={() => setSelectedInstallation(null)}
                            >
                                <div>
                                    <Typography variant="h6">{selectedInstallation.store_number}</Typography>
                                    <Typography>{selectedInstallation.address}</Typography>
                                    <Typography>{`${selectedInstallation.city}, ${selectedInstallation.state}`}</Typography>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: selectedCoolers.has(selectedInstallation.id) ? "#d16656" : "#1B40A7",
                                            color: 'white',
                                            boxShadow: 'none',
                                            marginTop: '8px'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedCoolers(prev => {
                                                const newSelection = new Set(prev);
                                                if (newSelection.has(selectedInstallation.id)) {
                                                    newSelection.delete(selectedInstallation.id);
                                                } else {
                                                    newSelection.add(selectedInstallation.id);
                                                }
                                                return newSelection;
                                            });
                                        }}
                                    >
                                        {selectedCoolers.has(selectedInstallation.id) ? "Remove" : "Add"}
                                    </Button>
                                </div>
                            </InfoWindowF>
                        )}
                    </GoogleMap>
                </LoadScript>

                <Button
                    onClick={centerOnCurrentLocation}
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        backgroundColor: 'white',
                        color: '#1B40A7',
                        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                    }}
                >
                    <NearMeIcon />
                </Button>
            </Box>

            {/* Control Buttons Section - Reorganized for mobile */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained"
                        onClick={calculateAndStartRoute}
                        disabled={selectedCoolers.size === 0}
                        fullWidth
                        sx={{
                            ...buttonStyle,
                            margin: 0
                        }}
                    >
                        ROUTE
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained"
                        component="a"
                        href={createGoogleMapsUrl()}
                        target="_blank"
                        disabled={selectedCoolers.size === 0}
                        fullWidth
                        sx={{
                            ...buttonStyle,
                            margin: 0
                        }}
                    >
                        OPEN IN GOOGLE MAPS
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained"
                        onClick={handleShowMore}
                        fullWidth
                        sx={{
                            ...buttonStyle,
                            margin: 0
                        }}
                    >
                        SHOW MORE
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <TextField
                            placeholder="Zip Code"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            fullWidth
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px',
                                    borderRadius: '8px'
                                }
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={handleZipCodeSubmit}
                            sx={{
                                ...buttonStyle,
                                margin: 0,
                                minWidth: '100px'
                            }}
                        >
                            SEARCH
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={() => setShowCompletedInstallations(!showCompletedInstallations)}
                        fullWidth
                        sx={{
                            ...buttonStyle,
                            margin: 0
                        }}
                    >
                        {showCompletedInstallations ? 'HIDE COMPLETED INSTALLATIONS' : 'SHOW COMPLETED INSTALLATIONS'}
                    </Button>
                </Grid>
            </Grid>

            {/* Search Section */}
            <TextField
                fullWidth
                label="Search installations"
                variant="outlined"
                onChange={handleSearchChange}
                sx={{ mb: 3 }}
            />
            {/* Installations Grid */}
            <Grid container spacing={2}>
                {isLoading ? (
                    Array.from(new Array(6)).map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Skeleton variant="rounded" width="100%" height={200} />
                        </Grid>
                    ))
                ) : (
                    <>
                        {renderInstallations().map(installation => (
                            <Grid item xs={12} sm={6} md={4} key={installation.id}>
                                <Card
                                    sx={{
                                        cursor: 'pointer',
                                        boxShadow: 'none',
                                        borderRadius: '10px',
                                        backgroundColor: selectedCoolers.has(installation.id) ? 'rgb(100, 124, 189, 0.4)' : 'rgb(256, 256, 256)',
                                        '&:hover': { transform: 'translateY(-4px)', transition: 'transform 0.2s' }
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedCoolers(prev => {
                                            const newSelection = new Set(prev);
                                            if (newSelection.has(installation.id)) {
                                                newSelection.delete(installation.id);
                                            } else {
                                                newSelection.add(installation.id);
                                            }
                                            return newSelection;
                                        });
                                        centerMapOnInstallation(installation);
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="h6" sx={{ color: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}>
                                            Store #{installation.store_number}
                                        </Typography>
                                        <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                            {installation.address}
                                        </Typography>
                                        <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                            {`${installation.city}, ${installation.state}`}
                                        </Typography>
                                        <Typography sx={{ fontFamily: 'NeueEinstellungNormal', color: 'text.secondary' }}>
                                            {formatDistance(installation.distance)}
                                        </Typography>
                                        <Typography sx={{ fontFamily: 'NeueEinstellungNormal', color: 'text.secondary' }}>
                                            Status: {installation.installation_status}
                                        </Typography>

                                        <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{
                                                    backgroundColor: selectedCoolers.has(installation.id) ? "#d16656" : "#1B40A7",
                                                    fontFamily: 'NeueEinstellungBold',
                                                    mb: 1,
                                                    '&:hover': {
                                                        backgroundColor: selectedCoolers.has(installation.id) ? "#c15645" : "#152e78"
                                                    }
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedCoolers(prev => {
                                                        const newSelection = new Set(prev);
                                                        if (newSelection.has(installation.id)) {
                                                            newSelection.delete(installation.id);
                                                        } else {
                                                            newSelection.add(installation.id);
                                                        }
                                                        return newSelection;
                                                    });
                                                }}
                                            >
                                                {selectedCoolers.has(installation.id) ? 'Remove' : 'Add'}
                                            </Button>

                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{
                                                    backgroundColor: '#1B40A7',
                                                    fontFamily: 'NeueEinstellungBold',
                                                    mb: 1,
                                                    '&:hover': { backgroundColor: '#152e78' }
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigate(`/projects/${projectId}/installations/${installation.id}`);
                                                }}
                                            >
                                                Start Installation
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}

                        {page < totalPages && (
                            <Grid item xs={12} sm={6} md={4}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '200px',
                                        cursor: 'pointer',
                                        boxShadow: 'none',
                                        borderRadius: '10px'
                                    }}
                                    onClick={handleShowMore}
                                >
                                    <CardContent sx={{ textAlign: 'center' }}>
                                        <AddIcon sx={{ fontSize: 40, color: '#1B40A7' }} />
                                        <Typography sx={{ color: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}>
                                            Show More
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </Container>
    );
};

export default ProjectInstallations;