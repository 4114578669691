import React, { useState, useEffect } from 'react';
import { PieChart, Pie, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import Papa from 'papaparse';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import BackButton from '../components/BackButton';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Checkbox } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PendingIcon from '@mui/icons-material/Pending';

const ProjectReport = () => {
  const [completedData, setCompletedData] = useState([]);
  const [allInstallations, setAllInstallations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCoolers, setSelectedCoolers] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [sendingProgress, setSendingProgress] = useState({ current: 0, total: 0 });
  const [showSuccess, setShowSuccess] = useState(false);
  const [confirmResendDialog, setConfirmResendDialog] = useState(false);
  const [coolersToResend, setCoolersToResend] = useState([]);

  const WEBHOOK_URL = 'https://hook.us2.make.com/6qpbbq1g41cr5xcsv4dw206u7ai2kmcp';

  const sendToKDP = async (installation) => {
    try {
      console.log('Installation data:', installation);
      console.log('Photos data:', installation.photos);

      const jobStatus = installation.deliveryComplete === false ? 'DRY-RUN' : 'COMPLETED';

      const payload = [{
        job_location_number: installation['Store Number'],
        job_status: jobStatus,
        installation_date: installation.installation_date,
        cooler_image: [
          installation.photos?.completedSetupImage,
        ].filter(Boolean).map(url => ({ url })),
        serial_plate_image: [
          installation.photos?.serialPlateImage
        ].filter(Boolean).map(url => ({ url })),
        kdp_asset_images: [
          installation.photos?.assetNumberImage
        ].filter(Boolean).map(url => ({ url })),
        cooler_removed: installation.cooler_removed || false,
        cooler_removed_type: installation.removed_cooler_type || null,
        kdp_asset_number: installation['Asset Number'],
        cooler_serial_number: installation['Serial Number'],
        ...(jobStatus === 'DRY-RUN' && {
          dryrun_notes: installation.deliveryNotes || "Delivery was not completed",
        })
      }];

      console.log('Payload to KDP:', payload);

      const response = await axios.post(WEBHOOK_URL, payload);

      if (response.status === 200) {
        console.log('Successfully sent to KDP');
        return true;
      } else {
        console.error('Error from KDP webhook:', response.data);
        return false;
      }
    } catch (error) {
      console.error('Error sending to KDP:', error);
      return false;
    }
  };

  const fetchData = async () => {
    try {
      const installationsResponse = await fetch('https://freshpet-pm-9087312eb841.herokuapp.com/api/cooler_installations');
      const installationsData = await installationsResponse.json();
      setAllInstallations(installationsData);

      const completedResponse = await fetch('https://freshpet-pm-9087312eb841.herokuapp.com/api/completed_installations');
      const completedData = await completedResponse.json();
      console.log('Completed installation data from backend:', completedData[0]);
      setCompletedData(completedData);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const statusData = allInstallations.reduce((acc, curr) => {
    const status = curr.installation_status || 'unknown';
    const existing = acc.find(item => item.status === status);
    if (existing) {
      existing.value++;
    } else {
      acc.push({ status, value: 1 });
    }
    return acc;
  }, []);

  const stateData = allInstallations.reduce((acc, curr) => {
    if (curr.state) {
      const existing = acc.find(item => item.state === curr.state);
      if (existing) {
        existing.count++;
      } else {
        acc.push({ state: curr.state, count: 1 });
      }
    }
    return acc;
  }, []);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const exportToExcel = () => {
    const exportData = completedData.map(item => ({
      'Store Number': item.store_number || '',
      'State': item.state || '',
      'City': item.city || '',
      'Address': item.address || '',
      'Installation Date': item.installation_date ? new Date(item.installation_date).toLocaleDateString() : '',
      'Serial Number': item.serial_number || '',
      'Asset Number': item.asset_number || '',
      'Installed By': item.installed_by || '',
      'Status': item.status || '',
      'Notes': item.notes || ''
    }));

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(exportData, { origin: 'A2' });
    
    // Add title row
    XLSX.utils.sheet_add_aoa(ws, [
      ['DG/KDP Completed Stores Installation Report'],
      [] // Empty row after title
    ], { origin: 'A1' });

    // Style the header
    ws['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 9 } } // Merge cells A1:J1 for title (updated for new Notes column)
    ];

    // Set column widths
    ws['!cols'] = [
      { wch: 15 }, // Store Number
      { wch: 10 }, // State
      { wch: 15 }, // City
      { wch: 30 }, // Address
      { wch: 15 }, // Installation Date
      { wch: 15 }, // Serial Number
      { wch: 15 }, // Asset Number
      { wch: 15 }, // Installed By
      { wch: 10 }, // Status
      { wch: 40 }  // Notes
    ];

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Completed Installations');

    // Generate Excel file with current date
    const currentDate = new Date().toISOString().split('T')[0];
    XLSX.writeFile(wb, `DG_KDP_installations_${currentDate}.xlsx`);
  };

  // Styled components (similar to AdminDashboard)
  const StyledPaper = {
    padding: '1.5rem',
    borderRadius: '16px',
    fontFamily: 'NeueEinstellungBold',
    boxShadow: 'none',
    background: '#fff',
    marginBottom: '1rem'
  };

  const welcomeStyle = {
    background: 'linear-gradient(90deg, #1B40A7, #2b5be0)',
    padding: '1.5rem',
    borderRadius: '16px',
    marginBottom: '2rem',
    boxShadow: '0 4px 15px rgba(27, 64, 167, 0.2)',
  };

  const totalInstallations = allInstallations.length;
  const completedInstallations = allInstallations.filter(
    item => item.installation_status === 'completed'
  ).length;

  // Define columns for the data grid
  const columns = [
    { field: 'store_number', headerName: 'Store #', width: 100 },
    { field: 'state', headerName: 'State', width: 70 },
    { field: 'city', headerName: 'City', width: 130 },
    { field: 'serial_number', headerName: 'Serial #', width: 130 },
    { field: 'asset_number', headerName: 'Asset #', width: 130 },
    { field: 'installed_by', headerName: 'Installed By', width: 130 },
    { field: 'installation_date', 
      headerName: 'Install Date', 
      width: 130,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString()
    },
    { field: 'sent_to_kdp', 
      headerName: 'Sent to KDP', 
      width: 130,
      renderCell: (params) => params.value ? '✓' : ''
    },
  ];

  const handleSendToKDP = async () => {
    const selectedCoolerData = completedData.filter(cooler => 
      selectedCoolers.includes(cooler.cooler_id)
    );

    const alreadySentCoolers = selectedCoolerData.filter(cooler => cooler.sent_to_kdp);
    const newCoolers = selectedCoolerData.filter(cooler => !cooler.sent_to_kdp);

    if (alreadySentCoolers.length > 0) {
      setCoolersToResend(alreadySentCoolers);
      setConfirmResendDialog(true);
      return;
    }

    await sendCoolersToKDP(newCoolers);
  };

  const sendCoolersToKDP = async (coolersToSend) => {
    if (coolersToSend.length === 0) {
      alert('No coolers selected or all selected coolers have already been sent');
      return;
    }

    setIsSending(true);
    setSendingProgress({ current: 0, total: coolersToSend.length });

    const successfullySentCoolers = [];

    for (const [index, cooler] of coolersToSend.entries()) {
      const success = await sendToKDP({
        'Store Number': cooler.store_number,
        'Status': cooler.status || cooler.installation_status,
        'Asset Number': cooler.asset_number,
        'Serial Number': cooler.serial_number,
        'Notes': cooler.notes,
        photos: cooler.photos,
        deliveryComplete: cooler.delivery_complete,
        deliveryNotes: cooler.delivery_notes,
        installation_date: cooler.installation_date
      });

      if (success) {
        successfullySentCoolers.push(cooler.cooler_id);
      }
      
      setSendingProgress(prev => ({ ...prev, current: index + 1 }));
    }

    if (successfullySentCoolers.length > 0) {
      await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/mark_coolers_sent', {
        coolerIds: successfullySentCoolers
      });

      setShowSuccess(true);
      setTimeout(async () => {
        await fetchData();
        setSelectedCoolers([]);
        setIsSending(false);
        setShowSuccess(false);
      }, 1500);
    } else {
      setIsSending(false);
      alert('No coolers were successfully sent to KDP');
    }
  };

  const navigate = useNavigate();

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <div className="animate-pulse space-y-2 text-center">
          <div className="w-12 h-12 mx-auto border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
          <Typography sx={{ color: 'text.secondary' }}>Loading dashboard...</Typography>
        </div>
      </Container>
    );
  }

  if (isSending || showSuccess) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999
        }}
      >
        <AnimatePresence>
          {isSending && !showSuccess && (
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.5, opacity: 0 }}
              style={{ textAlign: 'center' }}
            >
              <CircularProgress
                size={60}
                thickness={4}
                sx={{ color: '#1B40A7', mb: 2 }}
              />
              <Typography 
                variant="h6" 
                sx={{ 
                  fontFamily: 'NeueEinstellungBold',
                  color: '#1B40A7',
                  mt: 2 
                }}
              >
                Sending to KDP...
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  fontFamily: 'NeueEinstellungBold',
                  color: '#1B40A7',
                  mt: 1 
                }}
              >
                {sendingProgress.current} of {sendingProgress.total} coolers sent
              </Typography>
            </motion.div>
          )}
          
          {showSuccess && (
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              style={{ textAlign: 'center' }}
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ 
                  type: "spring",
                  stiffness: 260,
                  damping: 20 
                }}
              >
                <CheckCircleIcon 
                  sx={{ 
                    fontSize: 80,
                    color: '#1B40A7',
                    mb: 2
                  }} 
                />
              </motion.div>
              <Typography 
                variant="h5"
                sx={{ 
                  fontFamily: 'NeueEinstellungBold',
                  color: '#1B40A7'
                }}
              >
                Successfully sent {sendingProgress.current} coolers!
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <BackButton />
      
      {/* Welcome Header */}
      <Paper elevation={0} style={welcomeStyle}>
        <Typography 
          variant="h5" 
          sx={{ 
            color: '#fff', 
            fontFamily: "Akira",
            marginBottom: '0.5rem'
          }}
        >
          Project Dashboard
        </Typography>
        <Typography 
          variant="subtitle1" 
          sx={{ 
            color: 'rgba(255, 255, 255, 0.8)',
            fontFamily: 'NeueEinstellungBold' 
          }}
        >
          Completed Installations: {completedInstallations}/{totalInstallations}
        </Typography>
        <Typography 
          variant="subtitle2" 
          sx={{ 
            color: 'rgba(255, 255, 255, 0.8)',
            fontFamily: 'NeueEinstellungBold' 
          }}
        >
          Last updated: {new Date().toLocaleDateString()}
        </Typography>
      </Paper>

      <Grid container spacing={3}>
        {/* Installation Status */}
        

        {/* Project Milestones */}
        <Grid item xs={12}>
          <Paper 
            elevation={0} 
            style={{
              ...StyledPaper,
              cursor: 'pointer',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 15px rgba(27, 64, 167, 0.2)'
              }
            }}
            onClick={() => navigate('/installation-breakdown')}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold' }}>
                Project Milestones
              </Typography>
              <Box 
                sx={{ 
                  backgroundColor: 'rgba(27, 64, 167, 0.1)', 
                  padding: '4px 12px', 
                  borderRadius: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <Typography 
                  variant="body2" 
                  sx={{ 
                    fontFamily: 'NeueEinstellungBold',
                    color: '#1B40A7'
                  }}
                >
                  View Installation Details
                </Typography>
                <TrendingUpIcon sx={{ color: '#1B40A7', fontSize: '1.2rem' }} />
              </Box>
            </Box>

            <Grid container spacing={3}>
              {/* Completion Rate */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: '12px',
                    background: 'linear-gradient(135deg, rgba(27, 64, 167, 0.1), rgba(43, 91, 224, 0.1))',
                    mb: 2
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <TrendingUpIcon sx={{ color: '#1B40A7', mr: 1 }} />
                    <Typography sx={{ fontFamily: 'NeueEinstellungBold', color: '#1B40A7' }}>
                      Overall Progress
                    </Typography>
                  </Box>
                  <Box sx={{ position: 'relative', height: '24px', bgcolor: 'rgba(255,255,255,0.5)', borderRadius: '12px' }}>
                    <Box
                      sx={{
                        position: 'absolute',
                        height: '100%',
                        bgcolor: '#1B40A7',
                        borderRadius: '12px',
                        width: `${(completedInstallations / totalInstallations) * 100}%`,
                        transition: 'width 1s ease-in-out'
                      }}
                    />
                    <Typography
                      sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#fff',
                        fontFamily: 'NeueEinstellungBold',
                        fontSize: '0.875rem',
                        zIndex: 1
                      }}
                    >
                      {Math.round((completedInstallations / totalInstallations) * 100)}%
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Key Metrics */}
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: '12px',
                    bgcolor: '#f8fafc',
                    height: '100%'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckCircleOutlineIcon sx={{ color: '#00C49F', mr: 1 }} />
                    <Typography sx={{ fontFamily: 'NeueEinstellungBold' }}>
                      Completed
                    </Typography>
                  </Box>
                  <Typography variant="h4" sx={{ fontFamily: 'NeueEinstellungBold', color: '#00C49F' }}>
                    {completedInstallations}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    installations
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: '12px',
                    bgcolor: '#f8fafc',
                    height: '100%'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <PendingIcon sx={{ color: '#FFBB28', mr: 1 }} />
                    <Typography sx={{ fontFamily: 'NeueEinstellungBold' }}>
                      Remaining
                    </Typography>
                  </Box>
                  <Typography variant="h4" sx={{ fontFamily: 'NeueEinstellungBold', color: '#FFBB28' }}>
                    {totalInstallations - completedInstallations}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    to complete
                  </Typography>
                </Box>
              </Grid>

              {/* Recent Activity */}
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: '12px',
                    bgcolor: '#f8fafc',
                    height: '100%'
                  }}
                >
                  <Typography sx={{ fontFamily: 'NeueEinstellungBold', mb: 2 }}>
                    Recent Activity
                  </Typography>
                  {completedData.slice(0, 3).map((installation, index) => (
                    <Box
                      key={installation.cooler_id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: index < 2 ? 2 : 0,
                        p: 1,
                        borderRadius: '8px',
                        '&:hover': { bgcolor: 'rgba(27, 64, 167, 0.05)' }
                      }}
                    >
                      <LocalShippingIcon sx={{ color: '#1B40A7', mr: 2 }} />
                      <Box>
                        <Typography variant="body2" sx={{ fontFamily: 'NeueEinstellungBold' }}>
                          Store #{installation.store_number}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                          {new Date(installation.installation_date).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Add DataGrid for cooler list */}
      <Paper elevation={0} style={StyledPaper}>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'NeueEinstellungBold' }}>
          Completed Coolers
        </Typography>
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={completedData}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25, 50, 75]}
            checkboxSelection
            getRowId={(row) => row.cooler_id}
            onSelectionModelChange={(newSelection) => {
              setSelectedCoolers(newSelection);
            }}
            selectionModel={selectedCoolers}
          />
        </Box>
        <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
          <Button
            onClick={handleSendToKDP}
            disabled={selectedCoolers.length === 0}
            variant="contained"
            sx={{
              backgroundColor: '#1B40A7',
              '&:hover': { backgroundColor: '#152e78' }
            }}
          >
            Send Selected to KDP
          </Button>
          <Button
            onClick={() => setSelectedCoolers(completedData.map(c => c.cooler_id))}
            variant="outlined"
          >
            Select All
          </Button>
        </Box>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
        <Button onClick={exportToExcel} variant="contained">
          Export Report
        </Button>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmResendDialog}
        onClose={() => setConfirmResendDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '10px',
            width: '100%',
            maxWidth: { xs: '90%', sm: '500px' }
          }
        }}
      >
        <DialogTitle sx={{ fontFamily: 'NeueEinstellungBold' }}>
          Confirm Resend
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            The following coolers have already been sent to KDP:
          </Typography>
          {coolersToResend.map(cooler => (
            <Typography key={cooler.cooler_id} sx={{ mb: 1, color: 'text.secondary' }}>
              • Store #{cooler.store_number} - Asset #{cooler.asset_number}
            </Typography>
          ))}
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to send them again?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => {
              setConfirmResendDialog(false);
              setCoolersToResend([]);
            }}
            sx={{
              color: 'text.secondary',
              fontFamily: 'NeueEinstellungBold'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setConfirmResendDialog(false);
              await sendCoolersToKDP([...coolersToResend]);
              setCoolersToResend([]);
            }}
            sx={{
              backgroundColor: '#1B40A7',
              color: 'white',
              fontFamily: 'NeueEinstellungBold',
              '&:hover': {
                backgroundColor: '#152e78'
              }
            }}
          >
            Send Again
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ProjectReport;
