import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { 
  Button, 
  Typography, 
  Box, 
  Container,
  useMediaQuery,
  useTheme,
  keyframes
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Define the gradient animation
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(-45deg, #ffffff, #6383db, #1B40A7, #152e78)',
        backgroundSize: '400% 400%',
        animation: `${gradientAnimation} 15s ease infinite`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.2) 100%)',
          pointerEvents: 'none'
        }
      }}
    >
      {/* Optional floating particles effect */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
          zIndex: 1,
          opacity: 1,
          '& > div': {
            position: 'absolute',
            background: 'rgba(255,255,255,0.1)',
            borderRadius: '50%',
            animation: `${floatAnimation} 20s infinite`,
          }
        }}
      />

      {/* Add blur overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: '-20px',
          left: '-20px',
          right: '-20px',
          bottom: '-20px',
          backdropFilter: 'blur(40px)',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          zIndex: 1
        }}
      />

      <Container maxWidth="sm" sx={{ position: 'relative', zIndex: 2 }}>
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            borderRadius: '34px',
            padding: isMobile ? '40px 20px' : '60px 40px',
            textAlign: 'center',
            backdropFilter: 'blur(10px)',
            boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.08)',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0px 12px 40px rgba(0, 0, 0, 0.12)',
            }
          }}
        >
          <img 
            src="/shurlogo.png" 
            alt="Logo" 
            style={{ 
              width: isMobile ? '180px' : '240px',
              marginBottom: '40px'
            }} 
          />
          
          <Typography
            variant={isMobile ? "h5" : "h4"}
            sx={{
              color: '#000000',
              fontFamily: 'Akira',
              marginBottom: '32px',
              letterSpacing: '1px'
            }}
          >
            Welcome Back
          </Typography>

          <Button
            variant="contained"
            onClick={() => loginWithRedirect()}
            endIcon={<ArrowForwardIcon />}
            sx={{
              width: isMobile ? '90%' : '300px',
              height: '56px',
              backgroundColor: '#1B40A7',
              fontFamily: 'NeueEinstellungBold',
              borderRadius: '12px',
              boxShadow: '0px 4px 20px rgba(27, 64, 167, 0.15)',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: '#152e78',
                transform: 'translateY(-2px)',
                boxShadow: '0px 6px 25px rgba(27, 64, 167, 0.25)',
              }
            }}
          >
            Sign In to Continue
          </Button>
        </Box>

        <Typography
          variant="body2"
          sx={{
            color: 'rgba(255, 255, 255, 0.8)',
            textAlign: 'center',
            marginTop: '24px',
            fontFamily: 'NeueEinstellungNormal'
          }}
        >
          © {new Date().getFullYear()} Shur Solution. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

// Define floating animation for particles
const floatAnimation = keyframes`
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-100px) rotate(180deg);
  }
  100% {
    transform: translateY(0) rotate(360deg);
  }
`;

export default Login;
