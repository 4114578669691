// TechnicianData.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, TextField, Paper, Card, Collapse, IconButton, CardContent, Button, Box, Grid } from '@mui/material';
import axios from 'axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import debounce from 'lodash.debounce';
import { VictoryPie, VictoryTooltip, VictoryLabel } from 'victory';
import { useTheme } from '@mui/material/styles';
import BackButton from '../components/BackButton';

const BlueLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: '30px',
  borderRadius: '8px',
  backgroundColor: '#CCCCCC',
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px',
    backgroundColor: value > 100 ? '#FF0000' : '#1B40A7',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '20px',
  padding: '1.5rem',
  marginTop: '1.5rem',
  boxShadow: 'none', // Remove shadow
}));

const TechnicianData = () => {
  const { name } = useParams();
  const [techData, setTechData] = useState(null);
  const [open, setOpen] = useState(false);
  const [servicedCoolers, setServicedCoolers] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [photoCompletionData, setPhotoCompletionData] = useState(null);
  const theme = useTheme();

  const handleSearchChange = debounce((event) => {
    setSearchTerm(event.target.value.toLowerCase());
  }, 300);

  const renderSearchedCoolers = () => {
    return servicedCoolers.filter(cooler =>
      cooler['SerialNumber'].toLowerCase().includes(searchTerm)
    );
  };

  useEffect(() => {
    const fetchTechData = async () => {
      try {
        const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/technician-data/${name}`);
        setTechData(response.data);

        // Fetch serviced coolers for the technician (assuming you have an API endpoint for this)
        const servicedCoolersResponse = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-pm-report-list/${name}`); // Use name here
        setServicedCoolers(servicedCoolersResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTechData();
  }, [name]);

  useEffect(() => {
    const fetchPhotoCompletionData = async () => {
      try {
        const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/technician-photo-completion/${encodeURIComponent(name)}`);
        setPhotoCompletionData(response.data);
      } catch (error) {
        console.error('Error fetching photo completion data:', error);
        if (error.response) {
          console.error('Server response:', error.response.data);
        }
        setPhotoCompletionData(null);
      }
    };

    fetchPhotoCompletionData();
  }, [name]);

  if (!techData) {
    return <div>Loading...</div>;
  }

  const {
    ytdProgress,
    mtdProgress,
    totalCoolersServiced,
    thisYear,
    thisMonth,
    gasketsConsumed,
    coverKitsConsumed,
  } = techData;

  const PhotoCompletionChart = ({ data }) => {
    const theme = useTheme();
    const chartData = [
      { x: "All Photos", y: data.allPhotos },
      { x: "Missing 1", y: data.missingOne },
      { x: "Missing 2", y: data.missingTwo },
      { x: "Missing 3", y: data.missingThree },
    ];

    return (
      <Box sx={{ height: 200, width: '100%' }}>
        <VictoryPie
          data={chartData}
          colorScale={["#1B40A7", "#4CAF50", "#FFC107", "#F44336"]}
          labels={({ datum }) => `${datum.x}: ${datum.y}`}
          labelComponent={
            <VictoryTooltip
              style={{ fontSize: 10, fontFamily: 'NeueEinstellungNormal' }}
            />
          }
          style={{
            labels: { fill: theme.palette.text.primary, fontSize: 10, fontFamily: 'NeueEinstellungNormal' },
            data: { stroke: "#fff", strokeWidth: 1 }
          }}
          innerRadius={50}
          labelRadius={({ innerRadius }) => innerRadius + 30 }
        />
      </Box>
    );
  };

  return (
    <Container>
      <BackButton />
      <Typography variant="h4" gutterBottom style={{ fontFamily: 'NeueEinstellungBold' }}>
        {name}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'NeueEinstellungBold' }}>
              Progress
            </Typography>
            <Box mb={2}>
              <Typography variant="body1" color="black" fontFamily="Grifter">
                YTD Progress: {ytdProgress}%
              </Typography>
              <BlueLinearProgress variant="determinate" value={Math.min(ytdProgress, 100)} />
            </Box>
            <Box>
              <Typography variant="body1" color="black" fontFamily="Grifter">
                MTD Progress: {mtdProgress}% {mtdProgress > 100 && '(Exceeded)'}
              </Typography>
              <BlueLinearProgress variant="determinate" value={Math.min(mtdProgress, 100)} />
            </Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom style={{ fontFamily: 'NeueEinstellungBold' }}>
              Statistics
            </Typography>
            <Typography variant="body1" color="black" fontFamily="Grifter">
              Total Coolers Serviced: {totalCoolersServiced}
            </Typography>
            <Typography variant="body1" color="black" fontFamily="Grifter">
              This Year: {thisYear}
            </Typography>
            <Typography variant="body1" color="black" fontFamily="Grifter">
              This Month: {thisMonth}
            </Typography>
            <Typography variant="body1" color="black" fontFamily="Grifter">
              Gaskets Consumed: {gasketsConsumed}
            </Typography>
            <Typography variant="body1" color="black" fontFamily="Grifter">
              Cover Kits Consumed: {coverKitsConsumed}
            </Typography>
          </StyledPaper>
        </Grid>
        {photoCompletionData && (
          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'NeueEinstellungBold' }}>
                Photo Completion Status
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <PhotoCompletionChart data={photoCompletionData} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="body2" color="textSecondary" fontFamily="NeueEinstellungNormal">
                      Total Serviced Coolers: {photoCompletionData.total}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" fontFamily="NeueEinstellungNormal">
                      Coolers with All Photos: {photoCompletionData.allPhotos}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" fontFamily="NeueEinstellungNormal">
                      Coolers Missing 1 Photo: {photoCompletionData.missingOne}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" fontFamily="NeueEinstellungNormal">
                      Coolers Missing 2 Photos: {photoCompletionData.missingTwo}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" fontFamily="NeueEinstellungNormal">
                      Coolers Missing 3 Photos: {photoCompletionData.missingThree}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
        )}
      </Grid>
      <StyledPaper>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontFamily: 'NeueEinstellungBold',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setOpen(!open)}
        >
          Serviced Coolers
          <IconButton size="small">
            <KeyboardArrowDownIcon
              sx={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
              }}
            />
          </IconButton>
        </Typography>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <TextField
            fullWidth
            label="Search by Serial Number"
            variant="outlined"
            onChange={handleSearchChange}
            sx={{ marginBottom: '1rem' }}
          />
          {searchTerm ? (
            renderSearchedCoolers().map((cooler, idx) => (
              <Card
                key={idx}
                sx={{
                  mb: 2,
                  boxShadow: 'none', // Remove shadow
                  borderRadius: '10px',
                }}
              >
                <CardContent onClick={() => navigate(`/cooler-details/${cooler['SerialNumber']}`)}>
                  <Typography variant="h6" sx={{ color: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}>
                    {`Cooler ID: ${cooler['SerialNumber']}`}
                  </Typography>
                  <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                    {`Model: ${cooler.Model}`}
                  </Typography>
                  <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                    {`${cooler.ShippingCity}, ${cooler.ShippingState}`}
                  </Typography>
                  {/* Add more cooler details as needed */}
                </CardContent>
              </Card>
            ))
          ) : (
            servicedCoolers.map((cooler, idx) => (
              <Card
                key={idx}
                sx={{
                  mb: 2,
                  boxShadow: 'none', // Remove shadow
                  borderRadius: '10px',
                }}
              >
                <CardContent onClick={() => navigate(`/cooler-details/${cooler['SerialNumber']}`)}>
                  <Typography variant="h6" sx={{ color: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}>
                    {`Cooler ID: ${cooler['SerialNumber']}`}
                  </Typography>
                  <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                    {`Model: ${cooler.Model}`}
                  </Typography>
                  <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                    {`${cooler.ShippingCity}, ${cooler.ShippingState}`}
                  </Typography>
                  {/* Add more cooler details as needed */}
                </CardContent>
              </Card>
            ))
          )}
        </Collapse>
      </StyledPaper>
    </Container>
  );
};

export default TechnicianData;
