import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';

const ChildOfDetails = () => {
  const { childOf } = useParams();
  const [coolers, setCoolers] = useState([]);

  useEffect(() => {
    axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/newcooler/childof/${childOf}`)
      .then(response => {
        setCoolers(response.data);
      })
      .catch(err => {
        console.error('Error fetching coolers', err);
      });
  }, [childOf]);

  if (coolers.length === 0) {
    return <div>Loading coolers or no coolers found...</div>;
  }

  return (
    <div style={{ padding: '1rem', backgroundColor: '#F5F5F5', minHeight: '100vh' }}>
      <Grid container spacing={2}>
        {coolers.map((cooler, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card style={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
              <CardContent>
                <Typography variant="h6" style={{ color: '#5A67D8', fontFamily: 'NeueEinstellungBold' }}>
                  Serial Number: {cooler.AssetSerial_Number}
                </Typography>
                <Typography style={{ fontFamily: 'NeueEinstellungNormal' }}>
                  Status: {cooler.AccountStatus}
                </Typography>
                <Typography style={{ fontFamily: 'NeueEinstellungNormal' }}>
                  Name: {cooler.AccountName}
                </Typography>
                <Typography style={{ fontFamily: 'NeueEinstellungNormal' }}>
                  Model: {cooler.Model}
                </Typography>
                <Typography style={{ fontFamily: 'NeueEinstellungNormal' }}>
                  Address: {cooler.ShippingAddressLine1}
                </Typography>
                <Typography style={{ fontFamily: 'NeueEinstellungNormal' }}>
                  Asset Status: {cooler.AssetStatus}
                </Typography>
                <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/details/${cooler.AssetSerial_Number}`}
                  style={{ width: '100%', marginBottom: '0.5rem', backgroundColor: '#1B40A7', fontFamily: 'NeueEinstellungBold' }}
                >
                  View Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ChildOfDetails;
