import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Typography,
    CircularProgress,
    Pagination,
    Container,
    Box,
    Card,
    CardContent,
    Grid,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from 'axios';

const Installations = () => {
    const [installationsData, setInstallationsData] = useState({
        installations: [],
        total: 0,
        page: 1,
        totalPages: 1
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        fetchInstallations(1);
    }, []);

    const fetchInstallations = async (page) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/installations?page=${page}&sortCompleted=true`);
            if (response.data && Array.isArray(response.data.installations)) {
                setInstallationsData(response.data);
            } else {
                console.error('Unexpected data format:', response.data);
                setError('Unexpected data format received from server');
            }
        } catch (error) {
            console.error('Error fetching installations:', error);
            setError('Failed to fetch installations');
        } finally {
            setLoading(false);
        }
    };

    const sortInstallations = (installations) => {
        return installations.sort((a, b) => {
            if (a.installation_status.toLowerCase() === 'completed' && b.installation_status.toLowerCase() !== 'completed') {
                return -1;
            }
            if (a.installation_status.toLowerCase() !== 'completed' && b.installation_status.toLowerCase() === 'completed') {
                return 1;
            }
            return 0;
        });
    };

    const handlePageChange = (event, value) => {
        fetchInstallations(value);
    };

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'completed':
                return '#4caf50';
            case 'in progress':
                return '#ff9800';
            case 'pending':
                return '#f44336';
            default:
                return '#757575';
        }
    };

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="80vh"
            >
                <CircularProgress style={{ color: '#1B40A7' }} />
            </Box>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography color="error" sx={{ fontFamily: 'NeueEinstellungBold' }}>
                    {error}
                </Typography>
            </Container>
        );
    }

    const renderMobileView = () => (
        <Grid container spacing={2}>
            {installationsData.installations.map((installation) => (
                <Grid item xs={12} key={installation.id}>
                    <Card
                        sx={{
                            borderRadius: '10px',
                            boxShadow: 'none',
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'translateY(-4px)',
                                transition: 'transform 0.2s'
                            }
                        }}
                        onClick={() => navigate(`/installed-cooler/${installation.id}`)}
                    >
                        <CardContent>
                            <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', color: '#1B40A7' }}>
                                Store #{installation.store_number}
                            </Typography>
                            <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                {installation.cooler_type}
                            </Typography>
                            <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                {`${installation.city}, ${installation.state}`}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <Box
                                    sx={{
                                        width: 10,
                                        height: 10,
                                        borderRadius: '50%',
                                        backgroundColor: getStatusColor(installation.installation_status),
                                        mr: 1
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'NeueEinstellungNormal',
                                        color: getStatusColor(installation.installation_status)
                                    }}
                                >
                                    {installation.installation_status}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );

    const renderDesktopView = () => (
        <TableContainer
            component={Paper}
            sx={{
                borderRadius: '10px',
                boxShadow: 'none',
                backgroundColor: '#fff'
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontFamily: 'NeueEinstellungBold' }}>Store Number</TableCell>
                        <TableCell sx={{ fontFamily: 'NeueEinstellungBold' }}>Cooler Type</TableCell>
                        <TableCell sx={{ fontFamily: 'NeueEinstellungBold' }}>Location</TableCell>
                        <TableCell sx={{ fontFamily: 'NeueEinstellungBold' }}>Installation Status</TableCell>
                        <TableCell sx={{ fontFamily: 'NeueEinstellungBold' }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {installationsData.installations.map((installation) => (
                        <TableRow
                            key={installation.id}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(27, 64, 167, 0.04)'
                                }
                            }}
                        >
                            <TableCell sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                {installation.store_number}
                            </TableCell>
                            <TableCell sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                {installation.cooler_type}
                            </TableCell>
                            <TableCell sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                {`${installation.city}, ${installation.state}`}
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: getStatusColor(installation.installation_status),
                                            mr: 1
                                        }}
                                    />
                                    <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                        {installation.installation_status}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Button
                                    component={Link}
                                    to={`/installed-cooler/${installation.id}`}
                                    variant="contained"
                                    endIcon={<KeyboardArrowRightIcon />}
                                    sx={{
                                        backgroundColor: '#1B40A7',
                                        fontFamily: 'NeueEinstellungBold',
                                        borderRadius: '8px',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            backgroundColor: '#152e78'
                                        }
                                    }}
                                >
                                    View
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <Container sx={{ py: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        color: 'rgb(27, 64, 167)',
                        mr: 2,
                        '&:hover': { backgroundColor: 'rgba(27, 64, 167, 0.1)' }
                    }}
                >
                    <ArrowBackIcon />
                </Button>
                <Typography
                    variant="h4"
                    sx={{
                        fontFamily: 'NeueEinstellungBold',
                        fontSize: { xs: '1.5rem', sm: '2rem' }
                    }}
                >
                    All Installations
                </Typography>
            </Box>

            {installationsData.installations.length === 0 ? (
                <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                    No installations found.
                </Typography>
            ) : (
                <>
                    {isMobile ? renderMobileView() : renderDesktopView()}

                    <Box sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Pagination
                            count={installationsData.totalPages}
                            page={installationsData.page}
                            onChange={handlePageChange}
                            sx={{
                                '& .MuiPaginationItem-root': {
                                    fontFamily: 'NeueEinstellungBold'
                                },
                                '& .Mui-selected': {
                                    backgroundColor: '#1B40A7 !important',
                                    color: 'white'
                                }
                            }}
                        />
                    </Box>
                </>
            )}
        </Container>
    );
};

export default Installations;
