// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import TechnicianDashboard from './views/TechnicianDashboard';
import AdminDashboard from './views/AdminDashboard';
import DataDashboard from './views/DataDashboard';
import CoolerDetails from './views/CoolerDetails';
import CoolerHierarchy from './views/CoolerHierarchy';
import PMOwners from './views/PMOwners';
import NotFound from './views/NotFound';
import Settings from './views/Settings';
import Sidebar from './views/Sidebar';
import BigMap from './views/BigMap';
import TechnicianData from './views/TechnicianData';
import FileUploadPage from './views/FileUploadPage';
import TechPmReport from './views/TechPmReport';
import ChildOfDetails from './views/ChildOfDetails';
import AssignTechs from './views/AssignTechs';
import UpdateRecords from './views/UpdateRecords';
import GroupListView from './views/GroupListView';
import ChildOfListView from './views/ChildOfListView';
import CoolersListView from './views/CoolersListView';
import PredictiveMaintenance from './views/PredictiveMaintenance';
import AddCooler from './views/AddCooler';
import Login from './views/Login';
import ServicePage from './views/ServicePage';
import { CircularProgress, Box, useMediaQuery, Menu as MenuIcon, IconButton, useTheme, AppBar, Toolbar } from '@mui/material';
import './App.css';
import KommunicateChat from './views/chat';
import UploadCoolers from './views/UploadCoolers';
import ProjectInstallation from './views/ProjectInstallation';
import ProjectList from './views/ProjectList';
import ProjectInstallations from './views/ProjectInstallations';
import AddProject from './views/AddProject';
import AssetScanner from './components/AssetScanner';
import InstalledCoolerDetails from './views/InstalledCoolerDetails';
import Installations from './views/Installations';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ProjectReport from './views/ProjectReport';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageProcessor from './views/ImageProcessor';
import InstallationBreakdown from './views/InstallationBreakdown';
import TechnicianInstallations from './views/TechnicianInstallations';
import { SidebarProvider } from './contexts/SidebarContext';


const App = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const [show, setShow] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [open, setOpen] = useState(true);

  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    checkSession
  } = useAuth0();

  const TOKEN_RENEWAL_INTERVAL = 30 * 60 * 1000;

  useEffect(() => {
    const fetchUserRole = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch('https://freshpet-pm-9087312eb841.herokuapp.com/api/get-user-role', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ userId: user.sub })
          });

          if (response.ok) {
            const data = await response.json();
            setUserRole(data.roles);
            setShow(data.roles.includes('Admin'));
          }
        } catch (error) {
          console.error("Error fetching user role", error);
        }
      }
    };
    fetchUserRole();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  useEffect(() => {
    const silentAuth = async () => {
      try {
        await checkSession();
      } catch (error) {
        if (error.error === "login_required") {
          loginWithRedirect();
        }
      }
    };
    silentAuth();

    const renewToken = async () => {
      if (isAuthenticated) {
        try {
          await getAccessTokenSilently();
        } catch (error) {
          console.error("Error renewing Auth0 token:", error);
        }
      }
    };

    const intervalId = setInterval(renewToken, TOKEN_RENEWAL_INTERVAL);
    return () => clearInterval(intervalId);
  }, [checkSession, loginWithRedirect, getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    setSidebarOpen(!isMobile);
  }, [isMobile]);

  if (isLoading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <CircularProgress style={{ color: '#1B40A7' }} />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  const Dashboard = show ? AdminDashboard : TechnicianDashboard;

  return (
    <SidebarProvider isOpen={open}>
      <div style={{ display: 'flex', minHeight: '100vh', backgroundColor: "rgb(245,245,245)" }}>
        <Router>
          {/* Replace the floating IconButton with this AppBar */}
          {isMobile && (
            <AppBar 
              position="fixed" 
              sx={{ 
                backgroundColor: 'white',
                boxShadow: '0',
                zIndex: 1200 
              }}
            >
              <Toolbar 
                sx={{ 
                  minHeight: '64px !important',
                  padding: '0 16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <IconButton
                  edge="start"
                  aria-label="menu"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                  sx={{ 
                    color: '#1B40A7',
                    backgroundColor: 'rgba(27, 64, 167, 0.08)',
                    borderRadius: '8px',
                    padding: '8px',
                    marginLeft: '2px',
                    '&:hover': {
                      backgroundColor: 'rgba(27, 64, 167, 0.15)',
                    },
                    transition: 'all 0.2s ease-in-out',
                    transform: showMobileMenu ? 'rotate(-180deg)' : 'rotate(0deg)'
                  }}
                >
                  <MenuOpenIcon 
                    sx={{ 
                      fontSize: '28px',
                      transition: 'transform 0.2s ease-in-out'
                    }} 
                  />
                </IconButton>
                
              </Toolbar>
            </AppBar>
          )}

          <Sidebar
            open={isMobile ? showMobileMenu : sidebarOpen}
            onClose={() => setShowMobileMenu(false)}
            onToggle={() => setSidebarOpen(!sidebarOpen)}
            isMobile={isMobile}
          />

          <main style={{
            flexGrow: 1,
            marginLeft: isMobile ? 0 : (sidebarOpen ? '240px' : '64px'),
            transition: 'margin-left 0.3s',
            padding: '2rem',
            paddingTop: isMobile ? '5rem' : '0rem',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <div style={{
              marginTop: '20px',
              width: '100%',
              maxWidth: '2000px',
            }}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/technician" element={<TechnicianDashboard />} />
                <Route path="/datadashboard" element={<DataDashboard />} />
                <Route path="/cooler-details/:serialNumber" element={<CoolerDetails />} />
                <Route path="/add-cooler" element={<AddCooler />} />
                <Route path="/tech-report" element={<TechPmReport />} />
                <Route path="/newcoolers" element={<ChildOfListView />} />
                <Route path="/file-upload" element={<FileUploadPage />} />
                <Route path="/technician-data/:name" element={<TechnicianData />} />
                <Route path="/coolers/:childOf" element={<CoolersListView />} />
                <Route path="/childof-details/:childOf" element={<ChildOfDetails />} />
                <Route path="/manage-pm-owners" element={<PMOwners />} />
                <Route path="/assign-techs" element={<AssignTechs />} />
                <Route path="/big-map" element={<BigMap />} />
                <Route path="/cooler-records" element={<CoolerHierarchy />} />
                <Route path="/service/:coolerId" element={<ServicePage />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/project-installation/:projectId/:installationId" element={<ProjectInstallation />} />
                <Route path="/upload-coolers" element={<UploadCoolers />} />
                <Route path="/predictive-maintenance" element={<PredictiveMaintenance />} />
                <Route path="/projects" element={<ProjectList />} />
                <Route path="/projects/:projectId/installations" element={<ProjectInstallations />} />
                <Route path="/projects/:projectId/installations/:installationId" element={<ProjectInstallation />} />
                <Route path="/projects/new" element={<AddProject />} />
                <Route path="/installed-cooler/:coolerId" element={<InstalledCoolerDetails />} />
                <Route path="/installations" element={<Installations />} />
                <Route path="/asset-scanner" element={<ImageProcessor />} />
                <Route path="/projects/:projectId/report" element={<ProjectReport />} />
                <Route path="/installation-breakdown" element={<InstallationBreakdown />} />
                <Route path="/technician-installations/:techName" element={<TechnicianInstallations />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </main>
        </Router>
      </div>
    </SidebarProvider>
  );
};

const Auth0ProviderWithRedirect = ({ children }) => {
  const domain = "dev-lzhsywd3ggnqo1p0.us.auth0.com";
  const clientId = "cWCoW5MjmBxUfP8UUll9QcdLbp9KBdF2";

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};

export default () => (
  <Auth0ProviderWithRedirect>
    <App />
  </Auth0ProviderWithRedirect>
);