import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Import for the dot icon
import {
  Container,
  Typography,
  Paper,
  Card,
  CardContent,
  CardActionArea,
  CircularProgress,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  IconButton,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Checkbox, FormControlLabel } from '@mui/material';
import BackButton from '../components/BackButton';
const AssignTechs = () => {

  const [dontAskAgain, setDontAskAgain] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [coolerToReassign, setCoolerToReassign] = useState(null);
  const [stateCityGroups, setStateCityGroups] = useState({});
  const [openState, setOpenState] = useState({});
  const [openCity, setOpenCity] = useState({});
  const navigate = useNavigate();
  const [techUsers, setTechUsers] = useState([]);
  const [selectedTech, setSelectedTech] = useState(null);
  const [selectedCoolers, setSelectedCoolers] = useState([]);
  const stateContainsSelectedCooler = (state) => {
  return Object.values(selectedCoolers).some(
    (cooler) => cooler.ShippingState === state
  );
};
const toStartCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace(/'\w/g, match => match.toLowerCase());
};

const selectCooler = (cooler) => {
  const serialNumber = cooler['SerialNumber'];
  setSelectedCoolers(prevSelectedCoolers => {
    if (prevSelectedCoolers[serialNumber]) {
      const updatedSelectedCoolers = { ...prevSelectedCoolers };
      delete updatedSelectedCoolers[serialNumber];
      return updatedSelectedCoolers;
    } else {
      return { ...prevSelectedCoolers, [serialNumber]: cooler };
    }
  });
};

const cityContainsSelectedCooler = (city) => {
  // Convert the city to lower case for comparison
  const lowerCaseCity = city.toLowerCase();

  return Object.values(selectedCoolers).some(
    (cooler) => cooler.ShippingCity.toLowerCase() === lowerCaseCity
  );
};
useEffect(() => {
  const skipConfirmation = localStorage.getItem('skipReassignConfirmation') === 'true';
  setDontAskAgain(skipConfirmation);
}, []);

useEffect(() => {
axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/newcoolers')
  .then(response => {
    const groupedByStateCity = response.data.reduce((acc, cooler) => {
      const state = cooler.ShippingState; // Assuming state case sensitivity is not an issue
      // Convert city to lower case for consistent grouping
      const city = cooler.ShippingCity.toLowerCase();

      if (!acc[state]) {
        acc[state] = {};
      }
      if (!acc[state][city]) {
        acc[state][city] = [];
      }
      acc[state][city].push(cooler);
      return acc;
    }, {});

    setStateCityGroups(groupedByStateCity);
  })
  .catch(err => console.error('Error fetching coolers', err));
}, []);

  const handleClickState = (state) => {
    setOpenState({ ...openState, [state]: !openState[state] });
  };

  const handleClickCity = (state, city) => {
    setOpenCity({ ...openCity, [`${state}-${city}`]: !openCity[`${state}-${city}`] });
  };
  const handleSaveAssignedCoolers = async () => {
  try {
    await Promise.all(Object.keys(selectedCoolers).map(async (coolerId) => {
      await axios.put(`https://freshpet-pm-9087312eb841.herokuapp.com/api/assign-tech-to-cooler/${coolerId}`, {
        technicianUserId: selectedTech.user_id,
        technicianName: selectedTech.name  // Include the technician's name
      });
    }));
    setSelectedTech(null);
    setSelectedCoolers({});
  } catch (error) {
    console.error('Error saving assigned coolers:', error);
  }
};
const handleToggleAssignCoolersInCity = (coolersInCity) => {
  const updatedSelectedCoolers = { ...selectedCoolers };

  // Check if any cooler in the city is already selected
  const anySelected = coolersInCity.some(cooler =>
    updatedSelectedCoolers.hasOwnProperty(cooler['SerialNumber'])
  );

  coolersInCity.forEach((cooler) => {
    const serialNumber = cooler['SerialNumber'];

    if (anySelected) {
      // If any coolers are selected, deselect them
      delete updatedSelectedCoolers[serialNumber];
    } else {
      // If no coolers are selected, select them
      updatedSelectedCoolers[serialNumber] = cooler;
    }
  });

  setSelectedCoolers(updatedSelectedCoolers);
};

const handleClickCooler = (cooler) => {
  const serialNumber = cooler['SerialNumber'];
  const skipConfirmation = localStorage.getItem('skipReassignConfirmation') === 'true';

  if (selectedCoolers[serialNumber]) {
    selectCooler(cooler);
  } else if (!skipConfirmation && cooler.AssignedTechnicianName && cooler.AssignedTechnicianName !== 'None') {
    setCoolerToReassign(cooler);
    setOpenDialog(true);
  } else {
    selectCooler(cooler);
  }
};

  useEffect(() => {
    // Fetch tech users from your API
    axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/techusers')
      .then(response => {
        setTechUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching tech users', error);
      });
  }, []);

  const handleUserClick = async (user) => {
  // Check if the clicked user is already selected
  if (selectedTech && user.user_id === selectedTech.user_id) {
    // If the same user is selected again, deselect and show the full list
    setSelectedTech(null);
    setSelectedCoolers({});
  } else {
    // If a different user is selected, fetch the assigned cooler count
    try {
      const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/assignedCoolerCount/${user.user_id}`);
      const assignedCoolerCount = parseInt(response.data.count, 10);
      setSelectedTech({ ...user, assignedCoolerCount });
    } catch (error) {
      console.error('Error fetching assigned cooler count', error);
    }
  }
};

const selectedCoolersCount = Object.keys(selectedCoolers).length;
return (
<Container maxWidth="md" sx={{ borderRadius: '10px', marginTop: '2rem', marginBottom: '2rem' }}>
    <BackButton />
  <Paper sx={{ borderRadius: '20px', padding: '2rem' }}>
    <Typography variant="h6" gutterBottom sx={{ fontFamily: 'NeueEinstellungBold' }}>
      Assign Technicians
    </Typography>

    <Collapse in={selectedTech === null}>
      <List>
        {techUsers.map(user => (
          <Card key={user.user_id} sx={{ borderRadius: '5px', marginBottom: '1rem', width: '100%' }}>
            <ListItem onClick={() => handleUserClick(user)} button>
              <ListItemText primary={`${user.name}`} secondary={user.email} />
            </ListItem>
          </Card>
        ))}
      </List>
    </Collapse>

    <Collapse in={selectedTech !== null}>
    {selectedTech && (
  <Card
    sx={{ marginBottom: '1rem', transition: '0.5s' }}
    onClick={() => handleUserClick(selectedTech)} // Attach the event handler here
  >
    <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <ListItem sx={{ padding: 0 }}>
        <ListItemText
          primary={`${selectedTech.name}`}
          secondary={`Coolers Assigned: ${selectedTech.assignedCoolerCount + selectedCoolersCount}`}
        />
      </ListItem>
      <Button size="small" color="primary" onClick={handleSaveAssignedCoolers}>
        Save
      </Button>
    </CardContent>
  </Card>
)}

      <List component="nav">
        {Object.keys(stateCityGroups).map((state) => (
          <React.Fragment key={state}>
            <ListItem button onClick={() => handleClickState(state)}>
              <ListItemText primary={state} />
              {stateContainsSelectedCooler(state) && (
                <Box sx={{ color: 'rgb(100, 124, 189, 0.6)', marginLeft: 1 }}>
                  <FiberManualRecordIcon fontSize="small" />
                </Box>
              )}
            </ListItem>
            <Collapse in={openState[state]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {Object.keys(stateCityGroups[state]).map((city) => (
                  <React.Fragment key={`${state}-${city}`}>
                  <ListItem
                    button
                    sx={{ paddingLeft: '2rem' }}
                    onClick={() => handleClickCity(state, city)}
                    secondaryAction={
                      selectedTech && (
                        <IconButton
                          edge="end"
                          onClick={() => handleToggleAssignCoolersInCity(stateCityGroups[state][city])}
                        >
                          {cityContainsSelectedCooler(city) ? <RemoveIcon /> : <AddIcon />}
                        </IconButton>
                      )
                    }
                  >
                  <ListItemText
primary={toStartCase(city)}
secondary={`Coolers: ${stateCityGroups[state][city].length}`}
/>
                    {cityContainsSelectedCooler(city) && (
                      <Box color="rgb(100, 124, 189, 0.6)" marginLeft={1}>
                        <FiberManualRecordIcon fontSize="small" />
                      </Box>
                    )}
                  </ListItem>
                    <Collapse in={openCity[`${state}-${city}`]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {stateCityGroups[state][city].map((cooler, idx) => (
                          <ListItem
                            button
                            key={idx}
                            sx={{ paddingLeft: '3rem' }}
                            onClick={() => handleClickCooler(cooler)}
                          >
                            <Card
                              sx={{
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '10px',
                                width: '100%',
                                backgroundColor: selectedCoolers[cooler['SerialNumber']] ? 'rgb(100, 124, 189, 0.4)' : 'inherit',
                              }}
                            >
                              <CardContent>
                                <Typography variant="h6" sx={{ color: '#5A67D8', fontFamily: 'NeueEinstellungBold' }}>
                                  {`Cooler ID: ${cooler['SerialNumber']}`}
                                </Typography>
                                <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                  {`Model: ${cooler.Model}`}
                                </Typography>
                                <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                  {`Zip Code: ${cooler.ShippingZip}`}
                                </Typography>
                                <Typography sx={{ marginBottom: '1rem', fontFamily: 'NeueEinstellungNormal' }}>
                                {`Assigned Technician: ${cooler.AssignedTechnicianName ? cooler.AssignedTechnicianName : 'None'}`}

                                </Typography>
                              </CardContent>
                            </Card>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Collapse>
  </Paper>
  <Dialog
  open={openDialog}
  onClose={() => setOpenDialog(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Reassign Technician"}</DialogTitle>
  <DialogContent>
  <DialogContentText id="alert-dialog-description">
    Cooler already has a tech assigned. Are you sure you want to replace them?
  </DialogContentText>
  <FormControlLabel
    control={<Checkbox checked={dontAskAgain} onChange={(e) => setDontAskAgain(e.target.checked)} />}
    label="Don't ask again"
  />
</DialogContent>
  <DialogActions>
  <Button onClick={() => setOpenDialog(false)} color="primary">
    Cancel
  </Button>
  <Button onClick={() => {
  selectCooler(coolerToReassign);
  setOpenDialog(false);
  if (dontAskAgain) {
    localStorage.setItem('skipReassignConfirmation', 'true');
  }
}} color="primary" autoFocus>
  Proceed
</Button>
</DialogActions>
</Dialog>
</Container>
);
};
export default AssignTechs;
