import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Button,
    CircularProgress,
    Box,
    Container,
    Paper,
    Dialog,
    DialogContent,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const InstalledCoolerDetails = () => {
    const { coolerId } = useParams();
    const navigate = useNavigate();
    const [cooler, setCooler] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
        const fetchCoolerDetails = async () => {
            try {
                const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/installed-coolers/${coolerId}`);
                setCooler(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching cooler details:', error);
                setError('Failed to fetch cooler details');
                setLoading(false);
            }
        };

        fetchCoolerDetails();
    }, [coolerId]);

    const handleDelete = async () => {
        try {
            await axios.delete(`https://freshpet-pm-9087312eb841.herokuapp.com/api/installed-coolers/${coolerId}`);
            navigate(-1);
        } catch (error) {
            console.error('Error deleting cooler:', error);
            setError('Failed to delete cooler');
        }
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh'
                }}
            >
                <CircularProgress sx={{ color: '#000000' }} />
            </Box>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography color="error" sx={{ fontFamily: 'NeueEinstellungBold', mt: 4 }}>
                    {error}
                </Typography>
            </Container>
        );
    }

    if (!cooler) {
        return (
            <Container>
                <Typography sx={{ fontFamily: 'NeueEinstellungBold', mt: 4 }}>
                    Cooler not found
                </Typography>
            </Container>
        );
    }

    const DetailRow = ({ label, value }) => (
        <Box sx={{ mb: 1 }}>
            <Typography
                component="span"
                sx={{
                    fontFamily: 'NeueEinstellungBold',
                    color: '#000000',
                }}
            >
                {label}:
            </Typography>
            <Typography
                component="span"
                sx={{
                    ml: 1,
                    fontFamily: 'NeueEinstellungNormal'
                }}
            >
                {value || 'N/A'}
            </Typography>
        </Box>
    );

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        color: '#000000',
                        mr: 2,
                        '&:hover': { backgroundColor: 'rgba(27, 64, 167, 0.1)' }
                    }}
                >
                    <ArrowBackIcon />
                </Button>
                <Typography variant="h4" sx={{ flexGrow: 1, fontFamily: 'NeueEinstellungBold' }}>
                    Cooler Details
                </Typography>
                <Button
                    onClick={() => setDeleteDialogOpen(true)}
                    sx={{
                        color: 'error.main',
                        '&:hover': { backgroundColor: 'rgba(211, 47, 47, 0.1)' }
                    }}
                >
                    <DeleteIcon />
                </Button>
            </Box>

            <Paper
                elevation={0}
                sx={{
                    borderRadius: '10px',
                    overflow: 'hidden',
                    mb: 4
                }}
            >
                <CardContent>
                    <Grid container spacing={4}>
                        {/* Basic Information */}
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    fontFamily: 'NeueEinstellungBold',
                                    color: '#000000'
                                }}
                            >
                                Basic Information
                            </Typography>
                            <DetailRow label="Store Number" value={cooler.store_number} />
                            <DetailRow label="Cooler Type" value={cooler.cooler_type} />
                            <DetailRow label="Address" value={cooler.address} />
                            <DetailRow label="City" value={cooler.city} />
                            <DetailRow label="State" value={cooler.state} />
                            <DetailRow label="County" value={cooler.county} />
                            <DetailRow label="Zip Code" value={cooler.zip_code} />
                            <DetailRow label="Delivery System" value={cooler.delivery_system} />
                            <DetailRow label="SP" value={cooler.sp} />
                            <DetailRow label="Store District" value={cooler.store_district} />
                        </Grid>

                        {/* Service Information */}
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    fontFamily: 'NeueEinstellungBold',
                                    color: '#000000'
                                }}
                            >
                                Service Information
                            </Typography>
                            <DetailRow
                                label="Installation Date"
                                value={cooler.installation_date ? new Date(cooler.installation_date).toLocaleDateString() : 'N/A'}
                            />
                            <DetailRow
                                label="Last Service"
                                value={cooler.last_service_date ? new Date(cooler.last_service_date).toLocaleDateString() : 'N/A'}
                            />
                            <DetailRow
                                label="Next Service"
                                value={cooler.next_service_date ? new Date(cooler.next_service_date).toLocaleDateString() : 'N/A'}
                            />
                            <DetailRow label="Installation Status" value={cooler.installation_status} />
                            <DetailRow label="Service Count" value={cooler.service_count} />
                            <DetailRow label="Needs Attention" value={cooler.needs_attention ? 'Yes' : 'No'} />
                            <DetailRow label="Photos Complete" value={cooler.photos_complete ? 'Yes' : 'No'} />
                            <DetailRow label="Assigned Technician" value={cooler.assigned_technician_name} />
                        </Grid>

                        {/* Contact Information */}
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    fontFamily: 'NeueEinstellungBold',
                                    color: '#000000'
                                }}
                            >
                                Contact Information
                            </Typography>
                            <DetailRow label="District Manager" value={cooler.district_manager} />
                            <DetailRow label="DM Phone" value={cooler.district_manager_phone} />
                            <DetailRow label="DM Email" value={cooler.district_manager_email} />
                        </Grid>

                        {/* Shelf Configuration */}
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    fontFamily: 'NeueEinstellungBold',
                                    color: '#000000'
                                }}
                            >
                                Shelf Configuration
                            </Typography>
                            <DetailRow label="Shelf 1" value={cooler.shelf_1} />
                            <DetailRow label="Shelf 2" value={cooler.shelf_2} />
                            <DetailRow label="Shelf 3" value={cooler.shelf_3} />
                            <DetailRow label="Shelf 4" value={cooler.shelf_4} />
                        </Grid>

                        {/* Notes Section */}
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: 2,
                                    fontFamily: 'NeueEinstellungBold',
                                    color: '#000000'
                                }}
                            >
                                Notes
                            </Typography>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 2,
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '8px',
                                    mb: 2
                                }}
                            >
                                <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                    {cooler.notes || 'No notes available'}
                                </Typography>
                            </Paper>
                        </Grid>

                        {/* Photos Section */}
                        {cooler.photoUrls && cooler.photoUrls.length > 0 && (
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 2,
                                        fontFamily: 'NeueEinstellungBold',
                                        color: '#000000'
                                    }}
                                >
                                    Photos
                                </Typography>
                                <Grid container spacing={2}>
                                    {cooler.photoUrls.map((url, index) => (
                                        <Grid item xs={6} sm={4} md={3} key={index}>
                                            <img
                                                src={url}
                                                alt={`Cooler photo ${index + 1}`}
                                                onClick={() => setSelectedImage(url)}
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                    transition: 'transform 0.2s',
                                                    '&:hover': {
                                                        transform: 'scale(1.05)'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Paper>

            {/* Image Preview Dialog */}
            <Dialog
                open={!!selectedImage}
                onClose={() => setSelectedImage(null)}
                maxWidth="lg"
                fullWidth
            >
                <DialogContent sx={{ p: 0 }}>
                    <img
                        src={selectedImage}
                        alt="Full size"
                        style={{
                            width: '100%',
                            height: 'auto',
                            display: 'block'
                        }}
                    />
                </DialogContent>
            </Dialog>

            <Dialog 
                open={deleteDialogOpen} 
                onClose={() => setDeleteDialogOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '12px',
                        padding: '24px',
                        maxWidth: '400px',
                        width: '100%'
                    }
                }}
            >
                <DialogContent sx={{ p: 0, textAlign: 'center' }}>
                    <DeleteIcon 
                        sx={{ 
                            fontSize: 64, 
                            color: 'error.main',
                            mb: 2
                        }} 
                    />
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            mb: 1,
                            fontFamily: 'NeueEinstellungBold'
                        }}
                    >
                        Delete Installation
                    </Typography>
                    <Typography 
                        sx={{ 
                            mb: 3,
                            color: 'text.secondary',
                            fontFamily: 'NeueEinstellungNormal'
                        }}
                    >
                        Are you sure you want to delete this installation? This action cannot be undone.
                    </Typography>
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            gap: 2,
                            justifyContent: 'center'
                        }}
                    >
                        <Button 
                            onClick={() => setDeleteDialogOpen(false)}
                            variant="outlined"
                            sx={{
                                borderRadius: '8px',
                                px: 3,
                                py: 1,
                                textTransform: 'none',
                                fontFamily: 'NeueEinstellungNormal',
                                borderColor: 'grey.300',
                                color: 'text.primary',
                                '&:hover': {
                                    borderColor: 'grey.400',
                                    backgroundColor: 'grey.50'
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            onClick={handleDelete}
                            variant="contained"
                            color="error"
                            sx={{
                                borderRadius: '8px',
                                px: 3,
                                py: 1,
                                textTransform: 'none',
                                fontFamily: 'NeueEinstellungBold',
                                boxShadow: 'none',
                                '&:hover': {
                                    boxShadow: 'none',
                                    backgroundColor: 'error.dark'
                                }
                            }}
                        >
                            Delete Installation
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default InstalledCoolerDetails;