import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, Typography, Paper, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Button,
  Tooltip, LinearProgress
} from '@mui/material';

const PredictiveMaintenance = () => {
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPredictions();
  }, []);

  const fetchPredictions = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/predictive-maintenance');
      setPredictions(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching predictions:', error);
      setError('Failed to fetch predictions. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleScheduleMaintenance = async (coolerId) => {
    try {
      await axios.post(`https://freshpet-pm-9087312eb841.herokuapp.com/api/schedule-maintenance/${coolerId}`);
      // Refresh predictions after scheduling
      fetchPredictions();
    } catch (error) {
      console.error('Error scheduling maintenance:', error);
      setError('Failed to schedule maintenance. Please try again.');
    }
  };

  const getRiskColor = (score) => {
    if (score == null) return 'primary.main';
    if (score < 3) return 'success.main';
    if (score < 7) return 'warning.main';
    return 'error.main';
  };

  const safeNumberFormat = (value) => {
    return value != null ? Number(value).toFixed(2) : 'N/A';
  };

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Typography>Loading predictions...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom style={{ fontFamily: 'NeueEinstellungBold', marginTop: '2rem' }}>
        Predictive Maintenance
      </Typography>
      <Paper elevation={3} style={{ padding: '1rem', marginTop: '1rem' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cooler ID</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Last Service</TableCell>
                <TableCell>Predicted Next Service</TableCell>
                <TableCell>Risk Score</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {predictions.map((prediction) => (
                <TableRow key={prediction.coolerId}>
                  <TableCell>{prediction.coolerId}</TableCell>
                  <TableCell>{prediction.location || 'N/A'}</TableCell>
                  <TableCell>{prediction.model || 'N/A'}</TableCell>
                  <TableCell>{prediction.lastServiceDate ? new Date(prediction.lastServiceDate).toLocaleDateString() : 'N/A'}</TableCell>
                  <TableCell>{prediction.predictedNextService ? new Date(prediction.predictedNextService).toLocaleDateString() : 'N/A'}</TableCell>
                  <TableCell>
                    {prediction.riskScore != null ? (
                      <Tooltip title={`Based on ${prediction.serviceCount || 0} past services`} arrow>
                        <div>
                          <Typography variant="body2">{safeNumberFormat(prediction.riskScore)}</Typography>
                          <LinearProgress
                            variant="determinate"
                            value={Math.min(prediction.riskScore * 10, 100)}
                            style={{height: 10, borderRadius: 5}}
                            color={getRiskColor(prediction.riskScore)}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Typography variant="body2">No risk score available</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleScheduleMaintenance(prediction.coolerId)}
                      disabled={!prediction.coolerId}
                    >
                      Schedule
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default PredictiveMaintenance;
