import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Container,
    Typography,
    Grid,
    Button,
    TextField,
    Box,
    Paper,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddProject = () => {
    const [projectData, setProjectData] = useState({
        name: '',
        client: '',
        description: '',
    });
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/projects', projectData);
            navigate('/projects');
        } catch (error) {
            console.error('Error adding project:', error);
        }
    };

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        color: 'rgb(27, 64, 167)',
                        mr: 2,
                        '&:hover': { backgroundColor: 'rgba(27, 64, 167, 0.1)' }
                    }}
                >
                    <ArrowBackIcon />
                </Button>
                <Typography variant="h4" sx={{ fontFamily: 'NeueEinstellungBold' }}>
                    New Project
                </Typography>
            </Box>

            <Paper
                elevation={0}
                sx={{
                    p: { xs: 2, sm: 3 },
                    borderRadius: '10px',
                    backgroundColor: '#fff'
                }}
            >
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                label="Project Name"
                                name="name"
                                value={projectData.name}
                                onChange={(e) => setProjectData({ ...projectData, name: e.target.value })}
                                fullWidth
                                required
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                    }
                                }}
                                InputProps={{
                                    sx: { fontFamily: 'NeueEinstellungNormal' }
                                }}
                                InputLabelProps={{
                                    sx: { fontFamily: 'NeueEinstellungBold' }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Client"
                                name="client"
                                value={projectData.client}
                                onChange={(e) => setProjectData({ ...projectData, client: e.target.value })}
                                fullWidth
                                required
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                    }
                                }}
                                InputProps={{
                                    sx: { fontFamily: 'NeueEinstellungNormal' }
                                }}
                                InputLabelProps={{
                                    sx: { fontFamily: 'NeueEinstellungBold' }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                name="description"
                                value={projectData.description}
                                onChange={(e) => setProjectData({ ...projectData, description: e.target.value })}
                                fullWidth
                                multiline
                                rows={4}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                    }
                                }}
                                InputProps={{
                                    sx: { fontFamily: 'NeueEinstellungNormal' }
                                }}
                                InputLabelProps={{
                                    sx: { fontFamily: 'NeueEinstellungBold' }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{
                                    mt: 2,
                                    backgroundColor: '#1B40A7',
                                    fontFamily: 'NeueEinstellungBold',
                                    borderRadius: '10px',
                                    height: '48px',
                                    boxShadow: 'none',
                                    '&:hover': { backgroundColor: '#152e78' }
                                }}
                            >
                                Create Project
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
};

export default AddProject;