import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssessmentIcon from '@mui/icons-material/Assessment';

// ProjectList Component
const ProjectList = () => {
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();
    const { user } = useAuth0();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/projects');
                setProjects(response.data);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, []);
    const isAdmin = () => {
        return user && user['http://test.richardsondesign.co/roles']?.includes('Admin');
    };

    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 2, sm: 4 }, mt: { xs: 1, sm: 2 } }}>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        color: 'rgb(27, 64, 167)',
                        mr: 2,
                        minWidth: { xs: '36px', sm: 'auto' }, // Smaller button width on mobile
                        '&:hover': { backgroundColor: 'rgba(27, 64, 167, 0.1)' }
                    }}
                >
                    <ArrowBackIcon />
                </Button>
                <Typography
                    variant="h4"
                    sx={{
                        fontFamily: 'NeueEinstellungBold',
                        fontSize: { xs: '1.5rem', sm: '2rem' }, // Adjust font size for mobile
                        flexGrow: 1
                    }}
                >
                    Projects
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/projects/new')}
                    sx={{
                        backgroundColor: '#1B40A7',
                        fontFamily: 'NeueEinstellungBold',
                        borderRadius: '10px',
                        boxShadow: 'none',
                        fontSize: { xs: '0.75rem', sm: '1rem' }, // Smaller font size for mobile
                        padding: { xs: '6px 12px', sm: '8px 16px' }, // Adjust padding for mobile
                        '&:hover': { backgroundColor: '#152e78' }
                    }}
                >
                    New Project
                </Button>
            </Box>

            <Grid container spacing={2}>
                {projects.map(project => (
                    <Grid item xs={12} sm={6} md={4} key={project.id}>
                        <Card
                            onClick={() => navigate(`/projects/${project.id}/installations`)}
                            sx={{
                                cursor: 'pointer',
                                borderRadius: '10px',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'translateY(-4px)'
                                }
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontFamily: 'NeueEinstellungBold',
                                        color: '#1B40A7',
                                        fontSize: { xs: '1rem', sm: '1.25rem' } // Adjust for mobile
                                    }}
                                >
                                    {project.name}
                                </Typography>
                                <Typography sx={{ fontFamily: 'NeueEinstellungNormal', fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                                    {project.client}
                                </Typography>
                                {project.description && (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            mt: 1,
                                            color: 'text.secondary',
                                            fontFamily: 'NeueEinstellungNormal',
                                            fontSize: { xs: '0.75rem', sm: '0.875rem' } // Smaller description font size on mobile
                                        }}
                                    >
                                        {project.description}
                                    </Typography>
                                )}
                                
                                <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/projects/${project.id}/installations`);
                                        }}
                                        sx={{
                                            backgroundColor: '#1B40A7',
                                            fontFamily: 'NeueEinstellungBold',
                                            '&:hover': { backgroundColor: '#152e78' }
                                        }}
                                    >
                                        Route and Install
                                    </Button>
                                    
                                    {isAdmin() && (
                                        <Button
                                            variant="contained"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/projects/${project.id}/report`);
                                            }}
                                            sx={{
                                                backgroundColor: '#1B40A7',
                                                fontFamily: 'NeueEinstellungBold',
                                                '&:hover': { backgroundColor: '#152e78' }
                                            }}
                                        >
                                            <AssessmentIcon />
                                        </Button>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default ProjectList;
