import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker, Polyline, InfoWindowF } from '@react-google-maps/api';
import { Button, Grid, Card, CardContent, Typography, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import NearMeIcon from '@mui/icons-material/NearMe';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import debounce from 'lodash/debounce';


const containerStyle = {
  width: '100%',
  height: '400px',
  borderRadius: '15px',
  position: 'relative' // Add this line
};

const darkTheme = [


    {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#5a70bf"
              }
          ]
      },
      {
          "featureType": "transit",
          "stylers": [
              {
                  "color": "#808080"
              },
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
              {
                  "visibility": "on"
              },
              {
                  "color": "#b3b3b3"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#ffffff"
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "visibility": "on"
              },
              {
                  "color": "#ffffff"
              },
              {
                  "weight": 1.8
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "geometry.stroke",
          "stylers": [
              {
                  "color": "#d7d7d7"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "visibility": "on"
              },
              {
                  "color": "#ebebeb"
              }
          ]
      },
      {
          "featureType": "administrative",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#a7a7a7"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#ffffff"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#ffffff"
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "visibility": "on"
              },
              {
                  "color": "#efefef"
              }
          ]
      },
      {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#696969"
              }
          ]
      },
      {
          "featureType": "administrative",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "visibility": "on"
              },
              {
                  "color": "#737373"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "geometry.stroke",
          "stylers": [
              {
                  "color": "#d6d6d6"
              }
          ]
      },
      {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {},
      {
          "featureType": "poi",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#dadada"
              }
          ]
      }


];
const libraries = ["geometry"];
const TechnicianDashboard = () => {
  const navigate = useNavigate();
  const [selectedCoolers, setSelectedCoolers] = useState(() => {
   // Load selected coolers from local storage
   const savedCoolers = localStorage.getItem('selectedCoolers');
   return savedCoolers ? new Set(JSON.parse(savedCoolers)) : new Set();
 });
  const [coolers, setCoolers] = useState([]);
  const [page, setPage] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Example breakpoint at 768px
  const [isLoading, setIsLoading] = useState(true);
  const [mapCenter, setMapCenter] = useState({ lat: 38.419250, lng: -82.445152 });
  const [zoom, setZoom] = useState(15);
  const [route, setRoute] = useState([]);
  const [showServicedCoolers, setShowServicedCoolers] = useState(false);
  const [limit, setLimit] = useState(50); // Default limit to 50
  const [zipCode, setZipCode] = useState('');
  const toggleServicedCoolersVisibility = () => {
  setShowServicedCoolers(!showServicedCoolers);
};

  const convertToMiles = (meters) => {
    return (meters / 1609.34).toFixed(2); // Converts meters to miles
  };
  const [currentPosition, setCurrentPosition] = useState(null); // State to hold the current position
  const mapRef = useRef(null);
  const [selectedCooler, setSelectedCooler] = useState(null);
  // State to keep track of coolers with PM events
  const [servicedCoolers, setServicedCoolers] = useState(new Set());
  const centerOnCurrentLocation = () => {
  if (currentPosition && mapRef.current) {
    setMapCenter(currentPosition);
    setZoom(11);
    setPage(1); // Reset page number
    setCoolers([]); // Clear existing coolers
    fetchCoolers(currentPosition.lat, currentPosition.lng, 1); // Fetch coolers for the current location
  }
};



  const fetchPmEvents = async (serialNumber, coolerId) => {
    try {
      const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/cooler-pm-events/${serialNumber}`);
      if (response.data && response.data.length > 0) {
        return coolerId;
      }
    } catch (error) {
      console.error('Error fetching PM events:', error);
    }
    return null;
  };
  useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768); // You can adjust this value if you need a different breakpoint
  };

  // Add event listener
  window.addEventListener('resize', handleResize);

  // Call the handler right away so state gets updated with initial window size
  handleResize();

  // Remove event listener on cleanup
  return () => window.removeEventListener('resize', handleResize);
}, []);

  useEffect(() => {
  // This useEffect is responsible for updating localStorage when selectedCoolers changes
  localStorage.setItem('selectedCoolers', JSON.stringify([...selectedCoolers]));
}, [selectedCoolers]);

useEffect(() => {
  console.log("Serviced Coolers:", servicedCoolers); // Debugging log
}, [servicedCoolers]);

useEffect(() => {
    const updateServicedCoolers = async () => {
      const servicedIds = await Promise.all(coolers.map(cooler =>
        fetchPmEvents(cooler.SerialNumber, cooler.RecordID)
      ));

      // Filter out null values and update state
      setServicedCoolers(new Set(servicedIds.filter(id => id !== null)));
    };

    if (coolers.length > 0) {
      updateServicedCoolers();
    }
  }, [coolers]);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const currentPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setMapCenter(currentPosition);
        setCurrentPosition(currentPosition); // Set the current position
        fetchCoolers(position.coords.latitude, position.coords.longitude, 1);
      },
      error => console.error("Error getting location", error),
      { enableHighAccuracy: true }
    );
  }, []);
  const handleAddToRoute = (coolerId) => {
    setSelectedCoolers((prevSelectedCoolers) => {
      const newSelection = new Set(prevSelectedCoolers);
      if (newSelection.has(coolerId)) {
        newSelection.delete(coolerId);
      } else {
        newSelection.add(coolerId);
      }
      return newSelection;
    });
  };
  const buildDirectionsUrl = (start, waypoints, apiKey) => {
        const baseUrl = '/maps/api/directions/json'; // Removed the domain
        const origin = `origin=${start.lat},${start.lng}`;
        const destination = `destination=${start.lat},${start.lng}`; // assuming the route is a round trip
        const waypointsString = waypoints.map(waypoint => `${waypoint.lat},${waypoint.lng}`).join('|');
        const waypointsParam = waypoints.length ? `waypoints=optimize:true|${waypointsString}` : '';
        return `${baseUrl}?${origin}&${destination}&${waypointsParam}&key=${apiKey}`;
    };
    const startService = (coolerId) => {
    navigate(`/service/${coolerId}`);
  };
    const calculateAndStartRoute = async () => {
    const selectedCoolersList = Array.from(selectedCoolers);
    /*if (!currentPosition || selectedCoolersList.length === 0) {
        console.log("No selected coolers or current position is unavailable.");
        return;
    }*/

    // Creating waypoints array and extracting the last waypoint as the destination
    const waypoints = [];
    let lastWaypoint = null;
    selectedCoolersList.forEach(coolerId => {
        const cooler = coolers.find(c => c.RecordID === coolerId);
        if (cooler) {
            waypoints.push(`${cooler.Latitude},${cooler.Longitude}`);
        }
    });

    if (waypoints.length > 0) {
        lastWaypoint = waypoints.pop(); // Remove and store the last waypoint for destination
    }

    try {
        const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/directions', {
            params: {
                origin: `${currentPosition.lat},${currentPosition.lng}`,
                destination: lastWaypoint || `${currentPosition.lat},${currentPosition.lng}`,
                waypoints: waypoints.join('|'),
                apiKey: "AIzaSyCmD5wWJPbP6wQuZcZxCv2jdCXPDgLRLl4" // Replace with your actual API key
            }
        });

        const directionsData = response.data.routes[0];
        const overviewPath = directionsData.overview_polyline.points;
        const path = window.google.maps.geometry.encoding.decodePath(overviewPath);
        setRoute([{ path: path }]);

        // Adjust map bounds to the route
        const bounds = new window.google.maps.LatLngBounds();
        path.forEach((point) => {
            bounds.extend(point);
        });

        // Assuming you have a ref to the GoogleMap component:
        if (mapRef.current) {
            mapRef.current.fitBounds(bounds);
        }

    } catch (error) {
        console.error('Error calculating route:', error);
    }
};

const fetchCoolers = async (latitude, longitude, pageNumber) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/coolers?lat=${latitude}&lng=${longitude}&page=${pageNumber}&limit=${limit}`); // Add limit to query params
    const fetchedCoolers = response.data;

    // Update the state with new coolers, ensuring no duplicates
    setCoolers(prevCoolers => {
      const allCoolers = [...prevCoolers, ...fetchedCoolers];
      const uniqueCoolers = Array.from(new Map(allCoolers.map(cooler => [cooler['RecordID'], cooler])).values());
      return uniqueCoolers;
    });
    setIsLoading(false);
  } catch (error) {
    console.error('Error fetching coolers:', error);
    setIsLoading(false);
  }
};

const handleLimitChange = (event) => {
  setLimit(parseInt(event.target.value, 10));
  setPage(1); // Reset page when limit changes
  fetchCoolers(mapCenter.lat, mapCenter.lng, 1); // Fetch new data
};

const handleZipCodeSubmit = async () => {
  try {
    const response = await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/geocode-zip', { zipCode });
    const { lat, lng } = response.data;
    setMapCenter({ lat, lng });
    setZoom(11); // Adjust the zoom level as necessary
    setPage(1); // Reset page number
    setCoolers([]); // Clear existing coolers
    fetchCoolers(lat, lng, 1); // Fetch coolers for the new location
  } catch (error) {
    console.error('Error fetching geocoded location:', error);
  }
};
const centerMapOnCooler = (cooler) => {
setMapCenter({ lat: parseFloat(cooler.Latitude), lng: parseFloat(cooler.Longitude) });
setZoom(10);
setSelectedCooler(cooler); // Set the selected cooler
};
  const createGoogleMapsUrl = () => {
  const selectedCoolersList = Array.from(selectedCoolers);
  /*if (selectedCoolersList.length === 0) {
    console.log("No selected coolers or current position is unavailable.");
    return "#";
  }*/

  const waypoints = selectedCoolersList.map(coolerId => {
    const cooler = coolers.find(c => c.RecordID === coolerId);
    if (cooler) {
      return `${cooler.Latitude},${cooler.Longitude}`;
    }
    return null;
  }).filter(Boolean); // Remove any nulls from the array

  // We are not using the currentPosition state here, instead we let Google Maps use the user's current location automatically by not specifying the origin parameter.
  const destination = waypoints.pop(); // Use the last cooler as the destination

  const waypointsParam = waypoints.length > 0 ? `&waypoints=${encodeURIComponent(waypoints.join('|'))}` : '';

  return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(destination)}${waypointsParam}&travelmode=driving`;
};

  const handleShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchCoolers(mapCenter.lat, mapCenter.lng, nextPage);
  };
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
  if (mapRef.current && mapRef.current.containerElement) {
    if (isFullscreen) {
      // Exiting fullscreen, so reset styles
      Object.assign(mapRef.current.containerElement.style, containerStyle);
    } else {
      // Entering fullscreen, apply fullscreen styles
      Object.assign(mapRef.current.containerElement.style, {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        borderRadius: '0',
        zIndex: 1000
      });
    }
  }
  setIsFullscreen(!isFullscreen);
};

const mapContainerWrapperStyle = {
   position: 'relative', // This is important to position children absolutely within
   width: '100%', // Adjust according to your layout
   height: '400px', // Or whatever height you want for the map container
 };
  const fullscreenStyle = isFullscreen
    ? { width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 1000 }
    : containerStyle;

  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

  return (
    <div style={{ padding: '1rem', backgroundColor: '#F5F5F5', minHeight: '100vh' }}>
    
      {/* Map container wrapper */}
      <div style={mapContainerWrapperStyle}>
      <LoadScript
      googleMapsApiKey="AIzaSyCmD5wWJPbP6wQuZcZxCv2jdCXPDgLRLl4"
      libraries={['places', 'geometry', 'drawing']}
      onLoad={() => setIsGoogleLoaded(true)}
      >
      <GoogleMap
  onLoad={map => {
    mapRef.current = map;
    setIsGoogleLoaded(true);
  }}
  mapContainerStyle={fullscreenStyle}
  center={mapCenter}
  zoom={zoom}
  options={{
    styles: darkTheme,
    mapTypeControl: false,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false
  }}
>
          {/* Render a marker for the current position with a blue dot icon */}
          {currentPosition && isGoogleLoaded && window.google && (
            <Marker
              position={currentPosition}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 7,
                fillColor: "#4285F4",
                fillOpacity: 1,
                strokeWeight: 2,
                strokeColor: "white"
              }}
            />
          )}
          {coolers.map(cooler => {
            // Skip if Google Maps isn't loaded yet
            if (!window.google || !isGoogleLoaded) return null;

            // Hide the marker if the cooler is serviced and showServicedCoolers is false
            if (servicedCoolers.has(cooler.RecordID) && !showServicedCoolers) {
              return null;
            }

            return (
              <Marker
                key={cooler.RecordID}
                position={{
                  lat: parseFloat(cooler.Latitude),
                  lng: parseFloat(cooler.Longitude)
                }}
                icon={window.google ? {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 4.5,
                  fillColor: servicedCoolers.has(cooler.RecordID) ? "green" : "red",
                  fillOpacity: 1,
                  strokeWeight: 1,
                  strokeColor: "white",
                } : null}
                onClick={() => {
                  if (selectedCooler && selectedCooler.RecordID === cooler.RecordID) {
                    setSelectedCooler(null);
                  } else {
                    setSelectedCooler(cooler);
                  }
                }}
              />
            );
          })}


        {route.length > 0 && (
        <Polyline
          path={route[0].path}
          options={{
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
          }}
        />
      )}
      {selectedCooler && (
  <InfoWindowF
    position={{
      lat: parseFloat(selectedCooler.Latitude),
      lng: parseFloat(selectedCooler.Longitude)
    }}
    onCloseClick={() => setSelectedCooler(null)}
  >
    <div>
      <h3>{selectedCooler.Location}</h3>
      <p>{selectedCooler.ShippingCity}, {selectedCooler.ShippingState}</p>
      {/* Add more cooler details here */}
      <Button
        variant="contained"
        style={{
          backgroundColor: selectedCoolers.has(selectedCooler.RecordID) ? "#d16656" : "#1B40A7",
          color: 'white',
          boxShadow: 'none',
          marginTop: '8px'
        }}
        onClick={(e) => {
          e.stopPropagation(); // Prevents the InfoWindowF click event
          handleAddToRoute(selectedCooler.RecordID);
        }}
      >
        {selectedCoolers.has(selectedCooler.RecordID) ? "Remove" : "Add"}
      </Button>
    </div>
  </InfoWindowF>
)}
      </GoogleMap>
      <Button
  onClick={toggleFullscreen}
  style={{
    position: isFullscreen ? 'fixed' : 'absolute', // fixed when fullscreen, absolute otherwise
    top: isFullscreen ? '10px' : '10px',  // 10px from the top in both states
    left: isFullscreen ? '10px' : '10px', // 10px from the left in both states
    zIndex: 1001, // Above all other content
    backgroundColor: 'white', // Black background
    color: '#1B40A7', // White icon

    borderRadius: '10px', // Rounded corners
  }}
>
  {isFullscreen ? <CloseFullscreenOutlinedIcon /> : <OpenInFullOutlinedIcon />}
</Button>
<Button
          onClick={centerOnCurrentLocation}
          style={{
            position: isFullscreen ? 'fixed' : 'absolute',
            top: 10,
            right: 10,
            zIndex: 1001,
            backgroundColor: 'white',
            color: '#1B40A7',

            borderRadius: '10px',
          }}
        >
          <NearMeIcon />
        </Button>
    </LoadScript>
</div>
    {/* Start Route button */}
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: '2rem' }}>


  {/* Route Button */}
  <Button
    variant="contained"
    onClick={calculateAndStartRoute}
    disabled={selectedCoolers.size === 0}
    sx={{
      mr: { xs: 0, sm: 2 },
      mb: { xs: 1, sm: 0 },
      backgroundColor: selectedCoolers.size === 0 ? '#e0e0e0' : '#1B40A7',
      color: selectedCoolers.size === 0 ? '#757575' : 'white',
      boxShadow: 'none',
      borderRadius: '10px',
      width: { xs: '100%', sm: '100px' },
      fontFamily: 'NeueEinstellungBold',
      '&:hover': {
        backgroundColor: selectedCoolers.size === 0 ? '#e0e0e0' : '#152e78',
        boxShadow: 'none'
      }
    }}
  >
    Route
  </Button>

  {/* Open in Google Maps Button */}
  <Button
    variant="contained"
    href={createGoogleMapsUrl()}
    target="_blank"
    disabled={selectedCoolers.size === 0}
    sx={{
      mr: { xs: 0, sm: 2 },
      mb: { xs: 1, sm: 0 },
      backgroundColor: selectedCoolers.size === 0 ? '#e0e0e0' : '#1B40A7',
      color: selectedCoolers.size === 0 ? '#757575' : 'white',
      boxShadow: 'none',
      borderRadius: '10px',
      width: { xs: '100%', sm: '200px' },
      fontFamily: 'NeueEinstellungBold',
      '&:hover': {
        backgroundColor: selectedCoolers.size === 0 ? '#e0e0e0' : '#152e78',
        boxShadow: 'none'
      }
    }}
  >
    Open in Google Maps
  </Button>

  {/* Conditional wrapper div to force the next button to wrap on mobile */}
  <div style={{ width: '100%', display: isMobile ? 'block' : 'none' }}></div>

  {/* Hide/Show Serviced Coolers Button */}
  <Button
        variant="contained"
        onClick={toggleServicedCoolersVisibility}
        sx={{
          marginRight: isMobile ? 0 : 1,
          marginBottom: isMobile ? 1 : 0,
          borderRadius: '10px',
          boxShadow: 'none',
          backgroundColor: '#1B40A7',
          color: 'white',
          '&:hover': { boxShadow: 'none', backgroundColor: '#1B40A7' },
          width: isMobile ? '100%' : 'auto',
        }}
      >
        {showServicedCoolers ? 'Hide Serviced Coolers' : 'Show Serviced Coolers'}
      </Button>

      {/* Show More Button */}
      <Button
        variant="contained"
        onClick={handleShowMore}
        sx={{
          marginRight: isMobile ? 0 : 1,
          marginBottom: isMobile ? 2 : 0,
          borderRadius: '10px',
          boxShadow: 'none',
          backgroundColor: '#1B40A7',
          color: 'white',
          '&:hover': {boxShadow: 'none', backgroundColor: '#1B40A7' },
          width: isMobile ? '100%' : 'auto',
        }}
      >
        Show More
      </Button>


      {/* Zip Code Text Field */}
      <TextField
        label="Zip Code"
        variant="outlined"
        value={zipCode}
        onChange={(e) => setZipCode(e.target.value)}
        sx={{
          marginLeft: isMobile ? 0 : 2,
          marginBottom: isMobile ? 1 : 0,
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            boxShadow: 'none',
            backgroundColor: 'white',
            '&:hover': {boxShadow: 'none', backgroundColor: 'white' },
          },
          width: isMobile ? '100%' : 'auto',
        }}
      />

      {/* Search Button */}
      <Button
        variant="contained"
        onClick={handleZipCodeSubmit}
        sx={{
          marginLeft: isMobile ? 0 : 2,
          borderRadius: '10px',
          boxShadow: 'none',
          backgroundColor: '#1B40A7',
          color: 'white',
          '&:hover': {boxShadow: 'none', backgroundColor: '#1B40A7' },
          width: isMobile ? '100%' : 'auto',
        }}
      >
        Search
      </Button>
    </div>
    {/* Cooler cards grid */}
    <Grid container spacing={2} style={{ marginTop: '1rem' }}>
  {isLoading ? (
    Array.from(new Array(6)).map((_, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Skeleton variant="rounded" width="100%" height={150} />
      </Grid>
    ))
  ) : (
    coolers.map(cooler => {
      // Hide the card if the cooler is serviced and showServicedCoolers is false
      if (servicedCoolers.has(cooler.RecordID) && !showServicedCoolers) {
        return null;
      }

      return (
      <Grid item xs={12} sm={6} md={4} key={cooler.RecordID}>
        <Card
          sx={{
            cursor: 'pointer', 
            boxShadow: 'none', 
            borderRadius: '16px',
            border: '1px solid rgba(27, 64, 167, 0.1)',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: '0 4px 15px rgba(27, 64, 167, 0.1)',
            }
          }}
          onClick={() => centerMapOnCooler(cooler)}
        >
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', mb: 1 }}>
                  {cooler.Location}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                  Model: {cooler.Model}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                  Serial: {cooler.SerialNumber}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', mb: 0.5 }}>
                  Location: {cooler.ShippingCity}, {cooler.ShippingState}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Distance: {convertToMiles(cooler.distance)} miles
                </Typography>
              </Box>
              
              <Box sx={{ mt: 'auto', display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: selectedCoolers.has(cooler.RecordID) ? "#d16656" : "#1B40A7",
                    color: 'white',
                    boxShadow: 'none',
                    borderRadius: '10px',
                    fontFamily: 'NeueEinstellungBold',
                    '&:hover': {
                      backgroundColor: selectedCoolers.has(cooler.RecordID) ? "#b85745" : "#152e78",
                      boxShadow: 'none'
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToRoute(cooler.RecordID);
                  }}
                >
                  {selectedCoolers.has(cooler.RecordID) ? "Remove" : "Add"}
                </Button>
                
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: servicedCoolers.has(cooler.RecordID) ? "#00C49F" : "#1B40A7",
                    color: 'white',
                    boxShadow: 'none',
                    borderRadius: '10px',
                    fontFamily: 'NeueEinstellungBold',
                    '&:hover': {
                      backgroundColor: servicedCoolers.has(cooler.RecordID) ? "#00a085" : "#152e78",
                      boxShadow: 'none'
                    }
                  }}
                  onClick={() => startService(cooler.RecordID)}
                  disabled={servicedCoolers.has(cooler.RecordID)}
                >
                  {servicedCoolers.has(cooler.RecordID) ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CheckIcon /> Serviced
                    </Box>
                  ) : "Start Service"}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
);
    })
  )}
  <Grid item xs={12} sm={6} md={4}>
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: '100%',
        minHeight: '250px',
        borderRadius: '16px',
        border: '1px solid rgba(27, 64, 167, 0.1)',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 4px 15px rgba(27, 64, 167, 0.1)',
        }
      }}
      onClick={handleShowMore}
    >
      <CardContent sx={{ textAlign: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
          <Typography 
            variant="h6" 
            sx={{ 
              color: '#1B40A7',
              fontFamily: 'NeueEinstellungBold'
            }}
          >
            Show More
          </Typography>
          <AddIcon sx={{ color: '#1B40A7', fontSize: '2rem' }} />
        </Box>
      </CardContent>
    </Card>
  </Grid>
</Grid>


  </div>
);
};
export default TechnicianDashboard;
