import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  useMediaQuery,
  IconButton
} from '@mui/material';
import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  Engineering as EngineeringIcon,
  Logout as LogoutIcon,
  UploadFile as UploadFileIcon,
  Assessment as AssessmentIcon,
  BubbleChart as BubbleChartIcon,
  Timeline as TimelineIcon,
  Build as BuildIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon
} from '@mui/icons-material';

// Styled Components
const StyledDrawer = styled(Drawer)(({ theme, isMobile, open }) => ({
  '& .MuiDrawer-paper': {
    marginTop: 0,
    boxShadow: isMobile ? '2px 0 8px rgba(0,0,0,0.15)' : 'none',
    borderRadius: 0,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#ffffff',
    width: isMobile ? 240 : (open ? 240 : 76),
    height: '100%',
    position: 'fixed',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    zIndex: theme.zIndex.drawer
  }
}));

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
  position: 'relative',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
}));

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  margin: '4px 8px',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: 'rgba(27, 64, 167, 0.1)',
  },
  ...(active && {
    backgroundColor: 'rgba(27, 64, 167, 0.1)',
    '& .MuiListItemIcon-root': {
      color: '#1B40A7',
    },
    '& .MuiListItemText-primary': {
      color: '#1B40A7',
      fontFamily: 'NeueEinstellungBold',
    },
  }),
}));

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 40,
  color: '#666666',
});

const StyledListItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontFamily: 'NeueEinstellungNormal',
    fontSize: '0.95rem',
  },
});

// Add new styled component for menu items in collapsed state
const CollapsedListItem = styled(ListItem)(({ active }) => ({
  padding: '12px 0',
  justifyContent: 'center',
  margin: '4px 8px',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: 'rgba(27, 64, 167, 0.1)',
  },
  ...(active && {
    backgroundColor: 'rgba(27, 64, 167, 0.1)',
    '& .MuiListItemIcon-root': {
      color: '#1B40A7',
    }
  }),
}));

const StyledIconButton = styled(IconButton)(({ open }) => ({
  color: '#1B40A7',
  backgroundColor: 'rgba(27, 64, 167, 0.08)',
  borderRadius: '8px',
  padding: '8px',
  minWidth: '40px',
  minHeight: '40px',
  '&:hover': {
    backgroundColor: 'rgba(27, 64, 167, 0.15)',
  },
  transition: 'all 0.2s ease-in-out',
  transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
}));

const Sidebar = ({ open, onClose, onToggle, isMobile }) => {
  const { logout, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [showTechReport, setShowTechReport] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchUserRole = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch('https://freshpet-pm-9087312eb841.herokuapp.com/api/get-user-role', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ userId: user.sub })
          });

          if (response.ok) {
            const data = await response.json();
            setShowTechReport(data.roles.includes('Admin'));
          }
        } catch (error) {
          console.error("Error fetching user role", error);
        }
      }
    };

    fetchUserRole();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    onClose();
  };

  const menuItems = [
    { path: '/', icon: <HomeIcon />, text: 'Home' },
    { path: '/technician', icon: <EngineeringIcon />, text: 'Route and Service' },
    { path: '/datadashboard', icon: <BubbleChartIcon />, text: 'Dashboard' },
    { path: '/projects', icon: <TimelineIcon />, text: 'Projects' },
    { path: '/installations', icon: <BuildIcon />, text: 'Installations' },
  ];

  const adminMenuItems = [
    { path: '/settings', icon: <SettingsIcon />, text: 'Settings' },
    { path: '/asset-scanner', icon: <AssessmentIcon />, text: 'Asset Scanner' },
    { path: '/tech-report', icon: <AssessmentIcon />, text: 'Tech Report' },
    { path: '/upload-coolers', icon: <UploadFileIcon />, text: 'Upload Coolers' },
  ];

  return (
    <StyledDrawer
      anchor="left"
      open={open}
      onClose={onClose}
      variant={isMobile ? 'temporary' : 'permanent'}
      isMobile={isMobile}
    >
      <LogoContainer sx={{ marginRight: '10px' }}>
        
        {!isMobile && (
          <StyledIconButton
            onClick={onToggle}
            open={open}
          >
            <ChevronLeftIcon sx={{ fontSize: '28px', }} />
          </StyledIconButton>
        )}
      </LogoContainer>
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {menuItems.map((item) => (
            <StyledListItem
              button
              key={item.path}
              component={Link}
              to={item.path}
              onClick={isMobile ? onClose : undefined}
              active={location.pathname === item.path ? 1 : 0}
            >
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              {(open || isMobile) && <StyledListItemText primary={item.text} />}
            </StyledListItem>
          ))}

          {showTechReport && (
            <>
              <Divider sx={{ my: 1, mx: 2 }} />
              {adminMenuItems.map((item) => (
                <StyledListItem
                  button
                  key={item.path}
                  component={Link}
                  to={item.path}
                  onClick={isMobile ? onClose : undefined}
                  active={location.pathname === item.path ? 1 : 0}
                >
                  <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                  {open && <StyledListItemText primary={item.text} />}
                </StyledListItem>
              ))}
            </>
          )}

          <Divider sx={{ my: 1, mx: 2 }} />
          {open ? (
            <StyledListItem
              button
              onClick={handleLogout}
            >
              <StyledListItemIcon>
                <LogoutIcon />
              </StyledListItemIcon>
              <StyledListItemText primary="Logout" />
            </StyledListItem>
          ) : (
            <CollapsedListItem
              button
              onClick={handleLogout}
            >
              <StyledListItemIcon>
                <LogoutIcon />
              </StyledListItemIcon>
            </CollapsedListItem>
          )}
        </List>
      </Box>
    </StyledDrawer>
  );
};

export default Sidebar;