import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import {
  Container,
  Card,
  CardContent,
  Typography,
  Collapse,
  IconButton,
  List,
  ListItem,
  Button,
  TextField,
  ListItemButton
} from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import icon for the back button
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const TechPmReport = () => {
  const [reportData, setReportData] = useState({});
  const [openCard, setOpenCard] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-pm-report')
      .then(response => {
        const groupedByDate = {};

        Object.entries(response.data).forEach(([dateTime, entries]) => {
          const dateOnly = new Date(dateTime).toLocaleDateString();

          if (!groupedByDate[dateOnly]) {
            groupedByDate[dateOnly] = {};
          }

          entries.forEach(entry => {
            const { technician, coolers } = entry;
            if (!groupedByDate[dateOnly][technician]) {
              groupedByDate[dateOnly][technician] = [];
            }
            groupedByDate[dateOnly][technician].push(...coolers);
          });
        });

        setReportData(groupedByDate);
      })
      .catch(err => console.error('Error fetching tech PM report', err));
  }, []);
  const exportToExcel = (data, filename) => {
    const worksheetData = data.map(technicianData => ({
      Technician: technicianData.technician,
      'Coolers Serviced': technicianData.coolersServiced.join(', '),
      'Number of Coolers': technicianData.coolersServiced.length
    }));

  const ws = XLSX.utils.json_to_sheet(worksheetData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Report");
  XLSX.writeFile(wb, filename);
};
  const exportDailyData = (date) => {
    const dailyTechData = reportData[date];

    // Structure the data:
    const worksheetData = Object.entries(dailyTechData).map(([technicianName, coolers]) => {
      return {
        Technician: technicianName,
        'Coolers Serviced': coolers.join(', ') // Combine cooler serials
      }
    });

    // Create a new Workbook
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Daily Data');

    // Generate file name
    const filename = `Tech_Report_${date.replace(/\//g, '-')}.xlsx`;

    // Download
    XLSX.writeFile(workbook, filename);
  };
  const exportCustomRangeData = () => {
    if (!startDate || !endDate) {
      alert('Please select both start and end dates.');
      return;
    }

    const data = getCustomRangeData(new Date(startDate), new Date(endDate));
    const flattenedData = Object.entries(data).flatMap(([date, dailyData]) =>
      Object.entries(dailyData).map(([technicianName, coolers]) => ({
        date,
        Technician: technicianName,
        'Coolers Serviced': coolers.join(', '),
        'Number of Coolers': coolers.length,
      }))
    );

    exportToExcel(flattenedData, `Tech_Report_Custom_${startDate.toISOString().split('T')[0]}_to_${endDate.toISOString().split('T')[0]}.xlsx`);
  };

  const getMonthToDateData = () => {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const techniciansData = {}; // Dictionary to aggregate data by technician

  for (const [dateStr, dailyData] of Object.entries(reportData)) {
    const date = new Date(dateStr);
    if (date >= firstDayOfMonth && date <= today) {
      for (const [technicianName, coolers] of Object.entries(dailyData)) {
        if (!techniciansData[technicianName]) {
          techniciansData[technicianName] = {
            technician: technicianName,
            coolersServiced: []
          };
        }
        techniciansData[technicianName].coolersServiced.push(...coolers);
      }
    }
  }

  return Object.values(techniciansData); // Convert to an array for export
};
const getYearToDateData = () => {
const today = new Date();
const firstDayOfYear = new Date(today.getFullYear(), 0, 1);

const techniciansData = {}; // Dictionary to aggregate data by technician

for (const [dateStr, dailyData] of Object.entries(reportData)) {
  const date = new Date(dateStr);
  if (date >= firstDayOfYear && date <= today) {
    for (const [technicianName, coolers] of Object.entries(dailyData)) {
      if (!techniciansData[technicianName]) {
        techniciansData[technicianName] = {
          technician: technicianName,
          coolersServiced: []
        };
      }
      techniciansData[technicianName].coolersServiced.push(...coolers);
    }
  }
}

return Object.values(techniciansData); // Convert to an array for export
};

const getCustomRangeData = (startDate, endDate) => {
const start = new Date(startDate);
const end = new Date(endDate);

const techniciansData = {}; // Dictionary to aggregate data by technician

for (const [dateStr, dailyData] of Object.entries(reportData)) {
  const date = new Date(dateStr);
  if (date >= start && date <= end) {
    for (const [technicianName, coolers] of Object.entries(dailyData)) {
      if (!techniciansData[technicianName]) {
        techniciansData[technicianName] = {
          technician: technicianName,
          coolersServiced: []
        };
      }
      techniciansData[technicianName].coolersServiced.push(...coolers);
    }
  }
}

return Object.values(techniciansData); // Convert to an array for export
};
  const handleCollapseClick = (date) => {
    setOpenCard(openCard === date ? null : date);
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
    <Button
  startIcon={<ArrowBackIcon />}
  onClick={() => navigate(-1)}
  sx={{
    marginBottom: '1rem',
    color: 'rgb(27, 64, 167)',
    fontFamily: 'NeueEinstellungBold', // Assuming you want to match the heading font
    '&:hover': {
      color: 'ffffff',
      backgroundColor: 'rgb(200, 200, 200)' // A slightly darker shade on hover for feedback
    }
  }}
>
  Back
</Button>
      <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', marginBottom: '1rem' }}>
        Tech Report
      </Typography>
      <Card sx={{ boxShadow: "0 2px 8px rgba(0, 0, 0, 0)", borderRadius: "10px", mt: 2 }}>
        <CardContent>
          <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', marginBottom: '1rem' }}>
            Export To Excel
          </Typography>

          <div style={{ display: 'flex', justifyContent: 'left', gap: '20px' }}>
    <Button
      variant="contained"
      sx={{
        marginTop: '1rem',
        borderRadius: '8px',
        fontSize: '12px',
        backgroundColor: 'rgb(27, 64, 167)', // Example color, customize as needed
        fontFamily: 'NeueEinstellungBold', // Match your styling
        '&:hover': {
          backgroundColor: 'rgb(2, 39, 142)' // Example hover effect
        }
      }}
      onClick={() => {
        const data = getMonthToDateData();
        exportToExcel(data, 'Tech_Report_MonthToDate.xlsx'); // Customize filename
      }}
    >
      Month To Date
    </Button>

    <Button
      variant="contained"
      sx={{
        marginTop: '1rem',
        borderRadius: '8px',
        fontSize: '12px',
        backgroundColor: 'rgb(27, 64, 167)', // Example color, customize as needed
        fontFamily: 'NeueEinstellungBold', // Match your styling
        '&:hover': {
          backgroundColor: 'rgb(2, 39, 142)' // Example hover effect
        }
      }}
      onClick={() => {
        const data = getYearToDateData();
        exportToExcel(data, 'Tech_Report_YearToDate.xlsx'); // Customize filename
      }}
    >
      Year To Date
    </Button>
  </div>


        </CardContent>
      </Card>
      {Object.entries(reportData)
        .sort(([date1], [date2]) => new Date(date2) - new Date(date1)) // Sort dates in descending order
        .map(([date, techniciansData], dateIndex) => (
          <Card key={dateIndex} sx={{ mt: 2, boxShadow: '0 2px 8px rgba(0, 0, 0, 0)', borderRadius: '10px' }}>
            <CardContent>

              <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleCollapseClick(date)}>
                {date}
                <IconButton size="small">
                  <KeyboardArrowDownIcon sx={{ transform: openCard === date ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} />
                </IconButton>
                {/* Calculate the total coolers serviced */}
                <Typography variant="body2" sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                  Coolers Serviced: {
                    Object.values(techniciansData).flat().length
                  }
                </Typography>
              </Typography>
              <Collapse in={openCard === date} timeout="auto" unmountOnExit>
                {Object.entries(techniciansData).map(([technicianName, coolers], techIndex) => (
                  <div key={techIndex} style={{ marginTop: '10px', marginLeft: '20px' }}>
                  <Typography variant="body1" sx={{ fontFamily: 'NeueEinstellungNormal', fontWeight: 'bold' }}>
                    {technicianName.trim()}
                  </Typography>
                  <Typography variant="body2" sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                    Coolers Serviced: {coolers.length}
                  </Typography>
                  <List>
{coolers.map((cooler, coolerIndex) => (
  <ListItem key={coolerIndex} disablePadding>
    <ListItemButton onClick={() => navigate(`/cooler-details/${cooler.serial}`)}>
      {cooler.serial} - {cooler.time}
      {cooler.needsAttention && <FeedbackIcon color="error" sx={{ ml: 1 }} />}
      <KeyboardArrowRightIcon/>
    </ListItemButton>
  </ListItem>
))}
</List>
                  </div>
                ))}
                <Button
    variant="contained"
    sx={{
      marginTop: '1rem',
      borderRadius: 'px',
      backgroundColor: 'rgb(27, 64, 167)', // Example color, customize as needed
      fontFamily: 'NeueEinstellungBold', // Match your styling
      '&:hover': {
        backgroundColor: 'rgb(2, 39, 142)' // Example hover effect
      }
    }}
    onClick={() => exportDailyData(date)} // Pass the date value
  >
    Export Excel
  </Button>
              </Collapse>

            </CardContent>
          </Card>
      ))}
    </Container>
  );
};

export default TechPmReport;
