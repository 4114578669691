import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Grid,
    Paper,
    Box,
    CircularProgress
} from '@mui/material';
import BackButton from '../components/BackButton';
import { motion } from 'framer-motion';
import PersonIcon from '@mui/icons-material/Person';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const InstallationBreakdown = () => {
    const [technicians, setTechnicians] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await fetch('https://freshpet-pm-9087312eb841.herokuapp.com/api/completed_installations');
            const data = await response.json();

            // Group installations by technician
            const techStats = data.reduce((acc, installation) => {
                const tech = installation.installed_by || 'Unknown';
                if (!acc[tech]) {
                    acc[tech] = {
                        name: tech,
                        count: 0,
                        recentInstalls: [],
                        states: new Set()
                    };
                }
                acc[tech].count++;
                acc[tech].states.add(installation.state);
                acc[tech].recentInstalls.push({
                    date: new Date(installation.installation_date),
                    store: installation.store_number
                });
                return acc;
            }, {});

            // Convert to array and sort by count
            const techArray = Object.values(techStats)
                .map(tech => ({
                    ...tech,
                    states: Array.from(tech.states),
                    recentInstalls: tech.recentInstalls
                        .sort((a, b) => b.date - a.date)
                        .slice(0, 3)
                }))
                .sort((a, b) => b.count - a.count);

            setTechnicians(techArray);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const welcomeStyle = {
        background: 'linear-gradient(90deg, #1B40A7, #2b5be0)',
        padding: '1.5rem',
        borderRadius: '16px',
        marginBottom: '2rem',
        boxShadow: '0 4px 15px rgba(27, 64, 167, 0.2)',
    };

    const cardStyle = {
        padding: '1.5rem',
        borderRadius: '16px',
        height: '100%',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        cursor: 'pointer',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 6px 20px rgba(27, 64, 167, 0.15)',
        },
    };

    if (loading) {
        return (
            <Container maxWidth="lg" sx={{ py: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                    <CircularProgress sx={{ color: '#1B40A7' }} />
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <BackButton />

            <Paper elevation={0} style={welcomeStyle}>
                <Typography
                    variant="h5"
                    sx={{
                        color: '#fff',
                        fontFamily: "Akira",
                        marginBottom: '0.5rem'
                    }}
                >
                    Installation Breakdown
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{
                        color: 'rgba(255, 255, 255, 0.8)',
                        fontFamily: 'NeueEinstellungBold'
                    }}
                >
                    Total Technicians: {technicians.length}
                </Typography>
            </Paper>

            <Grid container spacing={3}>
                {technicians.map((tech, index) => (
                    <Grid item xs={12} md={4} key={tech.name}>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: index * 0.1 }}
                        >
                            <Paper 
                                elevation={0} 
                                sx={cardStyle}
                                onClick={() => navigate(`/technician-installations/${encodeURIComponent(tech.name)}`)}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <PersonIcon sx={{ fontSize: 40, color: '#1B40A7', mr: 2 }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold' }}>
                                        {tech.name}
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <BuildIcon sx={{ color: '#1B40A7', mr: 1 }} />
                                    <Typography variant="h4" sx={{ fontFamily: 'NeueEinstellungBold', color: '#1B40A7' }}>
                                        {tech.count}
                                    </Typography>
                                    <Typography variant="body1" sx={{ ml: 1, color: 'text.secondary' }}>
                                        installations
                                    </Typography>
                                </Box>

                                <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                                    States: {tech.states.join(', ')}
                                </Typography>

                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle2" sx={{ fontFamily: 'NeueEinstellungBold', mb: 1 }}>
                                        Recent Installations:
                                    </Typography>
                                    {tech.recentInstalls.map((install, i) => (
                                        <Box key={i} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                            <CheckCircleIcon sx={{ fontSize: 16, color: '#00C49F', mr: 1 }} />
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                Store #{install.store} - {install.date.toLocaleDateString()}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Paper>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default InstallationBreakdown;