import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, List, ListItem, ListItemText, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PMOwners = () => {
  const [stateCityGroups, setStateCityGroups] = useState({});
  const [openState, setOpenState] = useState({});
  const [openCity, setOpenCity] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/newcoolers')
      .then(response => {
        const groupedByStateCity = response.data.reduce((acc, cooler) => {
          const state = cooler.ShippingState;
          const city = cooler.ShippingCity;
          if (!acc[state]) {
            acc[state] = {};
          }
          if (!acc[state][city]) {
            acc[state][city] = [];
          }
          acc[state][city].push(cooler);
          return acc;
        }, {});

        setStateCityGroups(groupedByStateCity);
      })
      .catch(err => console.error('Error fetching coolers', err));
  }, []);

  const handleClickState = (state) => {
    setOpenState({ ...openState, [state]: !openState[state] });
  };

  const handleClickCity = (state, city) => {
    setOpenCity({ ...openCity, [`${state}-${city}`]: !openCity[`${state}-${city}`] });
  };

  const handleClickCooler = (coolerId) => {
    navigate(`/cooler-details/${coolerId}`);
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom style={{ fontFamily: 'NeueEinstellungBold' }}>
        PM Owners Management
      </Typography>
      <List component="nav">
        {Object.keys(stateCityGroups).map((state) => (
          <React.Fragment key={state}>
            <ListItem button onClick={() => handleClickState(state)}>
              <ListItemText primary={state} />
            </ListItem>
            <Collapse in={openState[state]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {Object.keys(stateCityGroups[state]).map((city) => (
                  <React.Fragment key={`${state}-${city}`}>
                    <ListItem button style={{ paddingLeft: '4rem' }} onClick={() => handleClickCity(state, city)}>
                      <ListItemText primary={city} />
                    </ListItem>
                    <Collapse in={openCity[`${state}-${city}`]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {stateCityGroups[state][city].map((cooler, idx) => (
                          <ListItem
                            button
                            key={idx}
                            style={{ paddingLeft: '8rem' }}
                            onClick={() => handleClickCooler(cooler['Record ID (18)'])}
                          >
                            <ListItemText primary={`Cooler ID: ${cooler['Record ID (18)']}`} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
};

export default PMOwners;
