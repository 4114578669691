import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Card,
    CardContent,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
    Link,
    CircularProgress
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion, AnimatePresence } from 'framer-motion';

const ProjectInstallation = () => {
    const { projectId, installationId } = useParams();
    const { user } = useAuth0();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        serialPlateImage: null,
        completedSetupImage: null,
        assetNumberImage: null,
        notes: '',
        needsAttention: false,
        deliveryComplete: null,
        deliveryNotes: '',
        serialNumber: '',
        assetNumber: '',
        model: '',
        locationName: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        latitude: '',
        longitude: '',
        oldCoolerRemoved: false
    });
    const [showDeliveryNotes, setShowDeliveryNotes] = useState(false);
    const [serialPlatePreview, setSerialPlatePreview] = useState(null);
    const [setupPreview, setSetupPreview] = useState(null);
    const [assetNumberImagePreview, setassetNumberImagePreview] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [removedCoolerPreview, setRemovedCoolerPreview] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const processImage = async (file) => {
        try {
            setIsProcessing(true);
            const formData = new FormData();
            formData.append('image', file);

            const response = await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/extract-text', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const text = response.data.text;
            console.log('OCR Text:', text);
            
            // Look for serial number pattern: A67 followed by 8 digits
            const serialMatches = text.match(/[AА]67\d{9}/gi);
            
            // Look for asset number pattern: VM followed by 8 digits
            const assetMatches = text.match(/[UVM][MN]\d{8}/gi);
            
            const serialNumber = serialMatches ? serialMatches[0] : null;
            const assetNumber = assetMatches ? assetMatches[0].replace(/^[UN]/, 'V') : null;

            if (serialNumber || assetNumber) {
                setFormData(prev => ({
                    ...prev,
                    serialNumber: serialNumber || prev.serialNumber,
                    assetNumber: assetNumber || prev.assetNumber
                }));
            }
        } catch (err) {
            console.error('Processing error:', err);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleImageUpload = (type, event) => {
        const file = event.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            if (type === 'serialPlate') {
                setSerialPlatePreview(previewUrl);
                setFormData(prev => ({ ...prev, serialPlateImage: file }));
            } else if (type === 'completedSetup') {
                setSetupPreview(previewUrl);
                setFormData(prev => ({ ...prev, completedSetupImage: file }));
            } else if (type === 'assetNumber') {
                setassetNumberImagePreview(previewUrl);
                setFormData(prev => ({ ...prev, assetNumberImage: file }));
                processImage(file); // Only process the asset number image
            } else if (type === 'removedCooler') {
                setRemovedCoolerPreview(previewUrl);
                setFormData(prev => ({ ...prev, removedCoolerImage: file }));
            }
        }
    };

    const handleRemoveImage = (type) => {
        if (type === 'serialPlate') {
            setSerialPlatePreview(null);
            setFormData(prev => ({ ...prev, serialPlateImage: null }));
        } else if (type === 'completedSetup') {
            setSetupPreview(null);
            setFormData(prev => ({ ...prev, completedSetupImage: null }));
        } else if (type === 'assetNumber') {
            setassetNumberImagePreview(null);
            setFormData(prev => ({ ...prev, assetNumberImage: null }));
        } else if (type === 'removedCooler') {
            setRemovedCoolerPreview(null);
            setFormData(prev => ({ ...prev, removedCoolerImage: null }));
        }
    };

    const handleDeliveryChange = (value) => {
        setFormData(prev => ({ ...prev, deliveryComplete: value }));
        if (!value) {
            setShowDeliveryNotes(true);
        }
    };

    const handleSubmit = async () => {
        if (!formData.serialPlateImage || !formData.completedSetupImage) {
            alert('Please upload both required photos');
            return;
        }

        setIsSubmitting(true);

        try {
            const submitData = new FormData();
            submitData.append('serialPlateImage', formData.serialPlateImage);
            submitData.append('completedSetupImage', formData.completedSetupImage);
            if (formData.assetNumberImage) {
                submitData.append('assetNumberImage', formData.assetNumberImage);
            }
            submitData.append('serialNumber', formData.serialNumber);
            submitData.append('assetNumber', formData.assetNumber);
            submitData.append('model', formData.model);
            submitData.append('notes', formData.notes);
            submitData.append('needsAttention', formData.needsAttention);
            submitData.append('deliveryComplete', formData.deliveryComplete);
            submitData.append('deliveryNotes', formData.deliveryNotes || '');
            submitData.append('installedBy', user?.name || 'Unknown');
            
            // Location data
            submitData.append('locationName', formData.locationName);
            submitData.append('address', formData.address);
            submitData.append('city', formData.city);
            submitData.append('state', formData.state);
            submitData.append('zipCode', formData.zipCode || '');
            submitData.append('latitude', formData.latitude || '');
            submitData.append('longitude', formData.longitude || '');

            const response = await axios.post(
                `https://freshpet-pm-9087312eb841.herokuapp.com/api/project-installations/${projectId}/${installationId}`,
                submitData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response.status === 200) {
                setShowSuccess(true);
                // Wait for animation to complete
                setTimeout(() => {
                    navigate(`/projects/${projectId}/installations`);
                }, 1500);
            }
        } catch (error) {
            console.error('Error submitting installation:', error);
            alert('Error submitting installation. Please try again.');
            setIsSubmitting(false);
        }
    };

    if (isSubmitting || showSuccess) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 9999
                }}
            >
                <AnimatePresence>
                    {isSubmitting && !showSuccess && (
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.5, opacity: 0 }}
                        >
                            <CircularProgress
                                size={60}
                                thickness={4}
                                sx={{ color: '#1B40A7' }}
                            />
                        </motion.div>
                    )}
                    {showSuccess && (
                        <motion.div
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            style={{ textAlign: 'center' }}
                        >
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                transition={{ 
                                    type: "spring",
                                    stiffness: 260,
                                    damping: 20 
                                }}
                            >
                                <CheckCircleIcon 
                                    sx={{ 
                                        fontSize: 80,
                                        color: '#1B40A7',
                                        mb: 2
                                    }} 
                                />
                            </motion.div>
                            <Typography 
                                variant="h5"
                                sx={{ 
                                    fontFamily: 'NeueEinstellungBold',
                                    color: '#1B40A7'
                                }}
                            >
                                Submitted!
                            </Typography>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
        );
    }

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        color: 'rgb(27, 64, 167)',
                        mr: 2,
                        '&:hover': { backgroundColor: 'rgba(27, 64, 167, 0.1)' }
                    }}
                >
                    <ArrowBackIcon />
                </Button>
                <Typography variant="h4" sx={{ fontFamily: 'NeueEinstellungBold', fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                    Installation Details
                </Typography>
            </Box>

            {/* Instructions Section */}
            <Paper elevation={0} sx={{ mb: 4, borderRadius: '10px' }}>
                <CardContent>
                    <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', mb: 2 }}>
                        Instructions
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    height: 200,
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <img 
                                    src="/Look_of_Success.webp" 
                                    alt="Example of correct setup"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            
                            <Link 
                                href="/kdp_doc.pdf" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                sx={{ 
                                    fontFamily: 'NeueEinstellungBold', 
                                    color: '#1B40A7',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline'
                                    }
                                }}
                            >
                                Full Instructions PDF
                            </Link>
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper>

            {/* Photo Upload Section */}
            <Paper elevation={0} sx={{ mb: 4, borderRadius: '10px' }}>
                <CardContent>
                    <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', mb: 2 }}>
                        Photo Uploads
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ position: 'relative' }}>
                                {serialPlatePreview ? (
                                    <>
                                        <img
                                            src={serialPlatePreview}
                                            alt="Serial Plate Preview"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '8px',
                                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                                            }}
                                        />
                                        <Button
                                            onClick={() => handleRemoveImage('serialPlate')}
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                minWidth: 'auto',
                                                p: 0.5,
                                                bgcolor: 'rgba(255, 255, 255, 0.8)',
                                                borderRadius: '50%',
                                                '&:hover': {
                                                    bgcolor: 'rgba(255, 255, 255, 0.95)'
                                                }
                                            }}
                                        >
                                            <CancelIcon sx={{ color: '#1B40A7' }} />
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        component="label"
                                        fullWidth
                                        sx={{
                                            height: 200,
                                            border: '2px dashed rgba(27, 64, 167, 0.2)',
                                            borderRadius: '8px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            '&:hover': {
                                                bgcolor: 'rgba(27, 64, 167, 0.05)'
                                            }
                                        }}
                                    >
                                        <AddPhotoAlternateIcon sx={{ fontSize: 48, mb: 1, color: '#1B40A7' }} />
                                        <Typography sx={{ fontFamily: 'NeueEinstellungBold', color: '#1B40A7' }}>
                                            Cooler Serial Plate
                                        </Typography>
                                        <input
                                            type="file"
                                            hidden
                                            accept="image/*"
                                            onChange={(e) => handleImageUpload('serialPlate', e)}
                                        />
                                    </Button>
                                )}
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{ position: 'relative' }}>
                                {assetNumberImagePreview ? (
                                    <>
                                        <img
                                            src={assetNumberImagePreview}
                                            alt="Asset Number Image Preview"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '8px',
                                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                                            }}
                                        />
                                        <Button
                                            onClick={() => handleRemoveImage('assetNumber')}
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                minWidth: 'auto',
                                                p: 0.5,
                                                bgcolor: 'rgba(255, 255, 255, 0.8)',
                                                borderRadius: '50%',
                                                '&:hover': {
                                                    bgcolor: 'rgba(255, 255, 255, 0.95)'
                                                }
                                            }}
                                        >
                                            <CancelIcon sx={{ color: '#1B40A7' }} />
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        component="label"
                                        fullWidth
                                        disabled={isProcessing}
                                        sx={{
                                            height: 200,
                                            border: '2px dashed rgba(27, 64, 167, 0.2)',
                                            borderRadius: '8px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            '&:hover': {
                                                bgcolor: 'rgba(27, 64, 167, 0.05)'
                                            }
                                        }}
                                    >
                                        {isProcessing ? (
                                            <CircularProgress size={48} sx={{ color: '#1B40A7' }} />
                                        ) : (
                                            <>
                                                <AddPhotoAlternateIcon sx={{ fontSize: 48, mb: 1, color: '#1B40A7' }} />
                                                <Typography sx={{ fontFamily: 'NeueEinstellungBold', color: '#1B40A7' }}>
                                                    Asset Number Image
                                                </Typography>
                                            </>
                                        )}
                                        <input
                                            type="file"
                                            hidden
                                            accept="image/*"
                                            onChange={(e) => handleImageUpload('assetNumber', e)}
                                            disabled={isProcessing}
                                        />
                                    </Button>
                                )}
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{ position: 'relative' }}>
                                {setupPreview ? (
                                    <>
                                        <img
                                            src={setupPreview}
                                            alt="Completed Setup Preview"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '8px',
                                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                                            }}
                                        />
                                        <Button
                                            onClick={() => handleRemoveImage('completedSetup')}
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                minWidth: 'auto',
                                                p: 0.5,
                                                bgcolor: 'rgba(255, 255, 255, 0.8)',
                                                borderRadius: '50%',
                                                '&:hover': {
                                                    bgcolor: 'rgba(255, 255, 255, 0.95)'
                                                }
                                            }}
                                        >
                                            <CancelIcon sx={{ color: '#1B40A7' }} />
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        component="label"
                                        fullWidth
                                        sx={{
                                            height: 200,
                                            border: '2px dashed rgba(27, 64, 167, 0.2)',
                                            borderRadius: '8px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            '&:hover': {
                                                bgcolor: 'rgba(27, 64, 167, 0.05)'
                                            }
                                        }}
                                    >
                                        <AddPhotoAlternateIcon sx={{ fontSize: 48, mb: 1, color: '#1B40A7' }} />
                                        <Typography sx={{ fontFamily: 'NeueEinstellungBold', color: '#1B40A7' }}>
                                            Completed Setup
                                        </Typography>
                                        <input
                                            type="file"
                                            hidden
                                            accept="image/*"
                                            onChange={(e) => handleImageUpload('completedSetup', e)}
                                        />
                                    </Button>
                                )}
                            </Box>
                        </Grid>

                       
                    </Grid>
                </CardContent>
            </Paper>

            {/* Serial Number and Asset Number Section */}
            <Paper elevation={0} sx={{ mb: 4, borderRadius: '10px' }}>
                <CardContent>
                    <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', mb: 2 }}>
                        Cooler Details
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Serial Number"
                                value={formData.serialNumber}
                                onChange={(e) => setFormData(prev => ({ ...prev, serialNumber: e.target.value }))}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                        fontFamily: 'NeueEinstellungNormal'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Asset Number"
                                value={formData.assetNumber}
                                onChange={(e) => setFormData(prev => ({ ...prev, assetNumber: e.target.value }))}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                        fontFamily: 'NeueEinstellungNormal'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper>

            {/* Notes Section */}
            <Paper elevation={0} sx={{ mb: 4, borderRadius: '10px' }}>
                <CardContent>
                    <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', mb: 2 }}>
                        Notes
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={formData.notes}
                        onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                fontFamily: 'NeueEinstellungNormal'
                            }
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.needsAttention}
                                onChange={(e) => setFormData(prev => ({ ...prev, needsAttention: e.target.checked }))}
                                sx={{
                                    color: '#1B40A7',
                                    '&.Mui-checked': {
                                        color: '#1B40A7',
                                    },
                                }}
                            />
                        }
                        label="Note needs attention"
                        sx={{
                            mt: 2,
                            fontFamily: 'NeueEinstellungBold',
                        }}
                    />
                </CardContent>
            </Paper>

            {/* Delivery Status Section */}
            <Paper elevation={0} sx={{ mb: 4, borderRadius: '10px' }}>
                <CardContent>
                    <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', mb: 2 }}>
                        Delivery Complete?
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.deliveryComplete === true}
                                        onChange={() => handleDeliveryChange(true)}
                                        sx={{
                                            color: '#1B40A7',
                                            '&.Mui-checked': {
                                                color: '#1B40A7',
                                            },
                                        }}
                                    />
                                }
                                label="Yes"
                                sx={{ fontFamily: 'NeueEinstellungBold' }}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.deliveryComplete === false}
                                        onChange={() => handleDeliveryChange(false)}
                                        sx={{
                                            color: '#1B40A7',
                                            '&.Mui-checked': {
                                                color: '#1B40A7',
                                            },
                                        }}
                                    />
                                }
                                label="No"
                                sx={{ fontFamily: 'NeueEinstellungBold' }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper>

            {/* Delivery Notes Dialog */}
            <Dialog
                open={showDeliveryNotes}
                onClose={() => setShowDeliveryNotes(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '10px',
                        width: '100%',
                        maxWidth: { xs: '90%', sm: '500px' }
                    }
                }}
            >
                <DialogTitle sx={{ fontFamily: 'NeueEinstellungBold' }}>
                    Delivery Notes Required
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={formData.deliveryNotes}
                        onChange={(e) => setFormData(prev => ({ ...prev, deliveryNotes: e.target.value }))}
                        placeholder="Please explain why delivery was not completed..."
                        sx={{
                            mt: 2,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                fontFamily: 'NeueEinstellungNormal'
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button
                        onClick={() => setShowDeliveryNotes(false)}
                        sx={{
                            color: 'text.secondary',
                            fontFamily: 'NeueEinstellungBold'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => setShowDeliveryNotes(false)}
                        disabled={!formData.deliveryNotes}
                        sx={{
                            backgroundColor: '#1B40A7',
                            color: 'white',
                            fontFamily: 'NeueEinstellungBold',
                            '&:hover': {
                                backgroundColor: '#152e78'
                            },
                            '&.Mui-disabled': {
                                backgroundColor: 'rgba(27, 64, 167, 0.3)',
                                color: 'white'
                            }
                        }}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Old Cooler Removed Checkbox */}
            <Paper elevation={0} sx={{ mb: 4, borderRadius: '10px' }}>
                <CardContent>
                    <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', mb: 2 }}>
                        Old Cooler Removed
                    </Typography>
                    
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.oldCoolerRemoved}
                                onChange={(e) => setFormData(prev => ({ 
                                    ...prev, 
                                    oldCoolerRemoved: e.target.checked 
                                }))}
                                sx={{
                                    color: '#1B40A7',
                                    '&.Mui-checked': {
                                        color: '#1B40A7',
                                    },
                                }}
                            />
                        }
                        label="Old Cooler Removed"
                        sx={{ mb: 2, fontFamily: 'NeueEinstellungBold' }}
                    />

                    {formData.oldCoolerRemoved && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ position: 'relative' }}>
                                    {removedCoolerPreview ? (
                                        <>
                                            <img
                                                src={removedCoolerPreview}
                                                alt="Removed Cooler Preview"
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    borderRadius: '8px',
                                                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                                                }}
                                            />
                                            <Button
                                                onClick={() => handleRemoveImage('removedCooler')}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    minWidth: 'auto',
                                                    p: 0.5,
                                                    bgcolor: 'rgba(255, 255, 255, 0.8)',
                                                    borderRadius: '50%',
                                                    '&:hover': {
                                                        bgcolor: 'rgba(255, 255, 255, 0.95)'
                                                    }
                                                }}
                                            >
                                                <CancelIcon sx={{ color: '#1B40A7' }} />
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            component="label"
                                            fullWidth
                                            sx={{
                                                height: 200,
                                                border: '2px dashed rgba(27, 64, 167, 0.2)',
                                                borderRadius: '8px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                '&:hover': {
                                                    bgcolor: 'rgba(27, 64, 167, 0.05)'
                                                }
                                            }}
                                        >
                                            <AddPhotoAlternateIcon sx={{ fontSize: 48, mb: 1, color: '#1B40A7' }} />
                                            <Typography sx={{ fontFamily: 'NeueEinstellungBold', color: '#1B40A7' }}>
                                                Photo of Removed Cooler
                                            </Typography>
                                            <input
                                                type="file"
                                                hidden
                                                accept="image/*"
                                                onChange={(e) => handleImageUpload('removedCooler', e)}
                                            />
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </CardContent>
            </Paper>

            {/* Submit Button */}
            <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={handleSubmit}
                sx={{
                    height: 56,
                    backgroundColor: '#1B40A7',
                    fontFamily: 'NeueEinstellungBold',
                    borderRadius: '10px',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: '#152e78',
                        boxShadow: 'none'
                    }
                }}
            >
                Submit Installation
            </Button>
        </Container>
    );
};

export default ProjectInstallation;
