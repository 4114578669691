import React, { useState } from 'react';
import axios from 'axios';
import { 
  Button, Box, Typography, Container, Grid, Tabs, Tab, 
  FormControlLabel, Switch, Card, CardContent 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditGoals from './EditGoals';
import BackButton from '../components/BackButton';
import SettingsIcon from '@mui/icons-material/Settings';
import FlagIcon from '@mui/icons-material/Flag';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EmailIcon from '@mui/icons-material/Email';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#1B40A7',
  },
});

const StyledTab = styled(Tab)({
  fontFamily: 'NeueEinstellungBold',
  fontSize: '14px',
  color: '#000',
  '&.Mui-selected': {
    color: '#1B40A7',
  },
});

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  marginBottom: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1B40A7',
  color: '#fff',
  height: '50px',
  boxShadow: '0 2px 4px rgba(27,64,167,0.2)',
  fontFamily: 'NeueEinstellungBold',
  fontSize: '12px',
  padding: '0 24px',
  borderRadius: '8px',
  textTransform: 'none',
  width: '100%',
  '&:hover': {
    backgroundColor: '#152f7a',
    boxShadow: '0 4px 8px rgba(27,64,167,0.3)',
  },
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1),
  },
}));

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showPlaceholders, setShowPlaceholders] = useState(false);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const geocodeAddresses = async () => {
        try {
            // Make a request to the server endpoint for geocoding
            await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/geocode');
            alert('Geocoding process started. This might take a while.');
        } catch (error) {
            console.error('Error triggering geocoding:', error);
            alert('Failed to start geocoding process.');
        }
    };

  const resetDontAskAgainPreference = () => {
    // ...
  };

  const sendEmailReport = async () => {
    // ...
  };

  return (
    <Container maxWidth="md">
      <BackButton />
      <Box sx={{ mb: 4, mt: 3 }}>
        <Typography variant="h4" gutterBottom sx={{ 
          fontFamily: 'NeueEinstellungBold',
          fontSize: { xs: '24px', sm: '32px' }
        }}>
          Settings
        </Typography>
      </Box>

      <StyledTabs 
        value={selectedTab} 
        onChange={handleTabChange} 
        sx={{ mb: 4 }}
      >
        <StyledTab icon={<FlagIcon />} iconPosition="start" label="Edit Goals" />
        <StyledTab icon={<SettingsIcon />} iconPosition="start" label="Advanced" />
      </StyledTabs>

      {selectedTab === 1 && (
        <StyledCard>
          <CardContent>
            <Typography 
              variant="body1" 
              sx={{ 
                fontFamily: 'NeueEinstellungNormal',
                fontSize: '14px',
                mb: 3,
                color: 'text.secondary'
              }}
            >
              You should generally not need to do anything here, these are for testing.
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledButton startIcon={<LocationOnIcon />} onClick={geocodeAddresses}>
                  Start Geocoding
                </StyledButton>
              </Grid>
              <Grid item xs={12}>
                <StyledButton startIcon={<RestartAltIcon />} onClick={resetDontAskAgainPreference}>
                  Reset 'Don't Ask Again' Preference
                </StyledButton>
              </Grid>
              <Grid item xs={12}>
                <StyledButton startIcon={<EmailIcon />} onClick={sendEmailReport}>
                  Send Daily Email Report
                </StyledButton>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={showPlaceholders} 
                      onChange={(e) => setShowPlaceholders(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ViewColumnIcon sx={{ mr: 1 }} />
                      <Typography sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                        Show Cooler Placeholders
                      </Typography>
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      )}

      {selectedTab === 0 && <EditGoals />}
    </Container>
  );
};

export default Settings;
