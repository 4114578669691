import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  Container,
  LinearProgress,
} from '@mui/material';
import {
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryPie
} from 'victory';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonIcon from '@mui/icons-material/Person';

// Styled Components
const BlueLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: '30px',
  borderRadius: '8px',
  backgroundColor: '#CCCCCC',
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px',
    backgroundColor: value > 100 ? '#FF0000' : '#1B40A7',
  },
}));

const StyledButton = styled(Button)({
  backgroundColor: '#1B40A7',
  color: '#fff',
  height: '50px',
  fontFamily: 'NeueEinstellungBold',
  margin: '8px',
  width: 'calc(50% - 16px)',
  borderRadius: '12px',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#152e78',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 15px rgba(27, 64, 167, 0.2)',
  }
});

const StyledPaper = styled(Paper)({
  padding: '1.5rem',
  borderRadius: '16px',
  fontFamily: 'NeueEinstellungBold',
  boxShadow: 'none',
  background: '#ffffff',
  height: '100%',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 15px rgba(27, 64, 167, 0.1)',
  }
});

// Progress Bar Component
const ProgressBar = ({ name, mtdProgress, ytdProgress }) => {
  const mtdValue = parseFloat(mtdProgress);
  const ytdValue = parseFloat(ytdProgress);
  
  return (
    <StyledPaper>
      <Typography
        variant="h6"
        component={Link}
        to={`/technician-data/${name}`}
        sx={{
          color: '#1B40A7',
          textDecoration: 'none',
          fontFamily: 'NeueEinstellungBold',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          mb: 3,
          '&:hover': {
            color: '#152e78',
          }
        }}
      >
        {name}
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" sx={{ fontFamily: 'NeueEinstellungBold' }}>
            YTD Progress
          </Typography>
          <Typography variant="body2" sx={{ 
            fontFamily: 'NeueEinstellungBold',
            color: ytdValue > 100 ? '#00C49F' : '#1B40A7'
          }}>
            {ytdValue}%
          </Typography>
        </Box>
        <Box sx={{ position: 'relative', height: '8px', bgcolor: 'rgba(27, 64, 167, 0.1)', borderRadius: '4px' }}>
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              bgcolor: ytdValue > 100 ? '#00C49F' : '#1B40A7',
              borderRadius: '4px',
              width: `${Math.min(ytdValue, 100)}%`,
              transition: 'width 1s ease-in-out'
            }}
          />
        </Box>
      </Box>

      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" sx={{ fontFamily: 'NeueEinstellungBold' }}>
            MTD Progress
          </Typography>
          <Typography variant="body2" sx={{ 
            fontFamily: 'NeueEinstellungBold',
            color: mtdValue > 100 ? '#00C49F' : '#1B40A7'
          }}>
            {mtdValue}%
          </Typography>
        </Box>
        <Box sx={{ position: 'relative', height: '8px', bgcolor: 'rgba(27, 64, 167, 0.1)', borderRadius: '4px' }}>
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              bgcolor: mtdValue > 100 ? '#00C49F' : '#1B40A7',
              borderRadius: '4px',
              width: `${Math.min(mtdValue, 100)}%`,
              transition: 'width 1s ease-in-out'
            }}
          />
        </Box>
      </Box>
    </StyledPaper>
  );
};

const StatCard = ({ title, value, total, color = '#1B40A7', icon: Icon }) => (
  <Box sx={{ 
    p: 3, 
    borderRadius: '12px',
    bgcolor: '#ffffff',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Icon sx={{ color: color, mr: 1 }} />
      <Typography sx={{ 
        fontFamily: 'NeueEinstellungBold',
        color: 'text.primary'
      }}>
        {title}
      </Typography>
    </Box>
    
    <Box>
      <Typography variant="h3" sx={{ 
        fontFamily: 'NeueEinstellungBold',
        color: color,
        mb: 1
      }}>
        {Math.round((value / total) * 100)}%
      </Typography>
      <Typography variant="body2" sx={{ 
        color: 'text.secondary',
        mb: 2,
        fontFamily: 'NeueEinstellungNormal'
      }}>
        {value} of {total} Coolers
      </Typography>
      <Box sx={{ 
        position: 'relative', 
        height: '8px', 
        bgcolor: 'rgba(27, 64, 167, 0.1)', 
        borderRadius: '4px' 
      }}>
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            bgcolor: color,
            borderRadius: '4px',
            width: `${Math.min((value / total) * 100, 100)}%`,
            transition: 'width 1s ease-in-out'
          }}
        />
      </Box>
    </Box>
  </Box>
);

const AdminDashboard = () => {
  const { user } = useAuth0();
  const [coolers, setCoolers] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mtdServicedCoolersCount, setMtdServicedCoolersCount] = useState(0);
  const [technicianProgress, setTechnicianProgress] = useState([]);
  const [servicedCoolersCount, setServicedCoolersCount] = useState(0);

  const TOTAL_COOLERS = 2400;
  const TOTAL_COOLERS1 = 200;
  const animationDuration = 1000;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [ytdResponse, techResponse, mtdResponse] = await Promise.all([
          axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/ytd-pm-events'),
          axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-progress'),
          axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/mtd-pm-events')
        ]);

        setServicedCoolersCount(ytdResponse.data.length);
        setTechnicianProgress(techResponse.data.data);

        // Process MTD data
        const processedData = mtdResponse.data.reduce((acc, event) => {
          const date = new Date(event.pm_date).toISOString().split('T')[0];
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {});

        const chartData = Object.entries(processedData).map(([date, count]) => ({
          date,
          count
        }));

        setServiceData(chartData);
        setMtdServicedCoolersCount(chartData.reduce((total, data) => total + data.count, 0));

      } catch (err) {
        console.error('Error fetching dashboard data:', err);
      }
    };

    fetchAllData();
  }, []);

  const pieChartData = [
    { x: "Serviced", y: servicedCoolersCount },
    { x: "Unserviced", y: TOTAL_COOLERS - servicedCoolersCount }
  ];

  const pieChartData1 = [
    { x: "Serviced", y: mtdServicedCoolersCount },
    { x: "Unserviced", y: TOTAL_COOLERS1 - mtdServicedCoolersCount }
  ];

  return (
    <Container maxWidth="lg" sx={{ pb: 4 }}>
      <Paper
        elevation={0}
        sx={{
          background: 'linear-gradient(90deg, #1B40A7, #2b5be0)',
          padding: '1.5rem',
          borderRadius: '16px',
          marginBottom: '2rem',
          boxShadow: '0 4px 15px rgba(27, 64, 167, 0.2)',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: '#fff',
            fontFamily: "Akira",
            marginBottom: '0.5rem'
          }}
        >
          Welcome Back, {user?.name?.split(" ")[0]}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: 'rgba(255, 255, 255, 0.8)',
            fontFamily: 'NeueEinstellungBold'
          }}
        >
          Admin Dashboard Overview
        </Typography>
      </Paper>

      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            flexWrap: 'wrap',
          }}>
            <StyledButton component={Link} to="/assign-techs">
              Assign Techs
            </StyledButton>
            <StyledButton component={Link} to="/cooler-records">
              View Coolers
            </StyledButton>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <StatCard 
            title="YTD Performance" 
            value={servicedCoolersCount} 
            total={TOTAL_COOLERS}
            color="#1B40A7"
            icon={TrendingUpIcon}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatCard 
            title="MTD Progress" 
            value={mtdServicedCoolersCount} 
            total={TOTAL_COOLERS1}
            color="#00C49F"
            icon={CheckCircleOutlineIcon}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ 
        mb: 3, 
        fontFamily: 'NeueEinstellungBold',
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
        <PersonIcon sx={{ color: '#1B40A7' }} />
        Technician Progress
      </Typography>
      
      <Grid container spacing={3}>
        {technicianProgress.map((tech) => (
          <Grid item xs={12} sm={6} md={4} key={tech.technicianId}>
            <ProgressBar
              name={tech.technicianName}
              mtdProgress={tech.mtdProgress}
              ytdProgress={tech.ytdProgress}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AdminDashboard;