import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import {
  Container, Typography, Button, Paper, Box, Stepper, Step, StepLabel,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Select, MenuItem, FormControl, InputLabel, CircularProgress,
  Alert, IconButton, Tooltip, ThemeProvider, createTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BackButton from '../components/BackButton';



const theme = createTheme({
  palette: {
    primary: {
      main: '#1c40a7',
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: 'NeueEinstellungNormal',
  '&.header': {
    backgroundColor: '#1c40a7',
    color: theme.palette.common.white,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1c40a7',
  color: 'white',
  fontFamily: 'NeueEinstellungBold',
  '&:hover': {
    backgroundColor: '#152e78',
  },
}));

const UploadCoolers = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [mappings, setMappings] = useState({});
  const [dbExample, setDbExample] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processingStatus, setProcessingStatus] = useState('');

  const dbColumns = [
    "Location", "ShippingAddressLine1", "ShippingCity", "ShippingState",
    "ShippingZip", "ShippingCountry", "AssetStatus", "Manufacturer",
    "Model", "SerialNumber", "Warranty", "Warranty Start Date",
    "Unit Warranty End Date", "AccountStatus", "Compressor Warranty End Date"
  ];

  useEffect(() => {
    const fetchDbExample = async () => {
      try {
        const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/cooler-example');
        setDbExample(response.data);
      } catch (error) {
        console.error('Error fetching cooler example:', error);
        setError('Failed to fetch database example. Please try again later.');
      }
    };
    fetchDbExample();
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setError(null);

    const reader = new FileReader();
    reader.onload = (evt) => {
      try {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        const columns = data[0];
        setColumns(columns);
        setData(data.slice(1));

        const initialMappings = {};
        columns.forEach((col, index) => {
          if (dbColumns.includes(col)) {
            initialMappings[index] = col;
          }
        });
        setMappings(initialMappings);
        setActiveStep(1);
      } catch (error) {
        console.error('Error processing file:', error);
        setError('Failed to process the file. Please ensure it\'s a valid Excel file.');
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleMappingChange = (columnIndex, dbColumn) => {
    setMappings(prev => ({
      ...prev,
      [columnIndex]: dbColumn
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);
    const formattedData = data.map(row => {
      const newRow = {};
      Object.entries(mappings).forEach(([index, dbColumn]) => {
        newRow[dbColumn] = row[index];
      });
      return newRow;
    });

    try {
      setProcessingStatus('Uploading coolers...');
      await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/upload-coolers', formattedData);

      setProcessingStatus('Geocoding addresses...');
      await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/geocode');

      setProcessingStatus('Updating location data...');
      await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/update-locations');

      setActiveStep(3);
    } catch (error) {
      console.error('Error in upload process:', error);
      setError('Error during the upload process. Please try again.');
    } finally {
      setIsLoading(false);
      setProcessingStatus('');
    }
  };

  const steps = ['Upload File', 'Map Columns', 'Process Data', 'Confirmation'];

  return (
    <ThemeProvider theme={theme}>
      <BackButton />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'NeueEinstellungBold', color: '#000000' }}>
          Upload Coolers
        </Typography>
        <Paper sx={{ p: 3, borderRadius: '15px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

          {activeStep === 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <input
                accept=".xlsx, .xls, .xlsm"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="raised-button-file">
                <StyledButton
                  variant="contained"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  sx={{ mb: 2 }}
                >
                  Select Excel File
                </StyledButton>
              </label>
              {file && <Typography variant="body1">{file.name}</Typography>}
            </Box>
          )}

          {activeStep === 1 && (
            <>
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'NeueEinstellungBold', color: '#000000' }}>
                Column Mappings
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="header">Excel Column</StyledTableCell>
                      <StyledTableCell className="header">Database Column</StyledTableCell>
                      <StyledTableCell className="header">
                        Excel Example
                        <Tooltip title="This is an example value from your uploaded Excel file">
                          <IconButton size="small" sx={{ color: 'white' }}>
                            <HelpOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell className="header">
                        Database Example
                        <Tooltip title="This is an example value from the existing database">
                          <IconButton size="small" sx={{ color: 'white' }}>
                            <HelpOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {columns.map((column, index) => (
                      <TableRow key={index}>
                        <StyledTableCell>{column}</StyledTableCell>
                        <StyledTableCell>
                          <FormControl fullWidth>
                            <InputLabel sx={{ fontFamily: 'NeueEinstellungNormal' }}>Map to</InputLabel>
                            <Select
                              value={mappings[index] || ''}
                              onChange={(e) => handleMappingChange(index, e.target.value)}
                              sx={{ fontFamily: 'NeueEinstellungNormal' }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {dbColumns.map((dbColumn) => (
                                <MenuItem key={dbColumn} value={dbColumn} sx={{ fontFamily: 'NeueEinstellungNormal' }}>
                                  {dbColumn}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </StyledTableCell>
                        <StyledTableCell>{data[0] ? data[0][index] : ''}</StyledTableCell>
                        <StyledTableCell>
                          {dbExample && mappings[index] ? dbExample[mappings[index]] : ''}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                <StyledButton
                  variant="contained"
                  onClick={() => setActiveStep(0)}
                >
                  Back
                </StyledButton>
                <StyledButton
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Process Data'}
                </StyledButton>
              </Box>
            </>
          )}

          {activeStep === 2 && (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress size={60} sx={{ mb: 2, color: '#1c40a7' }} />
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'NeueEinstellungBold', color: '#1c40a7' }}>
                Processing Data
              </Typography>
              <Typography variant="body1">
                {processingStatus}
              </Typography>
            </Box>
          )}

          {activeStep === 3 && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom sx={{ fontFamily: 'NeueEinstellungBold', color: '#1c40a7' }}>
                Upload Successful!
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Your coolers have been successfully uploaded, geocoded, and updated in the database.
              </Typography>
              <StyledButton
                variant="contained"
                onClick={() => {
                  setActiveStep(0);
                  setFile(null);
                  setColumns([]);
                  setData([]);
                  setMappings({});
                }}
              >
                Upload Another File
              </StyledButton>
            </Box>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default UploadCoolers;
