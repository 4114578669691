import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Card, CardContent, Typography, Button, Collapse, IconButton, ImageList, ImageListItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BackButton from '../components/BackButton';
// Utility function to convert Excel date to JS date
function excelDateToJSDate(serial) {
  if (!serial) return null;
  const date = new Date((serial - 25569) * 86400 * 1000);
  return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD format
}

const CoolerDetails = () => {
  const { serialNumber } = useParams();
  const navigate = useNavigate();
  const { logout, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [coolerDetails, setCoolerDetails] = useState(null);
  const [pmEvents, setPmEvents] = useState([]);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [pmIdToDelete, setPmIdToDelete] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch('https://freshpet-pm-9087312eb841.herokuapp.com/api/get-user-role', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ userId: user.sub })
          });

          if (response.ok) {
            const data = await response.json();
            setShowDeleteButton(data.roles.includes('records-admin'));
          }
        } catch (error) {
          console.error("Error fetching user role", error);
        }
      }
    };

    fetchUserRole();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  useEffect(() => {
    axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/coolers/${serialNumber}`)
      .then(response => {
        setCoolerDetails(response.data);
        return axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/cooler-pm-events/${serialNumber}`);
      })
      .then(response => {
        setPmEvents(response.data);
      })
      .catch(err => console.error('Error fetching cooler details', err));
  }, [serialNumber]);

  const handleCollapseClick = () => {
    setOpen(!open);
  };

  const handleResolveNote = (pmId) => {
    axios.put(`https://freshpet-pm-9087312eb841.herokuapp.com/api/resolve-note/${pmId}`)
      .then(() => {
        setPmEvents(prevEvents => prevEvents.map(event =>
          event.pm_id === pmId ? { ...event, note_needs_attention: false } : event
        ));
      })
      .catch(err => console.error('Error resolving note', err));
  };

  const handleDeletePMEvent = (pmId) => {
    setPmIdToDelete(pmId);
    setDeleteDialogOpen(true);
  };

  const confirmDeletePMEvent = () => {
    axios.delete(`https://freshpet-pm-9087312eb841.herokuapp.com/api/cooler-service/${pmIdToDelete}`)
      .then(() => {
        setPmEvents(prevEvents => prevEvents.filter(event => event.pm_id !== pmIdToDelete));
        setDeleteDialogOpen(false);
      })
      .catch(err => console.error('Error deleting PM event', err));
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setPmIdToDelete(null);
  };

  const s3BucketUrl = "https://freshpetuploads.s3.us-east-2.amazonaws.com/";

  const getFileLabel = (fileType) => {
    switch (fileType) {
      case 'serialPlate':
        return 'Chiller Serial Plate';
      case 'condensorCoil':
        return 'Condensor Coil';
      case 'frontalPicture':
        return 'Chiller Frontal Picture';
      default:
        return 'File';
    }
  };

  return (
    <Container maxWidth="md" sx={{ borderRadius: '10px', marginTop: '2rem', marginBottom: '2rem' }}>
      <BackButton />
      {coolerDetails && (
        <>
          <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold' }}>
            Cooler: {coolerDetails.SerialNumber}
          </Typography>

          <Typography variant="subtitle1" sx={{ fontFamily: 'NeueEinstellungNormal' }}>
            {coolerDetails.Location}
          </Typography>

          <Typography variant="subtitle1" sx={{ fontFamily: 'NeueEinstellungNormal', marginBottom: '1rem' }}>
            {coolerDetails.ShippingAddressLine1}, {coolerDetails.ShippingCity} {coolerDetails.ShippingState}
          </Typography>
        </>
      )}
      <Card sx={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px', marginTop: '1rem' }}>
        <CardContent>
          <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleCollapseClick}>
            Cooler Details
            <IconButton size="small">
              <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} />
            </IconButton>
          </Typography>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {coolerDetails ? (
              Object.entries(coolerDetails)
                .filter(([key]) => key !== "location" && key !== "RecordID" && key !== "AssignedTechnicianId" && key !== "Latitude" && key !== "Longitude")
                .map(([key, value]) => {
                  let displayValue = value;
                  if (["Warranty Start Date", "Unit Warranty End Date", "Compressor Warranty End Date"].includes(key)) {
                    displayValue = excelDateToJSDate(value) || 'N/A';
                  }
                  return (
                    <Typography key={key} variant="body1" sx={{ fontFamily: 'NeueEinstellungNormal', marginBottom: '0.5rem' }}>
                      {`${key}: ${displayValue}`}
                    </Typography>
                  );
                })
            ) : (
              <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold' }}>Loading cooler details...</Typography>
            )}
          </Collapse>
        </CardContent>
      </Card>

      {pmEvents.map((pmEvent, index) => (
        <Card key={index} sx={{ mt: 2, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
          <CardContent>
            <Typography variant="h6" sx={{ fontFamily: 'NeueEinstellungBold' }}>
              PM Event - {new Date(pmEvent.pm_date).toLocaleString()}
            </Typography>
            <Typography sx={{ fontFamily: 'NeueEinstellungNormal', marginBottom: '2rem' }}>
              Next PM Date: {new Date(pmEvent.next_pm_date).toLocaleDateString()}
            </Typography>
            {pmEvent.notes.map((note, noteIndex) => (
              <React.Fragment key={noteIndex}>
                <Typography variant="body1" sx={{ fontFamily: 'NeueEinstellungNormal', fontWeight: 'bold' }}>
                  Serviced By: {pmEvent.technician_name}
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: 'NeueEinstellungNormal', fontWeight: 'bold' }}>
                  Note: {note.note}
                </Typography>
              </React.Fragment>
            ))}
            {pmEvent.files.length > 0 && (
              <ImageList sx={{ width: '100%', height: 'auto', gap: 2 }} cols={window.innerWidth < 600 ? 1 : 3}>
                {pmEvent.files.map((file, fileIndex) => (
                  <ImageListItem key={fileIndex} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                      src={`${s3BucketUrl}${file.file_url}`}
                      alt={`${getFileLabel(file.file_type)}`}
                      loading="lazy"
                      style={{ borderRadius: '10px', maxWidth: '100%', height: 'auto', cursor: 'pointer' }}
                      onClick={() => handleImageClick(`${s3BucketUrl}${file.file_url}`)}
                    />
                    <Typography variant="caption" sx={{ mt: 1, fontFamily: 'NeueEinstellungBold', marginBottom: '1rem' }}>
                      {getFileLabel(file.file_type)}
                    </Typography>
                  </ImageListItem>
                ))}
              </ImageList>
            )}

            {pmEvent.note_needs_attention && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  marginTop: '10px',
                  backgroundColor: '#1B40A7',
                  color: 'white',
                  borderRadius: '5px',
                  fontFamily: 'NeueEinstellungBold',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#1B40A7'
                  }
                }}
                onClick={() => handleResolveNote(pmEvent.pm_id)}
              >
                Resolve Note
              </Button>
            )}
            {showDeleteButton && (
              <Button
                variant="contained"
                color="error"
                onClick={() => handleDeletePMEvent(pmEvent.pm_id)}
                sx={{ marginTop: '10px', fontFamily: 'NeueEinstellungBold' }}
              >
                Delete PM Event
              </Button>
            )}
          </CardContent>
        </Card>
      ))}

      <Dialog
        open={!!selectedImage}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        sx={{ '& .MuiDialog-paper': { borderRadius: '40px', width: '100%', maxHeight: '100%' } }}
      >
        <img src={selectedImage} alt="Full Size" style={{ width: '100%', height: 'auto' }} />
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle sx={{ fontFamily: 'NeueEinstellungBold' }}>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontFamily: 'NeueEinstellungNormal' }}>
            Are you sure you want to delete this PM? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} sx={{ fontFamily: 'NeueEinstellungBold' }}>
            Back
          </Button>
          <Button onClick={confirmDeletePMEvent} color="error" sx={{ fontFamily: 'NeueEinstellungBold' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CoolerDetails;
