// ChildOfListView.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Typography, List, ListItem } from '@mui/material';
import { CircularProgress } from '@mui/material';

const ChildOfListView = () => {
  const [childOfList, setChildOfList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchChildOfList = async () => {
      try {
        const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/newcoolers/childof');
        setChildOfList(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Failed to fetch data');
        setLoading(false);
      }
    };

    fetchChildOfList();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress style={{ color: '#1B40A7' }} />
      </div>
    );
  }
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <List>
      {childOfList.map((item, index) => (
        <ListItem key={index} button component={Link} to={`/coolers/${item.ChildOf}`}>
          {item.ChildOf}
        </ListItem>
      ))}
    </List>
  );
};

export default ChildOfListView;
