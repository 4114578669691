import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Box, Container } from '@mui/material';
import { keyframes } from '@mui/system';

// Create floating animation
const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

// Create pulse animation
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const NotFound = () => {
  return (
    <Container>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          gap: 4,
          
        }}
      >
        {/* Animated 404 Text */}
        <Typography 
          variant="h1" 
          sx={{
            fontSize: { xs: '6rem', md: '12rem' },
            fontWeight: 'bold',
            color: '#1B40A7',
            fontFamily: 'NeueEinstellungBold',
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          404
        </Typography>

        {/* Message */}
        <Typography 
          variant="h4" 
          sx={{
            color: '#2c3e50',
            mb: 2,
            fontFamily: 'NeueEinstellungBold',
            maxWidth: '600px',
          }}
        >
          Oops! Looks like this page went missing...
        </Typography>

        <Typography 
          variant="h6" 
          sx={{
            color: '#7f8c8d',
            mb: 4,
            fontFamily: 'NeueEinstellungNormal',
            maxWidth: '600px',
          }}
        >
          The page you're looking for has been moved or doesn't exist.
        </Typography>

        {/* Action Buttons */}
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
          <Button 
            variant="contained" 
            component={Link} 
            to="/"
            sx={{
              backgroundColor: '#1B40A7',
              fontSize: '1.1rem',
              padding: '12px 30px',
              borderRadius: '50px',
              fontFamily: 'NeueEinstellungBold',
              textTransform: 'none',
              animation: `${pulse} 2s infinite`,
              '&:hover': {
                backgroundColor: '#152e78',
                transform: 'translateY(-2px)',
                transition: 'all 0.3s ease',
              },
              boxShadow: '0 4px 15px rgba(27, 64, 167, 0.2)',
            }}
          >
            Back to Dashboard
          </Button>

          <Button 
            variant="outlined" 
            component={Link} 
            to="/"
            sx={{
              color: '#1B40A7',
              borderColor: '#1B40A7',
              fontSize: '1.1rem',
              padding: '12px 30px',
              borderRadius: '50px',
              fontFamily: 'NeueEinstellungBold',
              textTransform: 'none',
              '&:hover': {
                borderColor: '#152e78',
                backgroundColor: 'rgba(27, 64, 167, 0.05)',
                transform: 'translateY(-2px)',
                transition: 'all 0.3s ease',
              },
            }}
          >
            Go Home
          </Button>
        </Box>

      </Box>
    </Container>
  );
};

export default NotFound;
