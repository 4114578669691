import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '700px',
  borderRadius: '15px'
};

const darkTheme = [




        {
              "featureType": "water",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#5a70bf"
                  }
              ]
          },
          {
              "featureType": "transit",
              "stylers": [
                  {
                      "color": "#808080"
                  },
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#b3b3b3"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#ffffff"
                  }
              ]
          },
          {
              "featureType": "road.local",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#ffffff"
                  },
                  {
                      "weight": 1.8
                  }
              ]
          },
          {
              "featureType": "road.local",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "color": "#d7d7d7"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#ebebeb"
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#a7a7a7"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#ffffff"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#ffffff"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#efefef"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#696969"
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#737373"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "labels.icon",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "labels",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "color": "#d6d6d6"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "labels.icon",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {},
          {
              "featureType": "poi",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#dadada"
                  }
              ]
          }




];

const libraries = ["geometry"];

const BigMap = () => {
  const mapRef = useRef(null);
  const [mapCenter, setMapCenter] = useState({ lat: 38.419250, lng: -82.445152 });
  const [zoom, setZoom] = useState(15);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState(true);
  const [coolers, setCoolers] = useState([]);
  const [page, setPage] = useState(1);
  const [servicedCoolers, setServicedCoolers] = useState(new Set());

  useEffect(() => {
    const fetchCoolers = async (latitude, longitude, pageNumber) => {
      try {
        setIsLoading(true);
        const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/coolers?lat=${latitude}&lng=${longitude}&page=${pageNumber}`);
        const fetchedCoolers = response.data;

        setCoolers(prevCoolers => {
          const allCoolers = [...prevCoolers, ...fetchedCoolers];
          const uniqueCoolers = Array.from(new Map(allCoolers.map(cooler => [cooler['RecordID'], cooler])).values());
          return uniqueCoolers;
        });
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching coolers:', error);
        setIsLoading(false);
      }
    };

    fetchCoolers(mapCenter.lat, mapCenter.lng, page);
  }, [mapCenter, page]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCmD5wWJPbP6wQuZcZxCv2jdCXPDgLRLl4"
      libraries={libraries}
    >
      <GoogleMap
        onLoad={map => mapRef.current = map}
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={zoom}
        options={{
          styles: darkTheme,
          mapTypeControl: false,
          zoomControl: false,
          streetViewControl: false,
          fullscreenControl: false
        }}
      >
        <MarkerClusterer>
          {(clusterer) =>
            coolers.map(cooler => (
              <Marker
                key={cooler.RecordID}
                position={{
                  lat: parseFloat(cooler.Latitude),
                  lng: parseFloat(cooler.Longitude)
                }}
                clusterer={clusterer}
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </LoadScript>
  );
};

export default BigMap;
