import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import {
    Container,
    Typography,
    Box,
    Paper,
    Card,
    CardContent,
    Button,
    Grid,
    Chip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    CircularProgress,
    Badge,
    Checkbox,
    FormControlLabel,
    ToggleButton,
    ToggleButtonGroup,
    Slide,
    AppBar,
    Toolbar,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import BuildIcon from '@mui/icons-material/Build';
import { motion } from 'framer-motion';
import SearchIcon from '@mui/icons-material/Search';

const TechnicianInstallations = () => {
    const { techName } = useParams();
    const { user } = useAuth0();
    const [installations, setInstallations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterStatus, setFilterStatus] = useState('all');
    const [selectedImage, setSelectedImage] = useState(null);
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedInstallations, setSelectedInstallations] = useState(new Set());
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [updateTarget, setUpdateTarget] = useState('selected'); // 'all' or 'selected'
    const [fullSizeImage, setFullSizeImage] = useState(null);

    useEffect(() => {
        fetchInstallations();
    }, [techName, filterStatus]);

    const fetchInstallations = async () => {
        try {
            let url = `https://freshpet-pm-9087312eb841.herokuapp.com/api/technician-installations/${encodeURIComponent(techName)}`;
            if (filterStatus !== 'all') {
                url += `?status=${filterStatus}`;
            }
            const response = await fetch(url);
            const data = await response.json();
            setInstallations(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching installations:', error);
            setLoading(false);
        }
    };

    const handlePaymentUpdate = async (status) => {
        try {
            const installationsToUpdate = updateTarget === 'all'
                ? getFilteredInstallations().map(i => i.id)
                : Array.from(selectedInstallations);

            await Promise.all(installationsToUpdate.map(installationId =>
                fetch(`https://freshpet-pm-9087312eb841.herokuapp.com/api/installation-payment/${installationId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        paymentStatus: status,
                        updatedBy: user?.name || 'Unknown User',
                    }),
                })
            ));

            fetchInstallations();
            setSelectedInstallations(new Set());
            setOpenStatusDialog(false);
        } catch (error) {
            console.error('Error updating payment status:', error);
        }
    };

    const getFilteredInstallations = () => {
        return installations.filter(installation => {
            const searchString = searchQuery.toLowerCase();
            return (
                installation.store_number?.toLowerCase().includes(searchString) ||
                installation.serial_number?.toLowerCase().includes(searchString) ||
                installation.asset_number?.toLowerCase().includes(searchString) ||
                installation.city?.toLowerCase().includes(searchString)
            );
        });
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'paid':
                return '#4CAF50';
            case 'pending':
                return '#FFA726';
            case 'unpaid':
                return '#F44336';
            default:
                return '#757575';
        }
    };

    const renderImageDialog = (photos) => (
        <Dialog
            open={openImageDialog}
            onClose={() => setOpenImageDialog(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle sx={{ fontFamily: 'NeueEinstellungBold' }}>Installation Photos</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {Object.entries(photos).map(([type, url]) => (
                        <Grid item xs={12} sm={6} md={4} key={type}>
                            <Card>
                                <img
                                    src={url}
                                    alt={type}
                                    style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                                    onClick={() => setFullSizeImage(url)}
                                />
                                <Typography variant="caption" sx={{ p: 1, display: 'block', textAlign: 'center' }}>
                                    {type.replace(/([A-Z])/g, ' $1').trim()}
                                </Typography>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    );

    const welcomeStyle = {
        background: 'linear-gradient(90deg, #1B40A7, #2b5be0)',
        padding: '1.5rem',
        borderRadius: '16px',
        marginBottom: '2rem',
        boxShadow: '0 4px 15px rgba(27, 64, 167, 0.2)',
    };

    const handleCardClick = (installationId, event) => {
        // Prevent triggering when clicking on buttons/links inside the card
        if (event.target.closest('button') || event.target.closest('a')) {
            return;
        }
        
        const newSelected = new Set(selectedInstallations);
        if (selectedInstallations.has(installationId)) {
            newSelected.delete(installationId);
        } else {
            newSelected.add(installationId);
        }
        setSelectedInstallations(newSelected);
    };

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress sx={{ color: '#1B40A7' }} />
            </Box>
        );
    }

    const handleSelectAll = () => {
        if (selectedInstallations.size === getFilteredInstallations().length) {
            setSelectedInstallations(new Set());
        } else {
            setSelectedInstallations(new Set(getFilteredInstallations().map(i => i.id)));
        }
    };

    const baseCardStyle = {
        p: 3,
        borderRadius: '16px',
        border: '1px solid #E0E0E0',
        height: '100%',
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 24px rgba(27, 64, 167, 0.12)',
            borderColor: '#1B40A7',
        },
    };

    const buttonStyle = {
        borderRadius: '12px',
        textTransform: 'none',
        fontFamily: 'NeueEinstellungBold',
        px: 3,
        py: 1.2,
    };

    const chipStyle = {
        borderRadius: '8px',
        height: '32px',
        '& .MuiChip-label': {
            px: 2,
            fontFamily: 'NeueEinstellungBold',
        }
    };

    const handleFilterChange = (event, newFilter) => {
        if (newFilter !== null) {
            setFilterStatus(newFilter);
        }
    };

    const searchFieldStyle = {
        '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            backgroundColor: '#ffffff',
            '&:hover': {
                backgroundColor: '#F0F2F7',
            },
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: '#1B40A7',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1B40A7',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 16px',
        },
    };

    const toggleButtonStyle = {
        borderRadius: '10px',
        px: 3,
        py: 1,
        border: '1px solid #E0E0E0',
        '&.Mui-selected': {
            backgroundColor: '#1B40A7',
            color: 'white',
            '&:hover': {
                backgroundColor: '#152e78',
            },
        },
        '&:hover': {
            backgroundColor: 'rgba(27, 64, 167, 0.08)',
        },
        textTransform: 'none',
        fontFamily: 'NeueEinstellungBold',
    };

    const customCheckboxStyle = {
        '& .MuiCheckbox-root': {
            color: '#1B40A7',
            padding: '12px',
            '&:hover': {
                backgroundColor: 'rgba(27, 64, 167, 0.08)',
            },
            '&.Mui-checked': {
                color: '#1B40A7',
            },
        },
        '& .MuiFormControlLabel-label': {
            fontFamily: 'NeueEinstellungBold',
        },
    };

    const statusButtonStyle = {
        ...buttonStyle,
        minWidth: '120px',
        fontWeight: 'bold',
        '&:hover': {
            transform: 'translateY(-2px)',
            transition: 'transform 0.2s ease',
        }
    };

    const StatusUpdateBar = () => (
        <Slide direction="up" in={selectedInstallations.size > 0}>
            <AppBar
                position="fixed"
                color="default"
                sx={{
                    top: 'auto',
                    bottom: 0,
                    backgroundColor: 'white',
                    boxShadow: '0 -4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Container maxWidth="lg">
                    <Toolbar sx={{ 
                        justifyContent: 'space-between',
                        py: 1,
                    }}>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                fontFamily: 'NeueEinstellungBold',
                                color: '#1B40A7' 
                            }}
                        >
                            {selectedInstallations.size} installation{selectedInstallations.size !== 1 ? 's' : ''} selected
                        </Typography>
                        
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                variant="outlined"
                                onClick={() => handlePaymentUpdate('unpaid')}
                                sx={{
                                    ...statusButtonStyle,
                                    borderColor: '#F44336',
                                    color: '#F44336',
                                    '&:hover': {
                                        borderColor: '#F44336',
                                        backgroundColor: '#F4433610',
                                    }
                                }}
                            >
                                Mark Unpaid
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => handlePaymentUpdate('pending')}
                                sx={{
                                    ...statusButtonStyle,
                                    borderColor: '#FFA726',
                                    color: '#FFA726',
                                    '&:hover': {
                                        borderColor: '#FFA726',
                                        backgroundColor: '#FFA72610',
                                    }
                                }}
                            >
                                Mark Pending
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => handlePaymentUpdate('paid')}
                                sx={{
                                    ...statusButtonStyle,
                                    backgroundColor: '#4CAF50',
                                    '&:hover': {
                                        backgroundColor: '#3d8c40',
                                    }
                                }}
                            >
                                Mark Paid
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => setSelectedInstallations(new Set())}
                                sx={{
                                    ...statusButtonStyle,
                                    borderColor: '#757575',
                                    color: '#757575',
                                    '&:hover': {
                                        borderColor: '#757575',
                                        backgroundColor: '#75757510',
                                    }
                                }}
                            >
                                Clear Selection
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Slide>
    );

    return (
        <Container maxWidth="lg" sx={{ py: 4, mb: selectedInstallations.size > 0 ? 10 : 0 }}>
            <Button
                onClick={() => window.history.back()}
                startIcon={<ArrowBackIcon />}
                sx={{
                    ...buttonStyle,
                    mb: 3,
                    color: '#1B40A7',
                    '&:hover': { 
                        backgroundColor: 'rgba(27, 64, 167, 0.08)',
                        
                    }
                }}
            >
                Back
            </Button>

            <Box sx={{ mb: 4 }}>
                <Typography 
                    variant="h4" 
                    sx={{ 
                        fontFamily: 'NeueEinstellungBold',
                        color: '#000000',
                        mb: 2 
                    }}
                >
                    {decodeURIComponent(techName)}'s Installations
                </Typography>
                <Grid container spacing={2}>
                    <Grid item>
                        <Chip
                            icon={<BuildIcon sx={{ color: '#1B40A7' }} />}
                            label={`Total Installations: ${installations.length}`}
                            sx={{
                                backgroundColor: '#1B40A710',
                                color: '#1B40A7',
                                '& .MuiChip-label': { 
                                    fontFamily: 'NeueEinstellungBold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Chip
                            icon={<CheckCircleIcon sx={{ color: '#1B40A7' }} />}
                            label={`Selected: ${selectedInstallations.size}`}
                            sx={{
                                backgroundColor: '#1B40A710',
                                color: '#1B40A7',
                                '& .MuiChip-label': { 
                                    fontFamily: 'NeueEinstellungBold'
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            placeholder="Search by store number, serial number, asset number, or city..."
                            variant="outlined"
                            fullWidth
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={searchFieldStyle}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon sx={{ color: '#1B40A7', mr: 1 }} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleButtonGroup
                            value={filterStatus}
                            exclusive
                            onChange={handleFilterChange}
                            aria-label="filter status"
                            sx={{ 
                                display: 'flex', 
                                flexWrap: 'wrap',
                                gap: 1,
                                '& .MuiToggleButtonGroup-grouped': {
                                    border: 'none',
                                    mx: 0.5,
                                    '&:not(:first-of-type)': {
                                        borderRadius: '10px',
                                    },
                                    '&:first-of-type': {
                                        borderRadius: '10px',
                                    },
                                },
                            }}
                        >
                            <ToggleButton value="all" sx={toggleButtonStyle}>
                                All Installations
                            </ToggleButton>
                            <ToggleButton value="paid" sx={toggleButtonStyle}>
                                Paid
                            </ToggleButton>
                            <ToggleButton value="pending" sx={toggleButtonStyle}>
                                Pending
                            </ToggleButton>
                            <ToggleButton value="unpaid" sx={toggleButtonStyle}>
                                Unpaid
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Box>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={selectedInstallations.size === getFilteredInstallations().length}
                        indeterminate={
                            selectedInstallations.size > 0 && 
                            selectedInstallations.size < getFilteredInstallations().length
                        }
                        onChange={handleSelectAll}
                    />
                }
                label={`Select All (${getFilteredInstallations().length})`}
                sx={{
                    ...customCheckboxStyle,
                    mb: 2,
                    backgroundColor: selectedInstallations.size > 0 ? 'rgba(27, 64, 167, 0.08)' : 'transparent',
                    borderRadius: '10px',
                    px: 1,
                    py: 0.5,
                }}
            />

            <Grid container spacing={3}>
                {getFilteredInstallations().map((installation, index) => (
                    <Grid item xs={12} sm={6} md={4} key={installation.id}>
                        <Paper
                            elevation={0}
                            onClick={(e) => handleCardClick(installation.id, e)}
                            sx={{
                                ...baseCardStyle,
                                backgroundColor: selectedInstallations.has(installation.id)
                                    ? 'rgba(27, 64, 167, 0.08)'
                                    : 'white',
                            }}
                        >
                            <Box sx={{ position: 'absolute', top: 12, right: 12 }}>
                                <Checkbox
                                    checked={selectedInstallations.has(installation.id)}
                                    onChange={(e) => {
                                        const newSelected = new Set(selectedInstallations);
                                        if (e.target.checked) {
                                            newSelected.add(installation.id);
                                        } else {
                                            newSelected.delete(installation.id);
                                        }
                                        setSelectedInstallations(newSelected);
                                    }}
                                    sx={{ 
                                        color: '#1B40A7',
                                        '&.Mui-checked': { color: '#1B40A7' }
                                    }}
                                />
                            </Box>

                            <Typography variant="h6" sx={{ mb: 2, fontFamily: 'NeueEinstellungBold' }}>
                                Store #{installation.store_number}
                            </Typography>

                            <Chip
                                label={installation.payment_status || 'unpaid'}
                                size="small"
                                sx={{
                                    ...chipStyle,
                                    backgroundColor: getStatusColor(installation.payment_status),
                                    color: 'white',
                                    mb: 2,
                                    fontWeight: 600,
                                }}
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
                                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <CalendarTodayIcon fontSize="small" sx={{ color: '#1B40A7' }} />
                                    {new Date(installation.installation_date).toLocaleDateString()}
                                </Typography>
                                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <LocationOnIcon fontSize="small" sx={{ color: '#1B40A7' }} />
                                    {installation.city}, {installation.state}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
                                <Chip
                                    size="small"
                                    label={`SN: ${installation.serial_number}`}
                                    sx={{ backgroundColor: '#F5F5F5' }}
                                />
                                <Chip
                                    size="small"
                                    label={`AN: ${installation.asset_number}`}
                                    sx={{ backgroundColor: '#F5F5F5' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    onClick={() => {
                                        setSelectedImage(installation);
                                        setOpenImageDialog(true);
                                    }}
                                    sx={{ color: '#1B40A7' }}
                                >
                                    <Badge
                                        badgeContent={Object.keys(installation.photos).length}
                                        color="primary"
                                    >
                                        <ImageIcon />
                                    </Badge>
                                </IconButton>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            <StatusUpdateBar />

            {/* Image Gallery Dialog */}
            {selectedImage && renderImageDialog(selectedImage.photos)}

            {/* Full Size Image Dialog */}
            <Dialog
                open={!!fullSizeImage}
                onClose={() => setFullSizeImage(null)}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: '12px',
                        overflow: 'hidden'
                    }
                }}
            >
                <DialogContent sx={{ p: 0 }}>
                    <img
                        src={fullSizeImage}
                        alt="Full size"
                        style={{
                            width: '100%',
                            height: 'auto',
                            display: 'block'
                        }}
                    />
                </DialogContent>
            </Dialog>

            {/* No Results Message */}
            {getFilteredInstallations().length === 0 && (
                <Box
                    sx={{
                        textAlign: 'center',
                        py: 8
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: 'text.secondary',
                            fontFamily: 'NeueEinstellungBold'
                        }}
                    >
                        No installations found
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: 'text.secondary',
                            fontFamily: 'NeueEinstellungNormal'
                        }}
                    >
                        Try adjusting your search or filter criteria
                    </Typography>
                </Box>
            )}
        </Container>
    );
};

export default TechnicianInstallations;