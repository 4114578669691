import React, { createContext, useContext } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children, isOpen }) => {
  return (
    <SidebarContext.Provider value={{ isOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
}; 