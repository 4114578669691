import React, { useState, useEffect } from 'react';
import { Button, Grid, Card, CardContent, Typography, TextField, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { FormControlLabel, Checkbox } from '@mui/material';
import BackButton from '../components/BackButton';

import { CircularProgress } from '@mui/material'; // Import CircularProgress here

const ServicePage = () => {
  const { user } = useAuth0();
  const { coolerId } = useParams();
  const [cooler, setCooler] = useState(null);
  const [pmGuide, setPmGuide] = useState([]);
  const [notes, setNotes] = useState('');
  const [consumedGasket, setConsumedGasket] = useState(false);
  const [consumedCover, setConsumedCover] = useState(false);
  const [noteNeedsAttention, setNoteNeedsAttention] = useState(false);
  const [serialPlateFile, setSerialPlateFile] = useState(null);
  const [condensorCoilFile, setCondensorCoilFile] = useState(null);
  const [frontalPictureFile, setFrontalPictureFile] = useState(null);
  const [serialPlatePreview, setSerialPlatePreview] = useState(null);
  const [condensorCoilPreview, setCondensorCoilPreview] = useState(null);
  const [frontalPicturePreview, setFrontalPicturePreview] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupNotes, setPopupNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [pmId, setPmId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCoolerDetails = async () => {
      try {
        const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/coolers/${coolerId}`);
        const coolerData = response.data;
        setCooler(coolerData);

        // Determine which PM guide to use based on the cooler's model
        const guideName = modelToGuideName[coolerData.Model] || "NA";
        const guideSteps = pmGuides[guideName] || ["No specific guide available for this model."];
        setPmGuide(guideSteps);
      } catch (error) {
        console.error('Error fetching cooler details:', error);
      }
    };

    fetchCoolerDetails();
  }, [coolerId]);

  function renderGuideSteps(steps) {
  return (
    <ul>
      {steps.map((step, index) => (
        Array.isArray(step) ? (
          <li key={index}>
            {step[0]} {/* This is the title for the sub-steps */}
            {renderGuideSteps(step[1])} {/* Recursive call for the sub-steps */}
          </li>
        ) : (
          <li key={index}>{step}</li>
        )
      ))}
    </ul>
  );
}
const LoadingOverlay = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress style={{ color: '#1B40A7' }} />
  </Box>
);
const handleFileUpload = (event, fileType) => {
  const file = event.target.files[0];
  if (!file) return;

  const previewUrl = URL.createObjectURL(file);

  if (fileType === 'serialPlate') {
    setSerialPlatePreview(previewUrl);
    setSerialPlateFile(file);
  } else if (fileType === 'condensorCoil') {
    setCondensorCoilPreview(previewUrl);
    setCondensorCoilFile(file);
  } else if (fileType === 'frontalPicture') {
    setFrontalPicturePreview(previewUrl);
    setFrontalPictureFile(file);
  }
};
  const removeImagePreview = (fileType) => {
  // Revoke the object URL to avoid memory leaks
  if (fileType === 'serialPlate' && serialPlatePreview) {
    URL.revokeObjectURL(serialPlatePreview);
    setSerialPlatePreview(null);
  } else if (fileType === 'condensorCoil' && condensorCoilPreview) {
    URL.revokeObjectURL(condensorCoilPreview);
    setCondensorCoilPreview(null);
  } else if (fileType === 'frontalPicture' && frontalPicturePreview) {
    URL.revokeObjectURL(frontalPicturePreview);
    setFrontalPicturePreview(null);
  }

  // The rest of your removal logic...
};
const handlePopupSubmit = async () => {
  console.log('handlePopupSubmit called');
  setShowPopup(false);
  setNotes((prevNotes) => prevNotes + '\n' + popupNotes);

  try {
    console.log('Starting submission');
    setIsLoading(true); // Start loading

    // Include user details in the PM event creation
    console.log('Creating PM event');
    const pmEventResponse = await axios.post(
      'https://freshpet-pm-9087312eb841.herokuapp.com/api/addPMEvent',
      {
        SerialNumber: coolerId,
        technicianAuth0Id: user.sub, // User's Auth0 ID
        technicianName: user.name, // User's name
        consumedGasket: consumedGasket, // New data point
        consumedCover: consumedCover, // New data point
        noteNeedsAttention: noteNeedsAttention, // Include this in the request
      }
    );

    const newPmId = pmEventResponse.data.pmEvent.pm_id;
    setPmId(newPmId); // Update the state with the new pm_id

    // Submit notes with the new pm_id
    if (notes) {
      console.log('Submitting notes');
      await axios.post(
        'https://freshpet-pm-9087312eb841.herokuapp.com/api/notes',
        { pmId: newPmId, notes }
      );
    }

    // Function to upload a file with the new pm_id
    const uploadFile = async (file, fileType) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileType', fileType);
      formData.append('pmId', newPmId); // Include pm_id in the form data
      await axios.post(
        'https://freshpet-pm-9087312eb841.herokuapp.com/api/upload',
        formData
      );    };

    // Upload each file with the new pm_id
    if (serialPlateFile) {
      console.log('Uploading serial plate file');
      await uploadFile(serialPlateFile, 'serialPlate');
    }
    if (condensorCoilFile) {
      console.log('Uploading condenser coil file');
      await uploadFile(condensorCoilFile, 'condensorCoil');
    }
    if (frontalPictureFile) {
      console.log('Uploading frontal picture file');
      await uploadFile(frontalPictureFile, 'frontalPicture');
    }

    if (noteNeedsAttention) {
      console.log('Sending note attention email');
      const emailData = {
        technicianName: user.name,
        serialNumber: cooler.SerialNumber,
        note: notes,
        linkToReport: `https://app.shursolution.com/cooler-details/${coolerId}`,
      };
      await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/sendNoteAttentionEmail', emailData);
    }

    // Remove the serviced cooler's ID from localStorage after successful submission
    console.log('Removing serviced cooler ID from localStorage');
    const savedCoolers = localStorage.getItem('selectedCoolers');
    let selectedCoolers = savedCoolers ? JSON.parse(savedCoolers) : [];
    selectedCoolers = selectedCoolers.filter(id => id !== coolerId);
    localStorage.setItem('selectedCoolers', JSON.stringify(selectedCoolers));

    console.log('Submission completed');
    setIsLoading(false); // End loading
    navigate('/technician'); // Redirect after successful submission
  } catch (error) {
    console.error('Error in submitting form:', error);
    setIsLoading(false); // Ensure loading is turned off in case of error
  }
};
const handleSubmit = async () => {
  if (!serialPlateFile || !condensorCoilFile || !frontalPictureFile) {
    setShowPopup(true);
    return;
  }
  try {
  setIsLoading(true); // Start loading
      // Include user details in the PM event creation
      const pmEventResponse = await axios.post(
  'https://freshpet-pm-9087312eb841.herokuapp.com/api/addPMEvent',
  {
    SerialNumber: coolerId,
    technicianAuth0Id: user.sub, // User's Auth0 ID
    technicianName: user.name, // User's name
    consumedGasket: consumedGasket, // New data point
    consumedCover: consumedCover, // New data point
    noteNeedsAttention: noteNeedsAttention, // Include this in the request
  }
);
      const newPmId = pmEventResponse.data.pmEvent.pm_id;
      setPmId(newPmId); // Update the state with the new pm_id

      // Submit notes with the new pm_id
      if (notes) {
        await axios.post(
          'https://freshpet-pm-9087312eb841.herokuapp.com/api/notes',
          { pmId: newPmId, notes }
        );
      }

      // Function to upload a file with the new pm_id
      const uploadFile = async (file, fileType) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileType', fileType);
        formData.append('pmId', newPmId); // Include pm_id in the form data

        await axios.post(
          'https://freshpet-pm-9087312eb841.herokuapp.com/api/upload',
          formData
        );
      };

      // Upload each file with the new pm_id
      if (serialPlateFile) await uploadFile(serialPlateFile, 'serialPlate');
      if (condensorCoilFile) await uploadFile(condensorCoilFile, 'condensorCoil');
      if (frontalPictureFile) await uploadFile(frontalPictureFile, 'frontalPicture');
      if (noteNeedsAttention) {
        // Assuming `newPmId` is the ID of the newly created PM event
        const emailData = {
          technicianName: user.name, // Or however you get the technician's name
          serialNumber: cooler.SerialNumber, // Assuming this is how you get the serial number
          note: notes,
          linkToReport: `https://app.shursolution.com/cooler-details/${coolerId}`, // Construct your link accordingly
        };

        // Make the POST request to your backend to send the email
        await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/sendNoteAttentionEmail', emailData);
      }

      // Remove the serviced cooler's ID from localStorage after successful submission
       const savedCoolers = localStorage.getItem('selectedCoolers');
       let selectedCoolers = savedCoolers ? JSON.parse(savedCoolers) : [];
       selectedCoolers = selectedCoolers.filter(id => id !== coolerId); // Remove the serviced cooler ID
       localStorage.setItem('selectedCoolers', JSON.stringify(selectedCoolers));

       setIsLoading(false); // End loading
       navigate('/technician'); // Redirect after successful submission
     } catch (error) {
       console.error('Error in submitting form:', error);
       setIsLoading(false); // Ensure loading is turned off in case of error
     }
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  if (!cooler) {
    return <div>Loading...</div>;
  }

  return (
    <>
    {isLoading && <LoadingOverlay />}
    {showPopup && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '2rem',
              borderRadius: '10px',
              textAlign: 'center',
              maxWidth: '80%',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Looks like you are missing photos. You can add a note about it here, or go back.
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={popupNotes}
              onChange={(e) => setPopupNotes(e.target.value)}
              style={{ marginBottom: '1rem' }}
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: '#1B40A7',
                color: 'white',
                marginRight: '1rem',
              }}
              onClick={() => setShowPopup(false)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: '#1B40A7',
                color: 'white',
              }}
              onClick={handlePopupSubmit}
            >
              Submit Anyway
            </Button>
          </div>
        </div>
      )}
      <style>
        {`
          .image-preview-container {
            width: 150px;
            height: 150px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            margin-bottom: 1rem;
            background: #f0f0f0; /* Placeholder background if no image is present */
          }
          .image-preview {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .image-remove-button {
            position: absolute;
            top: 5px; /* Adjusted for better visibility */
            left: -10px; /* Adjusted for better visibility */

            color: grey;
            border-radius: 10%;

            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            outline: none;
          }
          input[type="file"] {
            display: none;
          }
          .custom-file-upload {
            display: inline-block;
            padding: 6px 12px;
            cursor: pointer;
            margin-bottom: 1rem;
            background-color: #1B40A7;
            color: white;
            border-radius: 5px;
            font-family: 'NeueEinstellungBold';
          }
        `}
        </style>
  <Box style={{ padding: '1rem', backgroundColor: '#F5F5F5', minHeight: '100vh', fontFamily: 'NeueEinstellungNormal' }}>
    <BackButton />
    <Typography variant="h6" gutterBottom style={{ fontFamily: 'NeueEinstellungBold' }}>
      Service Record for {cooler.SerialNumber}
    </Typography>
    <Typography variant="h8" gutterBottom style={{ fontFamily: 'NeueEinstellungNormal' }}>
      {cooler.AccountName}
    </Typography>
    <Typography variant="h8" gutterBottom style={{ fontFamily: 'NeueEinstellungNormal', marginBottom: '2rem' }}>
    {cooler.ShippingAddressLine1}, {cooler.ShippingCity} {cooler.ShippingState}
    </Typography>
    {/* PM Guide Card */}
    <Card variant="outlined" style={{ marginTop: '1rem', marginBottom: '2rem', borderRadius: '15px' }}>
      <CardContent>
        <Typography variant="h6" style={{ fontFamily: 'NeueEinstellungBold' }}>PM Guide for {cooler.Model}</Typography>
        {renderGuideSteps(pmGuide)}
      </CardContent>
    </Card>

    {/* Photo Uploads Card */}
    <Card variant="outlined" style={{ marginBottom: '2rem', borderRadius: '15px' }}>
      <CardContent>
        <Typography variant="h5" style={{ fontFamily: 'NeueEinstellungBold' }}>Photo Uploads</Typography>

        <Box>
          <Typography style={{ fontFamily: 'NeueEinstellungNormal' }} >Chiller Serial Plate: </Typography>
          {serialPlatePreview ? (
            <Box className="image-preview-container">
              <img src={serialPlatePreview} alt="Chiller Serial Plate Preview" className="image-preview" />
              <Button className="image-remove-button" onClick={() => removeImagePreview('serialPlate')}>
                <CancelIcon />
              </Button>
            </Box>
          ) : (
            <>
              <input
                type="file"
                id="serial-plate-upload"
                onChange={(e) => handleFileUpload(e, 'serialPlate', coolerId)}
                style={{ display: 'none' }}
              />
              <label htmlFor="serial-plate-upload" className="custom-file-upload">
                Upload
              </label>
            </>
          )}

          <Typography style={{ fontFamily: 'NeueEinstellungNormal' }} >Condensor Coil:</Typography>
          {condensorCoilPreview ? (
            <Box className="image-preview-container">
              <img src={condensorCoilPreview} alt="Condensor Coil Preview" className="image-preview" />
              <Button className="image-remove-button" onClick={() => removeImagePreview('condensorCoil')}>
                <CancelIcon />
              </Button>
            </Box>
          ) : (
            <>
              <input
                type="file"
                id="condensor-coil-upload"
                onChange={(e) => handleFileUpload(e, 'condensorCoil', coolerId)}
                style={{ display: 'none' }}
              />
              <label htmlFor="condensor-coil-upload" className="custom-file-upload">
                Upload
              </label>
            </>
          )}

          <Typography style={{ fontFamily: 'NeueEinstellungNormal' }} >Chiller Frontal Picture:</Typography>
          {frontalPicturePreview ? (
            <Box className="image-preview-container">
              <img src={frontalPicturePreview} alt="Chiller Frontal Preview" className="image-preview" />
              <Button className="image-remove-button" onClick={() => removeImagePreview('frontalPicture')}>
                <CancelIcon />
              </Button>
            </Box>
          ) : (
            <>
              <input
                type="file"
                id="frontal-picture-upload"
                onChange={(e) => handleFileUpload(e, 'frontalPicture', coolerId)}
                style={{ display: 'none' }}
              />
              <label htmlFor="frontal-picture-upload" className="custom-file-upload">
                Upload
              </label>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
    {pmGuide.length && cooler.Model === 'TVM48SL' && (
  <Card variant="outlined" style={{ marginTop: '1rem', marginBottom: '2rem', borderRadius: '15px' }}>
    <CardContent>
      <Typography variant="h6" style={{ fontFamily: 'NeueEinstellungBold' }}>
        Gasket and Cover Kit Consumption
      </Typography>
      <Box>
        <FormControlLabel
          control={<Checkbox checked={consumedGasket} onChange={(e) => setConsumedGasket(e.target.checked)} />}
          label="Gasket"
        />
        <FormControlLabel
          control={<Checkbox checked={consumedCover} onChange={(e) => setConsumedCover(e.target.checked)} />}
          label="Cover"
        />
      </Box>
    </CardContent>
  </Card>
)}
    {/* Notes Card */}
    <Card variant="outlined" style={{ marginBottom: '2rem', borderRadius: '15px' }}>
        <CardContent>
          <Typography variant="h5" style={{ fontFamily: 'NeueEinstellungBold' }}>Notes</Typography>
          <FormControlLabel
  control={
    <Checkbox
      checked={noteNeedsAttention}
      onChange={(e) => setNoteNeedsAttention(e.target.checked)}
    />
  }
  label="Needs Attention"
/>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={notes}
            onChange={handleNotesChange}
            style={{ fontFamily: 'NeueEinstellungBold' }}
          />
        </CardContent>
      </Card>

      {/* Submit Button */}
      <Button
  disabled={isLoading}
  variant="contained"
  color="primary"
  style={{
    backgroundColor: '#1B40A7',
    color: 'white',
    display: 'block',
    width: '100%',
    padding: '10px 0',
    margin: '0 auto',
    marginBottom: '2rem',
    borderRadius: '15px',
    fontFamily: 'NeueEinstellungBold'
  }}
  onClick={() => handleSubmit()}
>
  Submit
</Button>
  </Box>
  </>
  );
};
  export default ServicePage;

const pmGuides = {
  "GDM41 SL-54": [
    "Check T-stat set to 4/5",
    "Remove Grill, clean coils",
    "Check slide doors operation",
    "Check light in non-led models replace if needed"
  ],
  "GDM41 Slide Door": [
    "Check T-stat set to 4/5",
    "Remove Grill, clean coils",
    "Check light in non-led models replace if needed",
    "Check door installation",
    [
      "Sub-steps for door installation:",
      [
        "Old- string doors",
        "Middle-New string doors",
        "New-Cassette Wires"
      ]
    ]
  ],
  "TVM48SL": [
    "Check T-stat 4/5",
    "Remove Grill, clean coils",
    [
      "For old SN# < 9:",
      [
        "Install new wiper gaskets",
        "Test doors",
        "Adjust gaskets to allow doors to properly close",
        "Check for ice build-up",
        "Install T-stat Cover (set to 4/5)"
      ]
    ],
    [
      "For new SN# > 10:",
      [
        "Check for wiper gaskets if not installed",
        "Test doors",
        "Check for ice build-up",
        "Install T-stat Cover (set to 4/5)"
      ]
    ]
  ],
  "AHT": [
    "110V Evaporator up top",
    "220V Cassette System",
    "Lower front grill and clean coils",
    "Use caution when removing the glass doors - try to clean the coils without removing doors"
  ],
  "True Swing Door": [
    "Check T-stat set to 4/5",
    "Remove Grill, clean coils",
    "Check doors operation",
    "Check light in non-led models replace if needed"
  ],
  "Minus 40 43/23": [
    "Look through grill; remove if dirty and clean",
    "Remove grill if needed and clean coils"
  ],
  // Add any other guides here as needed
};



const modelToGuideName = {
"18Z-UDGR": "Minus 40 43/23",
"21X-UDGR": "Minus 40 43/23",
"43X-UDGR-VX": "Minus 40 43/23",
"AC-W": "AHT",
"AHT-GD-XL R290": "AHT",
"AHT-GD-XLS-R290": "AHT",
"AHT-XL 220V": "AHT",
"AHT-XL-DD-220V": "AHT",
"AHT-XL-SLIM-DD-115V": "AHT",
"AHT-XL-SLIM-DD-220V": "AHT",
"AHT-XLY 220V": "AHT",
"AHT-XLY-DD-220V": "AHT",
"AHTGDXLSLIM-115V": "AHT",
"CFX-11": "NA",
"CT-3": "NA",
"DC-41SL-54": "NA",
"DD-12": "NA",
"DD-17": "NA",
"DD-20": "NA",
"GDM-19": "True Swing Door",
"GDM-19T": "True Swing Door",
"GDM-23": "True Swing Door",
"GDM-33SL": "GDM41 Slide Door",
"GDM-33SSL-54": "GDM41 Slide Door",
"GDM-41SL": "GDM41 Slide Door",
"GDM-41SL-54": "GDM41 Slide Door",
"GDM-41SLLDSS": "GDM41 Slide Door",
"GDM10-SSL": "True Swing Door",
"GDM19T-HC": "True Swing Door",
"GDM33SSL-56 HC LD": "GDM41 SL-54",
"GDM41SL-HC-LD": "GDM41 SL-54",
"GLC": "NA",
"MT-12": "True Swing Door",
"SD-10": "True Swing Door",
"SD-12": "True Swing Door",
"SD-5": "True Swing Door",
"THAC-48SS": "NA",
"TVM-400": "True Swing Door",
"TVM-48SL-54": "TVM48SL",
"TVM-48SL-HC~VM02 FRESHPET/BLK CCR.": "TVM48SL",
"TVM48-HC": "TVM48SL",
"TVM48SL": "TVM48SL",
"UDGR43": "Minus 40 43/23",
"UDGR43- SILVER": "Minus 40 43/23",
"UDGR43-PV": "Minus 40 43/23",
"VC-290": "True Swing Door",
"VC-500": "True Swing Door"
};
