import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Grid, Typography, Card, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    fontFamily: 'NeueEinstellungBold',
    fontSize: '14px',
  },
  '& .MuiInputBase-input': {
    fontFamily: 'NeueEinstellungBold',
    fontSize: '14px',
  },
});

const StyledButton = styled(Button)({
  backgroundColor: '#1B40A7',
  color: '#fff',
  height: '44px',
  boxShadow: '0 2px 8px rgba(27, 64, 167, 0.2)',
  fontFamily: 'NeueEinstellungBold',
  fontSize: '14px',
  borderRadius: '12px',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#2850BD',
    transform: 'translateY(-1px)',
    boxShadow: '0 4px 12px rgba(27, 64, 167, 0.3)',
  },
});

const StyledCard = styled(Card)({
  padding: '24px',
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
});

const EditGoals = () => {
  const [techGoals, setTechGoals] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchTechGoals();
  }, []);

  const fetchTechGoals = async () => {
    try {
      const response = await axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-goals');
      setTechGoals(response.data);
    } catch (err) {
      console.error('Error fetching tech goals:', err);
    }
  };

  const handleGoalChange = (techId, field, value) => {
    setTechGoals(prevGoals => {
      return prevGoals.map(goal => {
        if (goal.tech_id === techId) {
          return { ...goal, [field]: value };
        }
        return goal;
      });
    });
  };

  const handleSaveGoal = async (techId) => {
    try {
      const goal = techGoals.find(goal => goal.tech_id === techId);
      await axios.put(`https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-goals/${techId}`, {
        yearlyGoal: goal.yearly_goal,
        monthlyGoal: goal.monthly_goal,
      });
      setSnackbar({ open: true, message: 'Goals saved successfully!', severity: 'success' });
    } catch (err) {
      console.error('Error saving tech goal:', err);
      setSnackbar({ open: true, message: 'Error saving goals. Please try again.', severity: 'error' });
    }
  };

  return (
    <div style={{ padding: '24px' }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        style={{ 
          marginBottom: '32px', 
          fontFamily: 'NeueEinstellungBold',
          color: '#1B40A7' 
        }}
      >
        Edit Goals
      </Typography>
      <Grid container spacing={3}>
        {techGoals.map(goal => (
          <Grid item xs={12} key={goal.tech_id}>
            <StyledCard>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={3}>
                  <Typography 
                    variant="subtitle1" 
                    style={{ 
                      fontFamily: 'NeueEinstellungBold',
                      marginBottom: '8px',
                      color: '#666' 
                    }}
                  >
                    {goal.tech_name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <StyledTextField
                    label="Yearly Goal"
                    value={goal.yearly_goal}
                    onChange={(e) => handleGoalChange(goal.tech_id, 'yearly_goal', e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <StyledTextField
                    label="Monthly Goal"
                    value={goal.monthly_goal}
                    onChange={(e) => handleGoalChange(goal.tech_id, 'monthly_goal', e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <StyledButton 
                    variant="contained" 
                    onClick={() => handleSaveGoal(goal.tech_id)}
                    fullWidth
                  >
                    Save Changes
                  </StyledButton>
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditGoals;
