import React, { useState } from 'react';
import axios from 'axios';
import { Paper, Button, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import * as XLSX from 'xlsx';

const FileUploadPage = () => {
  const [file, setFile] = useState(null);
  const [columns, setColumns] = useState([]);
  const [mappings, setMappings] = useState({});

  const dbColumns = [
    "SerialNumber",
    "Location",
    "ShippingAddressLine1",
    "ShippingCity",
    "ShippingState",
    "ShippingZip",
    "AssetStatus",
    "ShippingCountry",
    "AccountStatus",
    "Model",
    "Manufacturer",
    "Warranty",
    "Warranty Start Date",
    "Unit Warranty End Date",
    "Compressor Warranty End Date",
    // Note: Skipping Latitude, Longitude, and location as they remain blank
  ];

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);

    const reader = new FileReader();
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 0, raw: false });
      const headers = jsonData[0];
      setColumns(headers.slice(0, jsonData[0].length).filter(header => header)); // Filter out empty headers
    };
    reader.readAsBinaryString(uploadedFile);
  };

  const handleMappingChange = (column, event) => {
    setMappings({
      ...mappings,
      [column]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('mappings', JSON.stringify(mappings));

    try {
      await axios.post('/api/upload-excel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('File uploaded and processed successfully.');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file.');
    }
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
      {columns.length > 0 && (
        <Box sx={{ my: 2 }}>
          {columns.map((column, index) => (
            <FormControl fullWidth key={index} sx={{ my: 1 }}>
              <InputLabel>{column}</InputLabel>
              <Select
                value={mappings[column] || ''}
                label={column}
                onChange={(event) => handleMappingChange(column, event)}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {dbColumns.map((dbColumn) => (
                  <MenuItem key={dbColumn} value={dbColumn}>{dbColumn}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Upload and Process
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default FileUploadPage;
