import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  Container,
  Card,
  CardContent,
} from '@mui/material';
import { VictoryArea, VictoryLine, VictoryBar, VictoryStack, VictoryChart, VictoryAxis, VictoryTheme } from 'victory';
import { VictoryPie, VictoryTooltip } from 'victory';
import BackButton from '../components/BackButton';

const DataDashboard = () => {
  const { user } = useAuth0();
  const [coolers, setCoolers] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [technicianPmEvents, setTechnicianPmEvents] = useState([]); // New state for storing technician's PM events
  const [servicedCoolersCount, setServicedCoolersCount] = useState(0);
  const [monthlyServicedCoolersCount, setMonthlyServicedCoolersCount] = useState(0); // For storing monthly serviced coolers count
  const [yearlyServicedCoolersCount, setYearlyServicedCoolersCount] = useState(0); // For storing yearly serviced coolers count
  const [yearlyGoal, setYearlyGoal] = useState(0); // State to store yearly goal
 const [monthlyGoal, setMonthlyGoal] = useState(0); // State to store monthly goal
  const animationDuration = 1000;

  const chartContainerStyle = {
  width: '100%', // Use 100% to fill the container or set a specific size
  maxWidth: '350px', // Set a maximum width for the charts
  margin: '0 auto', // This centers the chart in the Paper if it's smaller
};
  const buttonStyle = {
    backgroundColor: '#1B40A7',
    color: '#fff',
    height: '70px',
    fontFamily: 'NeueEinstellungBold',
    margin: '8px',
    width: 'calc(50% - 16px)',
    borderRadius: '8px'
  };

  useEffect(() => {
    if (user && user.sub) {
      axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/goals/${user.sub}`)
        .then(response => {
          const { yearly_goal, monthly_goal } = response.data;
          setYearlyGoal(yearly_goal);
          setMonthlyGoal(monthly_goal);
        })
        .catch(err => console.error('Error fetching goals', err));
    }
  }, [user]);

  useEffect(() => {
    axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/newcoolers')
      .then(response => setCoolers(response.data))
      .catch(err => console.error('Error fetching coolers', err));
  }, []);

  useEffect(() => {
    axios.get('https://freshpet-pm-9087312eb841.herokuapp.com/api/all-pm-events')
      .then(response => setServicedCoolersCount(response.data.length))
      .catch(err => console.error('Error fetching all PM events', err));
  }, []);

  useEffect(() => {
    const fetchMonthlyPmEventsForTechnician = async () => {
      if (user && user.sub) {
        try {
          const technicianAuth0Id = user.sub;
          const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-pm-report/${technicianAuth0Id}?filter=monthly`);
          const monthlyEvents = response.data;
          const monthlyServicedCoolers = monthlyEvents.reduce((total, event) => total + event.coolers_serviced.length, 0);
          setMonthlyServicedCoolersCount(monthlyServicedCoolers); // Update monthly serviced coolers count
        } catch (err) {
          console.error('Error fetching monthly PM events for technician', err);
        }
      }
    };

    fetchMonthlyPmEventsForTechnician();
  }, [user]);

  // useEffect for fetching yearly PM events
  useEffect(() => {
    const fetchYearlyPmEventsForTechnician = async () => {
      if (user && user.sub) {
        try {
          const technicianAuth0Id = user.sub;
          const response = await axios.get(`https://freshpet-pm-9087312eb841.herokuapp.com/api/tech-pm-report/${technicianAuth0Id}?filter=yearly`);
          const yearlyEvents = response.data;
          const yearlyServicedCoolers = yearlyEvents.reduce((total, event) => total + event.coolers_serviced.length, 0);
          setYearlyServicedCoolersCount(yearlyServicedCoolers); // Update yearly serviced coolers count
        } catch (err) {
          console.error('Error fetching yearly PM events for technician', err);
        }
      }
    };

    fetchYearlyPmEventsForTechnician();
  }, [user]);


  const paperStyle = {
    padding: '1.5rem',
    borderRadius: '16px',
    background: 'linear-gradient(145deg, #ffffff, #f5f5f5)',
    boxShadow: '8px 8px 16px #e6e6e6, -8px -8px 16px #ffffff',
    fontFamily: 'NeueEinstellungBold',
  };

  const welcomeStyle = {
    background: 'linear-gradient(90deg, #1B40A7, #2b5be0)',
    padding: '1.5rem',
    borderRadius: '16px',
    marginBottom: '2rem',
    boxShadow: '0 4px 15px rgba(27, 64, 167, 0.2)',
  };

  const totalCoolersByLocation = coolers.reduce((acc, cooler) => {
    acc[cooler.location] = (acc[cooler.location] || 0) + 1;
    return acc;
  }, {});

  const locationData = Object.entries(totalCoolersByLocation).map(([location, count]) => ({
    location,
    count,
  }));
  const servicedCoolersPercentage = yearlyGoal ? Math.round((yearlyServicedCoolersCount / yearlyGoal) * 100) : 0;
  const servicedCoolersPercentage1 = monthlyGoal ? Math.round((monthlyServicedCoolersCount / monthlyGoal) * 100) : 0;
  const assignedCoolersCount = coolers.reduce((acc, cooler) => cooler.AssignedTechnicianName ? acc + 1 : acc, 0);
  const totalCoolersCount = coolers.length;

  const pieChartData = [
    { x: "Serviced", y: yearlyServicedCoolersCount },
    { x: "Unserviced", y: yearlyGoal - yearlyServicedCoolersCount },
  ];

  const pieChartData1 = [
    { x: "Serviced", y: monthlyServicedCoolersCount },
    { x: "Unserviced", y: monthlyGoal - monthlyServicedCoolersCount },
  ];




  const cardStyle = {
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'left',
   padding: '1rem',
   marginBottom: '5rem',
   borderRadius: '20px',
   boxShadow: '0px 6px 18px rgba(0,0,0,0.1)',
   backgroundColor: 'white',
 };

 const areaStyle = {
   data: {
     fill: "#1B40A7", // Area color
     fillOpacity: 0.7, // Semi-opaque
   },
 };

 const chartStyle = {
   parent: {

     boxShadow: "0px 6px 18px rgba(0,0,0,0.1)",
     borderRadius: "20px",
   },
 };

 return (
  <Container maxWidth="lg" sx={{ py: 4 }}>
    <BackButton />
    
    <Paper elevation={0} style={welcomeStyle}>
      <Typography 
        variant="h5" 
        style={{ 
          color: '#fff', 
          fontFamily: "Akira",
          marginBottom: '0.5rem'
        }}
      >
        Welcome Back, {user.name.split(" ")[0]}
      </Typography>
      <Typography 
        variant="subtitle1" 
        style={{ 
          color: 'rgba(255, 255, 255, 0.8)',
          fontFamily: 'NeueEinstellungBold' 
        }}
      >
        Track your maintenance progress
      </Typography>
    </Paper>

    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper elevation={0} style={paperStyle}>
          <Typography 
            variant="h6" 
            gutterBottom 
            style={{ 
              fontFamily: 'NeueEinstellungBold',
              color: '#1B40A7',
              marginBottom: '1.5rem'
            }}
          >
            Year to Date Progress
          </Typography>
          <Box sx={{ position: 'relative' }}>
            <VictoryPie
              data={pieChartData}
              colorScale={["#1B40A7", "#e8eaf6"]}
              labels={() => ''}
              width={300}
              height={300}
              innerRadius={100}
              style={{
                parent: { maxWidth: '400px' }
              }}
              animate={{
                duration: animationDuration,
                onLoad: { duration: animationDuration }
              }}
            />
            <Typography 
              variant="h4" 
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontFamily: 'NeueEinstellungBold',
                color: '#1B40A7'
              }}
            >
              {servicedCoolersPercentage}%
            </Typography>
          </Box>
          <Typography 
            align="center" 
            style={{ 
              marginTop: '1rem',
              fontFamily: 'NeueEinstellungBold' 
            }}
          >
            {yearlyServicedCoolersCount} of {yearlyGoal} Coolers
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={0} style={paperStyle}>
          <Typography 
            variant="h6" 
            gutterBottom 
            style={{ 
              fontFamily: 'NeueEinstellungBold',
              color: '#1B40A7',
              marginBottom: '1.5rem'
            }}
          >
            Month to Date Progress
          </Typography>
          <Box sx={{ position: 'relative' }}>
            <VictoryPie
              data={pieChartData1}
              colorScale={["#1B40A7", "#e8eaf6"]}
              labels={() => ''}
              width={300}
              height={300}
              innerRadius={100}
              style={{
                parent: { maxWidth: '400px' }
              }}
              animate={{
                duration: animationDuration,
                onLoad: { duration: animationDuration }
              }}
            />
            <Typography 
              variant="h4" 
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontFamily: 'NeueEinstellungBold',
                color: '#1B40A7'
              }}
            >
              {servicedCoolersPercentage1}%
            </Typography>
          </Box>
          <Typography 
            align="center" 
            style={{ 
              marginTop: '1rem',
              fontFamily: 'NeueEinstellungBold' 
            }}
          >
            {monthlyServicedCoolersCount} of {monthlyGoal} Coolers
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  </Container>
);
};

export default DataDashboard;
