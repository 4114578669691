import React, { useState, useCallback } from 'react';
import { Upload, RotateCcw, Image as ImageIcon, Check, AlertCircle } from 'lucide-react';
import axios from 'axios';

const styles = {
    container: {
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
    },
    dropzone: {
        border: '2px dashed #ccc',
        borderRadius: '8px',
        padding: '32px',
        marginBottom: '16px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    uploadIcon: {
        margin: '0 auto 16px',
        width: '48px',
        height: '48px',
        color: '#666',
    },
    uploadText: {
        color: '#666',
        margin: 0,
    },
    error: {
        backgroundColor: '#fee2e2',
        border: '1px solid #ef4444',
        borderRadius: '8px',
        padding: '12px',
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        color: '#dc2626',
    },
    processing: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px',
        gap: '8px',
    },
    resultItem: {
        padding: '16px',
        borderRadius: '8px',
        marginBottom: '16px',
        border: '1px solid',
    },
    resultItemSuccess: {
        backgroundColor: '#f0fdf4',
        borderColor: '#86efac',
    },
    resultItemError: {
        backgroundColor: '#fef2f2',
        borderColor: '#fecaca',
    },
    resultHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
        gap: '8px',
    },
    resultGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '16px',
        fontSize: '14px',
    },
    hidden: {
        display: 'none',
    }
};

const ImageProcessor = () => {
    const [processing, setProcessing] = useState(false);
    const [results, setResults] = useState([]);
    const [error, setError] = useState(null);
    const [trainingMode, setTrainingMode] = useState(false);

    const getPreprocessingConfigs = () => {
        return [
            // High contrast configurations for black text on white labels
            { threshold: 180, grayscale: true },  // Higher threshold for better text isolation
            { threshold: 200, grayscale: true },
            { threshold: 220, grayscale: true },
            // Try without grayscale for barcode areas
            { threshold: 180, grayscale: false },
            { threshold: 200, grayscale: false },
            { threshold: 220, grayscale: false },
        ];
    };

    const preprocessImage = async (imageFile, config = { threshold: 128, grayscale: true }) => {
        return new Promise((resolve) => {
            const img = new Image();
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;

                for (let i = 0; i < data.length; i += 4) {
                    if (config.grayscale) {
                        const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
                        const adjusted = avg > config.threshold ? 255 : 0;
                        data[i] = adjusted;
                        data[i + 1] = adjusted;
                        data[i + 2] = adjusted;
                    } else {
                        // Just apply threshold to each channel independently
                        data[i] = data[i] > config.threshold ? 255 : 0;
                        data[i + 1] = data[i + 1] > config.threshold ? 255 : 0;
                        data[i + 2] = data[i + 2] > config.threshold ? 255 : 0;
                    }
                }

                ctx.putImageData(imageData, 0, 0);
                canvas.toBlob(resolve, 'image/png');
            };

            img.src = URL.createObjectURL(imageFile);
        });
    };

    const validateResults = (text) => {
        // Log the full text for debugging
        console.log('Full OCR Text:', text);
        
        // Look for serial number pattern: A67 followed by 8 digits
        const serialMatches = text.match(/[AА]67\d{9}/gi);
        
        // Look for asset number pattern: VM followed by 8 digits
        const assetMatches = text.match(/[UVM][MN]\d{8}/gi);  // Handle OCR confusion between VM/UM/VN
        
        const serialNumber = serialMatches ? serialMatches[0] : null;
        const assetNumber = assetMatches ? assetMatches[0].replace(/^[UN]/, 'V') : null; // Convert UM/UN to VM
        
        // Log what we found
        console.log('Found matches:', {
            serialMatches,
            assetMatches,
            serialNumber,
            assetNumber
        });

        return {
            valid: serialNumber && assetNumber,
            serialNumber,
            assetNumber,
            text
        };
    };

    const processImage = async (file) => {
        try {
            setProcessing(true);
            setError(null);

            // Create form data for the file
            const formData = new FormData();
            formData.append('image', file);

            // Call backend endpoint
            const response = await axios.post('https://freshpet-pm-9087312eb841.herokuapp.com/api/extract-text', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const text = response.data.text;
            console.log('OCR Text:', text);
            
            const validation = validateResults(text);
            setResults(prev => [...prev, {
                filename: file.name,
                text,
                ...validation
            }]);
        } catch (err) {
            console.error('Processing error:', err);
            setError(err.message);
        } finally {
            setProcessing(false);
        }
    };

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer?.files || e.target.files);
        files.forEach(processImage);
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div style={styles.container}>
            
            <div
                style={styles.dropzone}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
            >
                <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleDrop}
                    style={styles.hidden}
                    id="file-upload"
                />
                <label htmlFor="file-upload">
                    <Upload style={styles.uploadIcon} />
                    <p style={styles.uploadText}>Drop images or click to upload</p>
                </label>
            </div>

            {error && (
                <div style={styles.error}>
                    <AlertCircle size={16} />
                    <span>{error}</span>
                </div>
            )}

            {processing && (
                <div style={styles.processing}>
                    <RotateCcw style={{ animation: 'spin 1s linear infinite' }} size={24} />
                    <span>Processing images...</span>
                </div>
            )}

            <div>
                {results.map((result, index) => (
                    <div
                        key={index}
                        style={{
                            ...styles.resultItem,
                            ...(result.valid ? styles.resultItemSuccess : styles.resultItemError)
                        }}
                    >
                        <div style={styles.resultHeader}>
                            <ImageIcon size={20} />
                            <span style={{ fontWeight: 500 }}>{result.filename}</span>
                            {result.valid ? (
                                <Check size={20} style={{ marginLeft: 'auto', color: '#22c55e' }} />
                            ) : (
                                <AlertCircle size={20} style={{ marginLeft: 'auto', color: '#ef4444' }} />
                            )}
                        </div>
                        <div style={styles.resultGrid}>
                            <div>
                                <p style={{ fontWeight: 500 }}>Found:</p>
                                <p>Serial: {result.serialNumber || 'Not found'}</p>
                                <p>Asset: {result.assetNumber || 'Not found'}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Add keyframe animation for spinner
const styleSheet = document.createElement('style');
styleSheet.textContent = `
  @keyframes spin {
    from { transform: rotate(360deg); }
    to { transform: rotate(0deg); }
  }
`;
document.head.appendChild(styleSheet);

export default ImageProcessor;